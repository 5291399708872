import React from "react";
import Sleek_studio from "../../../assets/about/purpose.jpg";
import Leaf_about from "../../../assets/about/leaf-about.png";
import one from "../../../assets/about/1989.jpg";
import two from "../../../assets/about/2000.jpg";
import three from "../../../assets/about/2008.jpg";
import four from "../../../assets/about/2013.jpg";
import five from "../../../assets/about/2021.jpg";

const SleekLegacy = () => {
  const timelineData = [
    {
      year: "1989",
      heading: "Started ‘Aay Ess Suitings’",
      paragraph:
        "Just a couple of years before the country set on its course towards economic liberalization, we set foot to change the paradigm of the then-fragmented Indian textile market.",
      src: one,
      alt: "1989",
    },
    {
      year: "2000",
      heading: "Founded ‘Sleek’",
      paragraph:
        "Embracing the tech revolution that took place in the early 2000s, we registered Sleek to set on a course to democratize India’s occasion-wear industry.",
      src: two,
      alt: "2000",
    },
    {
      year: "2008",
      heading: "Expanded operations under sleek to 100 stores.",
      paragraph:
        "While the market was in a state of recession in 2008, Sleek remained indifferent and was on an expansion spree with operations & project deliveries across PAN-India.",
      src: three,
      alt: "2008",
    },
    {
      year: "2013",
      heading: "Ventured as Sleek Studio",
      paragraph:
        "With the fast-changing landscape of the Indian couture industry, Sleek was registered as Sleek Studio with the intent to appeal to the contemporary audience with modern embellishments.",
      src: four,
      alt: "2013",
    },
    {
      year: "2021",
      heading: "Launched thesleekstudio.com",
      paragraph:
        "Keeping our momentum of tailored texture designs intact and setting a foot in the surging Indian eCommerce, we are giving the surefooted off-the-rack millennials of today the required dapper feel through our couture exuding valor, pride, and discipline.",
      src: five,
      alt: "2021",
    },
  ];
  const YearTiles = ({ className, year, heading, paragraph, src, alt }) => {
    return (
      <div
        className={`${className} md:max-w-screen-md max-w-screen-none mb-[12%]`}
      >
        <div className="flex gap-4 justify-start items-end relative">
          <h1 className="h1 font-lora leading-none text-secondary">{year}</h1>
          <div className="md:h-[21rem] h-[15rem] w-[10rem] md:w-[16rem] ">
            <img
              className="w-full h-full object-cover h6"
              src={src}
              alt={alt}
            />
          </div>
        </div>
        <div className="md:pl-14 max-w-lg">
          <h2 className="h5 font-lora font-medium text-primary md:mt-8 mt-2">
            {heading}
          </h2>
          <h3 className="h6  font-manrope font-light text-primary leading-normal mt-2">
            {paragraph}
          </h3>
        </div>
      </div>
    );
  };
  return (
    <div className="blade-padding contain">
      <h1 className="h1 font-lora text-primary text-center leading-tight">
        Legacy
      </h1>
      <div className="blade-padding max-w-screen-lg mx-auto">
        {timelineData.map((item, ind) => {
          const { alt, heading, paragraph, src, year } = item;
          return (
            <div
              key={ind}
              className={`${ind % 2 === 0 ? "" : "justify-end"} flex `}
            >
              <YearTiles
                year={year}
                heading={heading}
                paragraph={paragraph}
                src={src}
                alt={alt}
                className="year-tiles"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SleekLegacy;
