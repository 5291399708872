import React, { useState, useEffect } from "react";
import Search from "../../../modules/atoms/search";
import Pagination from "../../../modules/atoms/pagination";
import { MdDeleteOutline } from "react-icons/md";
import Dropdown from "../../../modules/atoms/dropdown";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearError } from "../../../redux/features/product/ProductSlice";
import {
  fetchByCategory,
  fetchBySubcategory,
  removeProduct,
  searchProduct,
  fetchAll,
} from "../../../redux/features/product/productActions";
import { fetchCategory } from "../../../redux/features/category/CategoryActions";
import { baseURL } from "../../../helpers";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";
import { selectCountry } from "../../../redux/features/country/CountrySlice";
import WarningPopup from "../../../modules/overlays/warningPopup";

const ProductList = () => {
  const [search, setSearch] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { products, isFetching, error } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.category);
  const { selectedCountry, country } = useSelector((state) => state.country);
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [categoryType, setCategoryType] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [page, setPage] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(true);

  const onSelectCategoryType = (type) => {
    setCategoryType(type);
    dispatch(fetchCategory({ type: type.name === "Men" ? "men" : "women" }));
  };

  const onSelectCountry = (country) => {
    dispatch(selectCountry(country));
    setSearch("");
    setCategoryType(null);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setIsAllSelected(true);
    dispatch(fetchAll({ country: country?.id, page: 1 }));
  };

  useEffect(() => {
    dispatch(fetchAll({ country: selectedCountry?.id }));
  }, []);

  const onChangePage = (pageNumber) => {
    if (search) {
      dispatch(
        searchProduct({
          body: { keyword: search, countryId: selectedCountry?.id },
          params: { page: pageNumber },
        })
      );
    } else if (selectedSubcategory) {
      dispatch(
        fetchBySubcategory({
          body: {
            subcategoryId: selectedSubcategory.id,
            countryId: selectedCountry.id,
          },
          params: { page: pageNumber },
        })
      );
    } else if (selectedCategory) {
      dispatch(
        fetchByCategory({
          body: {
            categoryId: selectedCategory.id,
            countryId: selectedCountry.id,
          },
          params: { page: pageNumber },
        })
      );
    } else {
      dispatch(fetchAll({ page: pageNumber, country: selectedCountry?.id }));
    }

    setPage(pageNumber);
  };

  const onSelectCategory = (selectedItem) => {
    setIsAllSelected(false);
    setPage(1);
    const category = categories.find(
      (category) => category.name === selectedItem.name
    );
    setSelectedCategory(category);
    dispatch(
      fetchByCategory({
        body: {
          categoryId: category.id,
          countryId: selectedCountry.id,
        },
        params: {},
      })
    );
  };

  const onSelectSubcategory = (selectedItem) => {
    setIsAllSelected(false);
    setPage(1);
    const subcategory = selectedCategory.subcategories.find(
      (subcategory) => subcategory.name === selectedItem.name
    );
    setSelectedSubcategory(subcategory);
    dispatch(
      fetchBySubcategory({
        body: {
          subcategoryId: subcategory.id,
          countryId: selectedCountry?.id,
        },
        params: {},
      })
    );
  };

  const onSelectProduct = (id) => {
    if (selectedProducts.includes(id)) {
      setSelectedProducts(
        selectedProducts.filter((productId) => productId !== id)
      );
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  const onSelectAll = (e) => {
    setPage(1);
    if (e.target.checked) {
      setSelectedProducts(products?.data?.map((product) => product.id));
    } else {
      setSelectedProducts([]);
    }
  };

  const onDelete = () => {
    dispatch(
      removeProduct({
        productIds: selectedProducts,
        onSuccess: () => {
          setSelectedProducts([]);
          onChangePage(page);
        },
      })
    );
  };

  const onClickDelete = () => {
    if (selectedProducts.length > 0) {
      setOpenPopup(true);
    } else {
      return;
    }
  };

  const onSearch = () => {
    setIsAllSelected(false);
    setPage(1);
    dispatch(
      searchProduct({
        body: { keyword: search, countryId: selectedCountry?.id },
        params: {},
      })
    );
  };

  const onClickAll = () => {
    setSearch("");
    setCategoryType(null);
    setSelectedCategory(null);
    setSelectedCategory(null);
    setIsAllSelected(true);
    dispatch(fetchAll({ country: selectedCountry?.id, page: 1 }));
  };

  return (
    <div className="w-[100%] h-full px-12">
      <ConfirmationPopup
        show={openPopup}
        onClose={() => setOpenPopup(false)}
        title={`Are you sure you want to delete ${
          selectedProducts.length > 1 ? "these products?" : "this product?"
        }`}
        onConfirm={onDelete}
      />
      <WarningPopup
        title={error?.message}
        show={error}
        onClose={() => dispatch(clearError(null))}
      />
      <div className="flex mt-12 items-center justify-between">
        <p className="font-bold text-xl ">Products</p>
        <Link
          to="add"
          className="text-white bg-primary h-full py-2 px-4 rounded-sm"
        >
          Add Product
        </Link>
      </div>

      <div className="bg-white mt-6 border border-solid rounded-md border-slate-200">
        <div className="flex gap-4 flex-wrap px-4 py-8 border-b border-slate-200 items-center">
          <span
            className={`${
              isAllSelected ? "border-primary text-primary" : "border-slate-200"
            } py-[6px] cursor-pointer rounded-md px-4 border`}
            onClick={onClickAll}
          >
            All
          </span>
          <Search
            onSearch={onSearch}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Dropdown
            placeholder="Gender"
            containerStyle="w-[120px]"
            dropdownInputStyle="w-[120px] border border-slate-300 py-1 px-1"
            dropdownStyle="w-[120px] h-[85px] top-[38px] left-0"
            selected={categoryType}
            data={[
              { id: 1, name: "Men" },
              { id: 2, name: "Women" },
            ]}
            onSelect={onSelectCategoryType}
          />
          <Dropdown
            placeholder="Category"
            emptyListText="No category found"
            containerStyle=""
            dropdownInputStyle="py-1 w-[200px] border border-slate-300 px-1"
            dropdownStyle="w-[200px] h-[150px] top-[38px] left-0"
            data={categories}
            selected={selectedCategory}
            onSelect={onSelectCategory}
          />
          <Dropdown
            placeholder="Subcategory"
            emptyListText="No subcategory found"
            data={selectedCategory?.subcategories}
            containerStyle=""
            dropdownInputStyle="py-1 w-[200px] border border-slate-300 px-1"
            dropdownStyle="w-[200px] h-[150px] top-[38px] left-0"
            selected={selectedSubcategory}
            onSelect={onSelectSubcategory}
          />
          <Dropdown
            placeholder="Country"
            containerStyle=""
            dropdownInputStyle="py-1 w-[200px] border border-slate-300 px-1"
            dropdownStyle="w-[200px] h-[150px] top-[38px] left-0"
            data={country}
            selected={selectedCountry}
            onSelect={onSelectCountry}
          />
        </div>
        {!isFetching && products?.data?.length === 0 ? (
          <div className="flex px-4 my-12 items-center justify-center">
            <h2 className="text-xl font-semibold">No Products Found</h2>
          </div>
        ) : isFetching ? (
          <div className="flex justify-center my-12">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <table className="w-full">
              <thead>
                <tr className="text-black text-sm">
                  <th className="px-4">
                    <input
                      onChange={onSelectAll}
                      className="w-[15px]  cursor-pointer"
                      type="checkbox"
                    />
                  </th>
                  <th className="text-left py-4">Product Details</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Actions</th>
                  <th className="text-left">
                    <span
                      onClick={onClickDelete}
                      className={`${
                        selectedProducts?.length > 0
                          ? "text-error cursor-pointer"
                          : "text-slate-400"
                      } flex items-center justify-center`}
                    >
                      Delete <MdDeleteOutline size={18} />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.data?.map((product) => (
                  <tr
                    key={product?.id}
                    className="border-t border-solid border-b-slate-200"
                  >
                    <td className="px-4">
                      <input
                        checked={selectedProducts.includes(product?.id)}
                        onChange={() => onSelectProduct(product?.id)}
                        className="w-[15px] cursor-pointer"
                        type="checkbox"
                      />
                    </td>
                    <td className="flex py-2 items-center">
                      <div className="w-12 mt-2 h-14">
                        <img
                          className="object-contain w-[100%] h-[100%]"
                          src={`${baseURL}${product?.files?.[0]?.url}`}
                          alt="product"
                        />
                      </div>
                      <p className="text-left ml-4 w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
                        {product?.title}
                      </p>
                    </td>
                    <td className="text-center">{`${selectedCountry?.symbol} ${product?.mrp}`}</td>
                    <td className="text-center">
                      <Link
                        to={`${product?.id}/update`}
                        className="py-1 font-semibold text-primary"
                      >
                        Edit
                      </Link>
                      <Link
                        to={`${product?.id}/details`}
                        className="py-1 ml-4 font-semibold text-primary"
                      >
                        Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <Pagination
          totalData={products?.total}
          pageNumber={page}
          pageData={10}
          onChangePage={onChangePage}
        />
      </div>
    </div>
  );
};

export default ProductList;
