import React from "react";

const Profile = ({ color }) => {
  return (
    <svg
      id="user"
      xmlns="http://www.w3.org/2000/svg"
      width="21.237"
      height="24.163"
      viewBox="0 0 21.237 24.163"
    >
      <g id="Group_4777" data-name="Group 4777" transform="translate(4.247)">
        <g id="Group_4776" data-name="Group 4776" transform="translate(0)">
          <path
            id="Path_3274"
            data-name="Path 3274"
            d="M127.371,0a6.371,6.371,0,1,0,6.371,6.371A6.378,6.378,0,0,0,127.371,0Z"
            transform="translate(-121)"
            fill={color ? color : "#dab188"}
          />
        </g>
      </g>
      <g id="Group_4779" data-name="Group 4779" transform="translate(0 14.158)">
        <g id="Group_4778" data-name="Group 4778" transform="translate(0)">
          <path
            id="Path_3275"
            data-name="Path 3275"
            d="M49.545,302.746A9.082,9.082,0,0,0,43.034,300H40.2a9.083,9.083,0,0,0-6.511,2.746A9.285,9.285,0,0,0,31,309.3a.708.708,0,0,0,.708.708H51.529a.708.708,0,0,0,.708-.708A9.285,9.285,0,0,0,49.545,302.746Z"
            transform="translate(-31 -300)"
            fill={color ? color : "#dab188"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Profile;
