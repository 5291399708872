import { createSlice } from "@reduxjs/toolkit";
import {
  applyCoupon,
  createCoupon,
  updateCoupon,
  fetchCoupons,
  removeCoupon,
} from "./CouponActions";

const initialState = {
  coupons: [],
  error: null,
  isFetching: false,
  appliedCoupon: null,
  subtotal: 0,
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    removeAppliedCoupon: (state) => {
      state.appliedCoupon = null;
      state.subtotal = 0;
    },
    clearError: (state, { payload }) => {
      if (state.error?.message || payload === null) {
        state.error = null;
      } else {
        state.error = { ...state.error, ...payload };
      }
    },
    clearCoupons: (state) => {
      state.coupons = [];
    },
  },
  extraReducers: {
    [createCoupon.pending]: (state) => {
      state.error = null;
      state.isFetching = true;
    },
    [createCoupon.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.coupons = payload;
    },
    [createCoupon.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [updateCoupon.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [updateCoupon.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      const index = state.coupons.findIndex(
        (coupon) => coupon.id === payload.id
      );
      state.coupons[index] = payload;
    },
    [updateCoupon.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [removeCoupon.pending]: (state) => {
      state.error = null;
    },
    [removeCoupon.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    [fetchCoupons.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [fetchCoupons.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.coupons = payload;
    },
    [fetchCoupons.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [applyCoupon.pending]: (state) => {
      state.error = null;
      state.isFetching = true;
    },
    [applyCoupon.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.appliedCoupon = payload.coupon;
      state.subtotal = payload.total;
    },
    [applyCoupon.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { removeAppliedCoupon, clearError, clearCoupons } =
  couponSlice.actions;
