import { BrowserRouter, useRoutes } from "react-router-dom";
import routes from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "./redux/features/users/UserActions";
import { fetchCountry } from "./redux/features/country/CountryActions";
import { fetchByUser } from "./redux/features/address/AddressActions";
import { fetchAdmin } from "./redux/features/admin/AdminActions";
import { selectCountry } from "./redux/features/country/CountrySlice";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";

const RenderRoutes = () => {
  let routeTree = useRoutes(routes());
  return routeTree;
};

function App() {
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const { country } = useSelector((state) => state.country);

  useEffect(() => {
    if (
      cookies["sleek_studio_user_id"] &&
      cookies["sleek_studio_user_token"] &&
      cookies["sleek_studio_user_token_id"]
    ) {
      sessionStorage.setItem(
        "sleek_studio_user_token",
        cookies["sleek_studio_user_token"]
      );
      dispatch(
        fetchUser({
          userId: parseInt(cookies["sleek_studio_user_id"]),
          onSuccess: () => {
            sessionStorage.setItem(
              "sleek_studio_user_token",
              cookies["sleek_studio_user_token"]
            );
          },
        })
      );
      dispatch(
        fetchByUser({ userId: parseInt(cookies["sleek_studio_user_id"]) })
      );
    }
    if (
      cookies["sleek_studio_admin_id"] &&
      cookies["sleek_studio_admin_token"] &&
      cookies["sleek_studio_admin_token_id"]
    ) {
      sessionStorage.setItem(
        "sleek_studio_admin_token",
        cookies["sleek_studio_admin_token"]
      );
      dispatch(
        fetchAdmin({
          userId: parseInt(cookies["sleek_studio_admin_id"]),
          onSuccess: () => {
            sessionStorage.setItem(
              "sleek_studio_admin_token",
              cookies["sleek_studio_admin_token"]
            );
          },
        })
      );
    }
    dispatch(fetchCountry());
  }, []);

  useEffect(() => {
    if (country.length > 0) {
      dispatch(selectCountry(country[0]));
    }
  }, [country]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick={true}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <BrowserRouter>
        <RenderRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
