import { createSlice } from "@reduxjs/toolkit";
import {
  addWishlistProduct,
  removeWishlistProduct,
  fetchWishlistProduct,
} from "./WishlistActions";

const initialState = {
  isFetching: false,
  error: null,
  wishlistProducts: null,
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    clearWishlist: (state) => {
      state.wishlistProducts = null;
    },
  },
  extraReducers: {
    [addWishlistProduct.pending]: (state) => {
      state.isFetching = true;
    },
    [addWishlistProduct.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      if (state.wishlistProducts) {
        state.wishlistProducts.data.push(payload.product);
      } else {
        state.wishlistProducts = { data: [payload.product] };
      }
    },
    [addWishlistProduct.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [removeWishlistProduct.pending]: (state) => {
      state.error = null;
    },
    [removeWishlistProduct.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    [fetchWishlistProduct.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchWishlistProduct.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.wishlistProducts = payload.products;
    },
    [fetchWishlistProduct.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { clearWishlist } = wishlistSlice.actions;
