import { createSlice } from "@reduxjs/toolkit";
import {
  createAddress,
  updateAdress,
  removeAddress,
  fetchByUser,
} from "./AddressActions";

const initialState = {
  addresses: [],
  isFetching: false,
  error: null,
  selectedAddress: null,
};

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    clearError: (state, { payload }) => {
      if (state.error?.message) {
        state.error = null;
      } else if (payload) {
        state.error = { ...state.error, ...payload };
      } else {
        state.error = payload;
      }
    },
    setError: (state, { payload }) => {
      if (payload?.data) {
        state.error = payload.data;
      } else {
        state.error = payload;
      }
    },
    selectAddress: (state, { payload }) => {
      state.selectedAddress = payload;
    },
    clearAddresses: (state) => {
      state.addresses = [];
    },
  },
  extraReducers: {
    [createAddress.pending]: (state) => {
      state.isFetching = true;
    },
    [createAddress.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.error = null;
      state.addresses.push(payload.address);
    },
    [createAddress.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [updateAdress.pending]: (state) => {
      state.isFetching = true;
    },
    [updateAdress.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      const index = state.addresses.findIndex(
        (address) => address.id === payload.address.id
      );
      state.addresses[index] = payload.address;
    },
    [updateAdress.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [removeAddress.pending]: (state) => {
      state.error = null;
    },
    [removeAddress.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    [fetchByUser.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchByUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.addresses = payload.addresses;
    },
    [fetchByUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { clearError, selectAddress, setError, clearAddresses } =
  addressSlice.actions;
