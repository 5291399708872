import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const create = (body) => api.post("order", body);

export const remove = (body) => api.delete("order", body, {}, "admin");

export const getAll = (params) => api.get("orders", params, "admin");

export const getByUser = (body) => api.post("user/orders", body);

export const moveToShiprocket = (body) =>
  api.post("order/move-to-shiprocket", body, {}, "", "admin");
