import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ProductCard from "./productCard";
import {
  fetchByCategory,
  fetchBySubcategory,
} from "../../../redux/features/product/productActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "../../atoms/loadingIndicator";
import PaginationContainer from "../../atoms/pagination";
import Dropdown from "../../atoms/dropdown";

export default function ProductList({ type, products, categories }) {
  const { isFetching } = useSelector((state) => state.product);
  const { isFetching: fetchingCategories } = useSelector(
    (state) => state.category
  );
  const { selectedCountry } = useSelector((state) => state.country);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const subcategories = useMemo(
    () =>
      selectedCategory?.subcategories
        ? [
            { name: "All", id: selectedCategory?.subcategories?.length },
            ...selectedCategory?.subcategories,
          ]
        : [],
    [selectedCategory]
  );

  useEffect(() => {
    if (categories?.length > 0 && selectedCountry?.id) {
      if (selectedSubcategory) {
        dispatch(
          fetchBySubcategory({
            body: {
              subcategoryId: selectedSubcategory.id,
              countryId: selectedCountry.id,
            },
            params: { page: page },
            type: type,
          })
        );
      } else if (selectedCategory) {
        dispatch(
          fetchByCategory({
            body: {
              categoryId: selectedCategory.id,
              countryId: selectedCountry.id,
            },
            params: { page: page },
            type: type,
          })
        );
      } else {
        setSelectedCategory(categories[0]);
        setPage(1);
        dispatch(
          fetchByCategory({
            body: {
              categoryId: categories[0]?.id,
              countryId: selectedCountry.id,
            },
            params: { page: 1 },
            type: type,
          })
        );
      }
    }
  }, [selectedCountry, categories]);

  const onSelectCategory = (selectedItem) => {
    const category = categories.find(
      (category) => category.name === selectedItem.name
    );
    setSelectedCategory(category);
    setPage(1);
    dispatch(
      fetchByCategory({
        body: {
          categoryId: category.id,
          countryId: selectedCountry.id,
        },
        params: { page: page },
        type: type,
      })
    );
  };

  const onSelectSubcategory = (selectedItem) => {
    if (selectedItem.name === "All") {
      setSelectedSubcategory(null);
      dispatch(
        fetchByCategory({
          body: {
            categoryId: selectedCategory.id,
            countryId: selectedCountry.id,
          },
          params: { page: page },
          type: type,
        })
      );
      return;
    }
    const subcategory = selectedCategory.subcategories?.find(
      (subcategory) => subcategory.name === selectedItem.name
    );
    setPage(1);
    setSelectedSubcategory(subcategory);
    dispatch(
      fetchBySubcategory({
        body: {
          subcategoryId: subcategory.id,
          countryId: selectedCountry.id,
        },
        params: { page: 1 },
        type: type,
      })
    );
  };

  const onChangePage = (pageNumber) => {
    if (selectedSubcategory) {
      dispatch(
        fetchBySubcategory({
          body: {
            subcategoryId: selectedSubcategory.id,
            countryId: selectedCountry.id,
          },
          params: { page: pageNumber },
          type: type,
        })
      );
    } else {
      dispatch(
        fetchByCategory({
          body: {
            categoryId: selectedCategory.id,
            countryId: selectedCountry.id,
          },
          params: { page: pageNumber },
          type: type,
        })
      );
    }
  };

  return (
    <section className="max-w-[100rem] mt-20 md:w-11/12 mx-auto px-4">
      <div className="flex flex-nowrap gap-2 pt-10 ">
        <Link to="/" className="text-green text-primary font-manrope font-bold">
          Home
          <span> / </span>
        </Link>
        <span className="text-green text-primary font-manrope font-normal ">
          {type === "men" ? "Men" : "Women"}
        </span>
      </div>

      <div className=" flex md:flex-row flex-col ml-auto max-w-xl md:gap-4 pb-8">
        <Dropdown
          placeholder="Select Category"
          containerStyle="w-full mt-4"
          dropdownInputStyle="w-full px-2 py-[8px] border border-slate-300"
          emptyListText="No category found"
          data={categories}
          selected={selectedCategory}
          onSelect={onSelectCategory}
        />
        <Dropdown
          placeholder="Select Sub Category"
          containerStyle="w-full mt-4"
          dropdownInputStyle="w-full px-2 py-[8px] border border-slate-300"
          emptyListText="No subcategory found"
          data={subcategories}
          selected={selectedSubcategory}
          onSelect={onSelectSubcategory}
        />
      </div>

      {/* {products?.data?.length ? (
        <div className="ml-auto max-w-md py-5 md:pr-5 flex md:flex-nowrap flex-wrap items-center md:justify-end gap-3 ">
          <select
            defaultValue={categories[0]?.name ?? "Category"}
            onChange={onSelectCategory}
            className="flex flex-nowrap gap-1 bg-white border-2 border-gray-300 rounded-sm px-2 py-2 items-center justify-center hover:fill-[#dab188]  fill-[#476F66]  hover:bg-[#476F66] text-[#476F66] hover:text-[#dab188] font-bold text-sm"
          >
            <option disabled hidden>
              Category
            </option>
            {categories?.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name.slice(0, 20)}
              </option>
            ))}
          </select>
          <select
            defaultValue="Subcategory"
            onChange={onSelectSubcategory}
            className="flex flex-nowrap gap-1 border-2 border-gray-300 rounded-sm  px-2 py-2 items-center justify-center fill-[#476F66] hover:fill-[#dab188] hover:bg-[#476F66] hover:text-[#dab188]  text-[#476F66] font-bold text-sm"
          >
            <option disabled hidden>
              Subcategory
            </option>
            {selectedCategory?.subcategories.map((subcategory) => (
              <option value={subcategory.name}>
                {subcategory.name.slice(0, 20)}..
              </option>
            ))}
          </select>
        </div>
      ) : null} */}

      {!isFetching && !fetchingCategories && !products?.data?.length ? (
        <div className="flex-col items-center justify-center my-20">
          <p className="text-3xl text-slate-400 text-center">Oops...</p>
          <p className="text-2xl text-center mt-8 text-slate-400">
            No products found
          </p>
        </div>
      ) : (isFetching || fetchingCategories) && !products?.data?.length ? (
        <div className="flex items-center min-h-[50vh] justify-center my-20">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="grid xl:grid-cols-4 relative lg:grid-cols-3 md:grid-cols-2 grid-cols-2 flex-wrap md:gap-5 gap-2 justify-start md:mb-28 mb-10">
          {products?.data?.map((product, index) => {
            return <ProductCard key={product.id} product={product} />;
          })}
          {isFetching && products.data?.length && (
            <div className="fixed top-0 left-0 z-[9999] right-0 w-screen bg-black bg-opacity-50 h-screen grid place-content-center">
              <div className="bg-white shadow-xl w-[100px] h-[100px] flex items-center justify-center left-[40vw] rounded-md">
                <LoadingIndicator />
              </div>
            </div>
          )}
        </div>
      )}

      {categories.length && products?.data?.length ? (
        <PaginationContainer
          totalData={products?.total}
          pageData={10}
          onChangePage={onChangePage}
          pageNumber={page}
        />
      ) : null}
    </section>
  );
}
