import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const login = (body) => api.post("login", body);

export const logout = (body) => api.post("logout", body, {}, "", "admin");

export const getProfile = (body) =>
  api.post("user/profile", body, {}, "", "admin");

export const generateOTP = (body) =>
  api.post("generate-otp", body, {}, "", "admin");

export const verifyOTP = (body) => api.post("verify-otp", body);

export const verifyEmail = (body) =>
  api.post("verify-email", body, {}, "", "admin");

export const verifyPhone = (body) =>
  api.post("verify-phone", body, {}, "", "admin");

export const resetEmailGenerateOTP = (body) =>
  api.post("generate-otp/reset-email", body, {}, "", "admin");

export const resetPasswordGenerateOTP = (body) =>
  api.post("generate-otp/reset-password", body, {}, "", "admin");

export const changePassword = (body) =>
  api.post("change-password", body, {}, "", "admin");

export const loginWithOTP = (body) => api.post("login-with-otp", body);

export const update = (body) => api.put("user/update", body, {}, "admin");
