import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";

import { Fashion } from "../../modules/core";
import axios from "axios";

import "./style.css";
import { useEffect } from "react";
import { MdCancel, MdOutlineCancel } from "react-icons/md";
const PassRecovery = () => {
  const [states, setStates] = useState({
    name: "",
    email: "",
  });
  const [otp, setOtp] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    if (isOpen) setIsOpen(false);
    else setIsOpen(true);
  };

  const handleChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;

    setStates({
      ...states,
      [name]: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("/api/register", {
          name: states.name,
          email: states.email,
          password: states.password,
          role: states.role,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    console.log("handle click");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="px-10 max-w-screen-2xl mx-auto">
        <div className="login-wrapper md:min-h-[45rem] md:h-screen h-fit flex lg:flex-row flex-col justify-center">
          <div className="lg:w-[68%] ">
            <Fashion />
          </div>
          <div className="lg:w-[38%] w-full max-w-screen-sm mx-auto md:p-10 lg:mt-0 mt-12 pb-20">
            <h4 className="text-primary h5">Password Recovery</h4>
            <form onSubmit={handleSubmit}>
              <input
                value={states.name}
                onChange={handleChange}
                name="name"
                type="number"
                placeholder="New Password"
                className="input-style mt-6"
              />
              <input
                value={states.email}
                onChange={handleChange}
                name="email"
                type="number"
                placeholder="Confirm Password"
                className="input-style mt-6"
              />
              <button
                type="submit"
                className="btn-secondary-fill w-full md:py-3 py-2 rounded mt-8 text-xl"
                onClick={handleModal}
              >
                Change Password
              </button>
            </form>
          </div>
        </div>

        <div
          className={`${
            isOpen ? "opacity-1" : "opacity-0 hidden duration-400"
          } modal absolute top-0 left-0 h-[100vh] duration-400 transition-all w-full bg-slate-900 bg-opacity-60 z-[999999] flex justify-center items-center`}
        >
          <div
            className={`${
              isOpen ? "fade-up" : "opacity-0 hidden"
            } duration-400 transition-all relative p-4`}
          >
            <div className="flex justify-center items-center gap-8 flex-col w-full max-w-md min-h-[27rem] bg-white p-10">
              <h6 className="text-center h6 max-w-xs mx-auto text-primary">
                Please enter the OTP sent to email@gmail.com
              </h6>
              <OtpInput
                onChange={setOtp}
                value={otp}
                inputStyle="otp-style border-b-2 border-primary"
                numInputs={4}
                isInputNum={true}
                separator={<span>--</span>}
              />
              <button className="btn-secondary-fill rounded w-full">
                Verify
              </button>

              <h3 className="h6 text-primary font-medium">
                Not received your OTP?
                <span className="text-secondary"> Resend OTP</span>
              </h3>
            </div>

            <MdOutlineCancel
              className="text-3xl text-secondary absolute top-8 right-8 cursor-pointer hover:text-primary transition"
              onClick={handleModal}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default PassRecovery;
