import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./family.css";

import leaf from "../../../assets/home/icons/leaf.svg";
// import MobileView from './mobileView';
import gsap from "gsap";
import { SwitchTransition, Transition } from "react-transition-group";
import { Link } from "react-router-dom";
import { category } from "../../../libs/data/homepage";

export default function Family({ defaultTab = 0 }) {
  const [activeTab, setActiveTab] = React.useState(defaultTab);
  const [slide, setSlide] = React.useState(0);
  const [activeImage, setActiveImage] = useState(0);

  React.useEffect(() => {
    // console.log(activeImage);
  }, [activeImage]);

  React.useEffect(() => {
    // const tabs = document.querySelectorAll('[role="tab"]');
    // const arrowKeyHandler = (event) => {
    //   let nextTab;
    //   switch (event.key) {
    //     case "ArrowRight":
    //       nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
    //       setActiveTab(nextTab);
    //       tabs[nextTab].focus();
    //       break;
    //     case "ArrowLeft":
    //       nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
    //       setActiveTab(nextTab);
    //       tabs[nextTab].focus();
    //       break;
    //     default: {
    //     }
    //   }
    // };
    // document.addEventListener("keyup", arrowKeyHandler);
    // return () => {
    //   document.removeEventListener("keyup", arrowKeyHandler);
    // };
  }, [activeTab]);

  // React.useEffect(() => {
  //   const slider = document.querySelector(".slider");
  //   const width = slider.offsetWidth;
  //   let slideIt = Math.round(activeTab * (width / 3));

  //   setSlide(slideIt);
  //   console.log(width, slide, activeTab, "width");
  // }, [activeTab, slide]);

  return (
    <section className="oursuite-wrapper blade-padding  ">
      <div className="">
        <div
          className="tabbed-container sm:flex flex-col"
          role="tablist"
          aria-label="Top Footballers"
          data-aos="fade-up"
        >
          <div className="tabs-wrapper max-w-screen-md mx-auto flex justify-evenly border-b-[1px] border-[#dab1884d] relative border-opacity-50">
            {category.map((tab, index) => {
              const active = activeTab === index;
              return (
                <button
                  className={`${
                    active ? "active transition-all duration-300" : ""
                  } text-sm md:text-2xl text-white pb-5 md:px-24 px-4 font-manrope transition-all duration-300`}
                  key={tab.id}
                  role="tab"
                  aria-selected={active}
                  aria-controls={`panel-${index}`}
                  id={`tab-${index}`}
                  tabIndex={active ? 0 : -1}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </button>
              );
            })}

            <div className="slider absolute w-full border-b-secondary hidden bottom-0 left-0">
              <div
                className={`indicator transition-all duration-200 h-1 w-[33%] bg-secondary`}
              ></div>
            </div>
          </div>
          {category.map((tab, index) => {
            const { src } = tab;
            return (
              <section
                key={tab.id}
                id={`panel-${index}`}
                role="tabpanel"
                tabIndex="0"
                aria-labelledby={`tab-${index}`}
                hidden={activeTab !== index}
                data-aos="fade-up"
              >
                <div className="content-wrapper">
                  <div className="left-wrapper relative">
                    <h2 className="text-secondary h4 font-lora uppercase gap-4 flex md:hidden">
                      {tab.title} <img className="w-[7%]" src={leaf} alt="" />
                    </h2>
                    <div className="frame relative md:mt-0 mt-3 2xl:max-h-[700px] max-h-[650px] xl:h-[700px] h-[25rem] w-full">
                      <img
                        className="h-full w-full object-cover object-center"
                        src={tab.src[activeImage].name}
                        alt="Product display"
                      />
                    </div>
                  </div>

                  <div className="txt-wrapper">
                    <h2 className="text-secondary h4 font-lora uppercase gap-4 md:flex hidden">
                      {tab.title} <img className="w-[7%]" src={leaf} alt="" />
                    </h2>

                    <div className="relative track-oursuite flex md:flex-col flex-col-reverse items-start ">
                      <ul className="mt-2">
                        <li className="p text-white md:py-3 py-1 font-manrope  leading-normal">
                          {tab.li1}
                        </li>
                        <li className="p text-white md:py-3 py-1  font-manrope  leading-normal">
                          {tab.li2}
                        </li>
                        <li className="md:py-5 py-2 mt-4">
                          <Link
                            to="/contact"
                            className="btn-secondary font-manrope md:px-10 px-5 md:py-3 py-1 p"
                          >
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                      <div className="side-wrapper grid grid-cols-3 gap-5 mt-5">
                        {src.map((img, ind) => {
                          return (
                            <div
                              key={ind}
                              className={`${
                                activeImage === ind
                                  ? "outline hover:scale-100"
                                  : ""
                              } relative cursor-pointer w-full hover:outline group outline-secondary overflow-hidden 2xl:h-60 xl:h-44 lg:h-48 h-fit`}
                            >
                              <img
                                key={img.id}
                                className="w-full h-full object-cover object-center group-hover:scale-110 transition-all duration-500"
                                src={img.name}
                                onClick={() => {
                                  setActiveImage(ind);
                                }}
                                alt="product options"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      </div>
    </section>
  );
}
