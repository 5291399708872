import { createSlice } from "@reduxjs/toolkit";
import {
  createOrder,
  removeOrder,
  fetchAll,
  fetchByUser,
} from "./OrderActions";

const initialState = {
  orders: null,
  authOrders: null,
  isFetching: false,
  error: null,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearOrders: (state) => {
      state.orders = null;
    },
    clearError: (state, payload) => {
      if (state.error?.message || payload === null) {
        state.error = null;
      } else {
        state.error = { ...state.error, ...payload };
      }
    },
    clearAuthOrders: (state) => {
      state.authOrders = null;
    },
  },
  extraReducers: {
    [createOrder.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [createOrder.fulfilled]: (state) => {
      state.isFetching = false;
    },
    [createOrder.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [removeOrder.pending]: (state) => {
      state.error = null;
    },

    [removeOrder.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    [fetchAll.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [fetchAll.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.orders = payload;
    },
    [fetchAll.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [fetchByUser.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [fetchByUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.authOrders = payload;
    },
    [fetchByUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { clearOrders, clearError, clearAuthOrders } = orderSlice.actions;
