import React from "react";
import Sleek_studio from "../../../assets/about/purpose.jpg";
import Leaf_about from "../../../assets/about/leaf-about.png";

const Purpose = () => {
  return (
    <div className="blade-padding contain">
      <h1 className="h1 font-lora text-primary text-center leading-none md:-mb-10 mb-5 z-50">
        Our Purpose
      </h1>
      <div className="md:flex max-w-screen-lg mx-auto relative -z-10 md:pb-32">
        <div className="relative">
          <img className="w-full" src={Sleek_studio} alt="" />
          <img
            className="absolute md:w-52 w-32 md:-bottom-[8%] bottom-3 md:-left-[8%] right-5 sm:block hidden"
            src={Leaf_about}
            alt=""
          />
        </div>
        <div className="mt-auto  md:w-[40%] md:pl-10 pb-10">
          <h6 className="h6 font-manrope text-primary leading-normal">
            The purpose we have set for ourselves and our heirs isn’t in
            superficial designs, projects, buildings, revenues, and timelines.
            It’s in the way we reinforce our place as the leading occasion-wear
            in the Indian couture market.
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Purpose;
