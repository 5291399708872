import { useMemo } from "react";
import "./product.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCartProduct } from "../../../redux/features/cart/CartActions";
import { addWishlistProduct } from "../../../redux/features/wishlist/WishlistActions";
import { baseURL } from "../../../helpers";
import { useCookies } from "react-cookie";
import CartOutline from "../../icons/CartOutline";
import HeartOutline from "../../icons/HeartOutline";
import PreviewOutline from "../../icons/PreviewOutline";
import { toast } from "react-toastify";

export default function ProductCard({ product }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCountry } = useSelector((state) => state.country);
  const { user } = useSelector((state) => state.auth);
  const { wishlistProducts } = useSelector((state) => state.wishlist);
  const [cookies] = useCookies();

  const onAddProductToCart = () => {
    if (
      user &&
      user.email_verified_at &&
      cookies["sleek_studio_user_id"] &&
      cookies["sleek_studio_user_token"] &&
      cookies["sleek_studio_user_token_id"]
    ) {
      dispatch(
        addCartProduct({
          userId: user.id,
          productId: product.id,
          onSuccess: () => {
            toast.success("Product added to the cart", {
              position: "bottom-center",
              autoClose: 3000,
            });
          },
        })
      );
    } else {
      navigate("/signup");
    }
  };

  const isAddedToWishlist = useMemo(
    () => wishlistProducts?.data?.some((item) => item?.id === product?.id),
    [wishlistProducts, product?.id]
  );

  const onAddProductToWishlist = () => {
    if (
      user &&
      user.email_verified_at &&
      cookies["sleek_studio_user_id"] &&
      cookies["sleek_studio_user_token"] &&
      cookies["sleek_studio_user_token_id"]
    ) {
      if (!isAddedToWishlist) {
        dispatch(
          addWishlistProduct({
            userId: user.id,
            productId: product?.id,
            onSuccess: () => {
              toast.success("Product added to the wishlist", {
                autoClose: 3000,
                position: "bottom-center",
              });
            },
          })
        );
      }
    } else {
      navigate("/signup");
    }
  };

  return (
    <div className="basis-[370px] grow-0 shrink relative group md:mb-5 mb-3">
      <Link to={`product/${product.id}`} className="block">
        <div className="min-h-[300px]">
          <img
            src={`${baseURL}${product.files[0]?.url}`}
            className="xl:h-[500px] md:h-[400px] h-full min-h-[300px] w-full object-cover object-top "
            alt=""
            loading="lazy"
            decoding="async"
          />
        </div>
      </Link>
      <div className="md:mt-2 mt-1 flex md:flex-row flex-col flex-nowrap md:gap-2 gap-1">
        <div className="flex-1">
          <Link
            to={`product/${product.id}`}
            className="text-primary font-semibold md:text-base text-[12px] block"
          >
            <p className="md:text-base text-[12px] text-primary font-manrope font-bold">
              {`${product.title.slice(0, 50)}`}
            </p>
          </Link>
        </div>
        <div className="flex-0 md:px-2 md:text-base text-[14px] font-manrope text-secondary font-semibold  flex justify-between md:items-start items-center">
          {`${selectedCountry.symbol} ${parseInt(product.mrp)}`}{" "}
          <button
            onClick={onAddProductToWishlist}
            className={`${
              isAddedToWishlist
                ? "fill-[#476f66]"
                : "fill-transparent hover:fill-[#476f66]"
            } bg-white text-primary font-manrope rounded-full md:hidden block`}
          >
            <HeartOutline color="#476f66" width="20" height="20" />
          </button>
        </div>
      </div>
      <div className="h-fit w-fit opacity-0 cursor-pointer group-hover:translate-y-0 translate-y-5 absolute group-hover:opacity-100 transition-all duration-500 ease-out bottom-[10%] left-0 right-0 mx-auto mb-[10%] md:flex hidden justify-center px-3 items-center flex-nowrap gap-6">
        {/* <button
          onClick={onAddProductToCart}
          className=" bg-white border-2 fill-transparent hover:fill-primary border-primary text-primary font-manrope  p-2 rounded-full"
        >
          <CartOutline color="#476f66" />
        </button> */}
        <Link to={`product/${product.id}`}>
          <PreviewOutline
            onClick={onAddProductToCart}
            className=" bg-white border-2 cursor-pointer duration-300 hover:fill-white fill-primary hover:bg-primary border-primary text-primary font-manrope  p-2 rounded-full"
            width="46"
            height="44"
          />
        </Link>
        <button
          onClick={onAddProductToWishlist}
          className={`${
            isAddedToWishlist
              ? "fill-[#476f66]"
              : "fill-transparent hover:fill-[#476f66]"
          } bg-white border-2 p-2  border-primary text-primary font-manrope rounded-full`}
        >
          <HeartOutline color="#476f66" width="26" height="25" />
        </button>
      </div>
    </div>
  );
}
