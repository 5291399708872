import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/features/users/UserActions";
import PhoneInput from "../../../modules/atoms/PhoneInput";
import { useEffect } from "react";
import { modifiedPhoneNumber } from "../../../helpers.js";
import { generateOTP, verifyOTP, verifyPhone } from "../../../api/User";
import OTPPopup from "../../../modules/overlays/otpPopup";
import { clearError, setError } from "../../../redux/features/users/userSlice";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./personal.css";
import moment from "moment";

const checkdate = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date)) {
      // d.getTime() or d.valueOf() will also work
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const EditPersonal = ({ setProfileDetail }) => {
  const [user, setUser] = useState({
    name: null,
    lastName: null,
    email: null,
    dob: null,
    gender: null,
    phoneNumber: null,
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [openCalender, setOpenCalender] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const dispatch = useDispatch();
  const {
    error,
    user: loggedInUser,
    isFetching,
  } = useSelector((state) => state.auth);
  const { country } = useSelector((state) => state.country);

  const handleChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    dispatch(clearError({ [name]: null }));
    setUser({ ...user, [name]: val });
  };

  const onSendOTP = async () => {
    await generateOTP({
      email: loggedInUser.email,
      type: "phone",
      role: "customer",
    })
      .then((res) => {
        if (res.status && res.data) {
          setOpenPopup(true);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onVerifyOTP = async (otp) => {
    await verifyOTP({ email: loggedInUser.email, type: "phone", otp: otp })
      .then(async (res) => {
        if (res.status && res.data) {
          await verifyPhone({ userId: loggedInUser?.id })
            .then((res) => {
              if (res.status && res.data) {
                setOpenPopup(false);
                setProfileDetail(false);
              }
            })
            .catch((error) => console.log(error));
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onClosePopup = () => {
    dispatch(clearError(null));
    setOpenPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      `${selectedCountry.code}${user.phoneNumber}` ===
        loggedInUser.phoneNumber ||
      user.phoneNumber === "" ||
      user.phoneNumber == null
    ) {
      dispatch(
        updateUser({
          user: {
            ...user,
            dob: user?.dob ? moment(user?.dob).format("YYYY-MM-DD") : user.dob,
            phoneNumber: null,
            userId: loggedInUser.id,
          },
          onSuccess: () => {
            setProfileDetail(false);
          },
        })
      );
      return;
    } else {
      dispatch(
        updateUser({
          user: {
            ...user,
            dob: user?.dob ? moment(user?.dob).format("YYYY-MM-DD") : user.dob,
            phoneNumber: `${selectedCountry.code}${user.phoneNumber}`,
            countryName: selectedCountry.name,
            userId: loggedInUser.id,
          },
          onSuccess: async () => {
            await onSendOTP();
          },
        })
      );
    }
  };

  useEffect(() => {
    if (loggedInUser?.phoneNumber && loggedInUser?.countryName) {
      const selectedCountry = country.find(
        (country) => country.name === loggedInUser.countryName
      );
      setSelectedCountry(selectedCountry);
    } else {
      setSelectedCountry(country[0]);
    }

    return () => {
      dispatch(clearError(null));
    };
  }, []);

  const profileInput = useMemo(
    () => [
      {
        name: "name",
        value: user.name ?? loggedInUser.name,
        id: 1,
        placeholder: "First Name",
        type: "normal",
      },
      {
        name: "lastName",
        value: user.lastName ?? loggedInUser.lastName,
        id: 2,
        placeholder: "Last Name",
        type: "normal",
      },
      {
        name: "phoneNumber",
        value:
          user.phoneNumber ?? modifiedPhoneNumber(loggedInUser.phoneNumber),
        id: 4,
        placeholder: "Phone Number",
        type: "normal",
      },
      {
        name: "email",
        value: loggedInUser.email,
        id: 3,
        placeholder: "Email",
        type: "normal",
      },
      {
        name: "gender",
        value: user.gender ?? loggedInUser.gender ?? "Gender",
        id: 5,
        placeholder: "Gender",
        type: "select",
      },
      {
        name: "dob",
        value:
          user.dob != null
            ? user.dob
            : loggedInUser.dob && loggedInUser.dob !== ""
            ? new Date(loggedInUser.dob)
            : "",
        id: 6,
        placeholder: "Date Of Birth",
        type: "date",
      },
    ],
    [loggedInUser, user]
  );

  return (
    <>
      <OTPPopup
        data={user.phoneNumber}
        show={openPopup}
        onClose={onClosePopup}
        onVerify={onVerifyOTP}
        onResend={onSendOTP}
      />
      <div className="slider my-12">
        <div className="flex justify-between">
          <h6 className="h5 text-primary font-semibold font-manrope ">
            EDIT PERSONAL DETAILS
          </h6>
        </div>
        <hr className="mt-10 md:mt-10 hr-line" />
        <div className="">
          <form className="my-10 grid md:grid-cols-2 gap-5">
            {profileInput.map((input) => (
              <div key={input.id} className="flex flex-col">
                {input.type === "normal" ? (
                  input.name === "phoneNumber" ? (
                    <div>
                      <PhoneInput
                        placeholder={input.placeholder}
                        value={input.value}
                        name={input.name}
                        onChange={handleChange}
                        selectedCountry={selectedCountry}
                        onSelectCountry={(country) =>
                          setSelectedCountry(country)
                        }
                      />
                      {error && error?.[input.name] && (
                        <p className="error-text">{error?.[input.name][0]}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <input
                        type="text"
                        placeholder={input.placeholder}
                        className="py-3  px-4 outline-[#476F66] input-secondary "
                        value={input.value}
                        disabled={input.name === "email"}
                        onChange={handleChange}
                        name={input.name}
                      />
                      {error && error?.[input.name] && (
                        <p className="error-text">{error?.[input.name][0]}</p>
                      )}
                    </div>
                  )
                ) : input.type === "date" ? (
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="DOB"
                      value={
                        checkdate(input.value)
                          ? moment(input.value).format("DD-MM-YYYY")
                          : ""
                      }
                      onChange={(e) => {
                        console.log("changed date", e.target.value);
                        setUser({ ...user, dob: e.target.value });
                      }}
                      name={input.name}
                      onClick={() => setOpenCalender(!openCalender)}
                      className="py-3 px-4 outline-[#476F66] input-secondary "
                    />
                    {openCalender && (
                      <Calendar
                        value={checkdate(input.value) ? input.value : ""}
                        onChange={(value) => {
                          setUser({
                            ...user,
                            dob: value,
                          });
                          setOpenCalender(false);
                        }}
                        className="calender"
                      />
                    )}
                    {error && error?.dob && (
                      <p className="error-text">{error.dob[0]}</p>
                    )}
                  </div>
                ) : (
                  <div>
                    <select
                      placeholder={input.placeholder}
                      name={input.name}
                      autoComplete="off"
                      value={input.value}
                      onChange={handleChange}
                      className="py-3 px-4 outline-[#476F66] input-secondary"
                    >
                      <option className="text-slate-500" disabled hidden>
                        Gender
                      </option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Others</option>
                    </select>
                    {error && error.gender && (
                      <p className="error-text">{error.gender[0]}</p>
                    )}
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={() => setProfileDetail(false)}
              className="btn-primary"
            >
              Cancel
            </button>
            <button onClick={handleSubmit} className="btn-secondary rounded ">
              {isFetching ? (
                <div className="flex items-center justify-center">
                  <LoadingIndicator
                    indicatorStyle={{
                      width: "30px",
                      height: "30px",
                      borderTop: "5px solid #dab188",
                    }}
                  />
                </div>
              ) : (
                "SAVE"
              )}
            </button>
          </form>
          {error && error?.message && (
            <p className="error-text">{error.message}</p>
          )}
        </div>
      </div>
    </>
  );
};
