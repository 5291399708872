import { createAsyncThunk } from "@reduxjs/toolkit";
import { add, remove, get } from "../../../api/Cart";

export const addCartProduct = createAsyncThunk(
  "cart/add",
  async ({ userId, productId, measurements, onSuccess }, thunkAPI) => {
    return await add({ userId, productId, ...measurements })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const removeCartProduct = createAsyncThunk(
  "cart/remove",
  async ({ userId, cartId, onSuccess }, thunkAPI) => {
    return await remove({ userId, cartId })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchCartProducts = createAsyncThunk(
  "cart/products",
  async ({ userId, countryId }, thunkAPI) => {
    return await get({ userId, countryId })
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
