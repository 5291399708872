import { createAsyncThunk } from "@reduxjs/toolkit";
import { create, update, remove, getByUser } from "../../../api/Address";

export const createAddress = createAsyncThunk(
  "address/create",
  async ({ address, onSuccess }, thunkAPI) => {
    return await create(address)
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const updateAdress = createAsyncThunk(
  "address/update",
  async ({ address, onSuccess }, thunkAPI) => {
    return await update(address)
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const removeAddress = createAsyncThunk(
  "address/remove",
  async ({ addressId, onSuccess }, thunkAPI) => {
    return await remove({ addressId })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchByUser = createAsyncThunk(
  "address/user",
  async ({ userId }, thunkAPI) => {
    return await getByUser({ userId })
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
