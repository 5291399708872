import React, { useState, useEffect } from "react";
import Search from "../../../modules/atoms/search";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSubcategory,
  removeSubcategory,
  updateCategory,
} from "../../../redux/features/category/CategoryActions";
import AddCategoryPopup from "../../../modules/overlays/AddCategoryPopup";
import BackButton from "../../../modules/atoms/backButton";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";
import { clearError } from "../../../redux/features/category/CategorySlice";
import WarningPopup from "../../../modules/overlays/warningPopup";

const SubcategoryList = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { categories, error } = useSelector((state) => state.category);
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    if (categories?.length > 0) {
      const category = categories.find(
        (category) => category.id === parseInt(categoryId)
      );

      category && setSelectedCategory(category);
      category?.subcategories && setSubcategories(category?.subcategories);
    }
  }, [categoryId, categories]);

  const onDelete = () => {
    dispatch(
      removeSubcategory({
        subcategoryIds: selected,
        onSuccess: () => {
          setSelected([]);
          dispatch(fetchSubcategory(parseInt(categoryId)));
        },
      })
    );
  };

  const onAdd = (newSubcategory) => {
    dispatch(
      updateCategory({
        categoryId: parseInt(categoryId),
        subcategory: newSubcategory.name,
        onSuccess: () => {
          setShowPopup(false);
        },
      })
    );
  };

  const onSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(subcategories.map((subcategory) => subcategory.id));
    } else {
      setSelected([]);
    }
  };

  const onClickDelete = () => {
    if (selected.length > 0) {
      setShowConfirmationPopup(true);
    } else {
      return;
    }
  };

  return (
    <div className="w-full h-full px-12 py-12">
      <ConfirmationPopup
        show={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={onDelete}
        popupStyle="h-[200px]"
        title={`Are you sure you want to delete ${
          selected.length > 1 ? "these subcategories" : "this subcategory"
        }? All products linked with 
        ${
          selected.length > 1 ? "these subcategories" : "this subcategory"
        } will be deleted.`}
      />
      <WarningPopup
        show={error}
        title={error?.message}
        onClose={() => dispatch(clearError(null))}
      />
      <AddCategoryPopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
        onSubmit={onAdd}
        popupType="subcategory"
      />
      <BackButton />
      <div className="flex mt-8 items-center justify-between">
        <h2 className="h6 font-bold">Subcategories</h2>
        <button
          onClick={() => setShowPopup(true)}
          className="text-white bg-primary h-full py-2 px-4 rounded-sm"
        >
          Add Subcategory
        </button>
      </div>

      <div className="mt-8 bg-white border border-solid pb-12 rounded-sm border-slate-200">
        <div className="flex items-center px-4 py-8 border-b border-slate-200 ">
          <p className="font-bold text-lg">Category:</p>
          <p className="text-lg ml-2">{selectedCategory?.name}</p>
        </div>
        {subcategories.length > 0 ? (
          <table className="w-full ">
            <thead className="border-b border-solid border-b-slate-200">
              <tr className="text-black text-sm">
                <th className="py-4 w-[180px] px-4 text-left">
                  <input
                    onChange={(e) => onSelectAll(e)}
                    className="w-5 cursor-pointer"
                    type="checkbox"
                  />
                </th>
                <th className="text-left">Name</th>
                <th className="text-left">
                  <span
                    onClick={onClickDelete}
                    className={`flex ${
                      selected.length > 0
                        ? "text-error cursor-pointer"
                        : "text-slate-400"
                    } items-center`}
                  >
                    Delete <MdDeleteOutline size={18} />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {subcategories?.map((subcategory) => (
                <tr
                  className="py-4 border-b border-slate-200"
                  key={subcategory.id}
                >
                  <td className="py-4 px-4">
                    <input
                      onChange={() => onSelect(subcategory.id)}
                      className="w-5 cursor-pointer"
                      checked={selected.includes(subcategory.id)}
                      type="checkbox"
                    />
                  </td>
                  <td>{subcategory.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex p-4 items-center justify-center">
            <h2 className="text-xl">No categories found</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubcategoryList;
