import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const create = (body) =>
  api.post("category/upload", body, {}, "", "admin");

export const update = (body) =>
  api.put("category/add-subcategory", body, {}, "admin");

export const remove = (body) =>
  api.delete("category/remove", body, {}, "admin");

export const get = ({ body, params }) => api.post("categories", body, params);

export const removeSubcategories = (body) =>
  api.delete("subcategory/remove", body, {}, "admin");

export const getsubcategories = (body) => api.post("subcategories", body);

export const getById = (body) => api.post("category", body);
