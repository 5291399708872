import React from "react";
import { useEffect } from "react";
import "./world.css";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const SleekWorld = () => {
  useEffect(() => {
    gsap.fromTo(
      ".target",
      {
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: ".pin-target",
          pin: true,
          scrub: true,
          start: "top top",
          end: "bottom 100%",
          markers: false,
        },
        opacity: 1,
        x: 10,
      }
    );
  });

  const worlds = [
    {
      num: "01",
      src: "https://images.unsplash.com/photo-1654707634812-3ce01a6258f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=760&q=80",
      title: "CURATING DISTINCTIVE STYLE STATEMENTS WITHIN A DAY!",
      desc: "We excel at bringing out contemporary Indian artisanal values and creating the couture you desire within days.",
    },
    {
      num: "02",
      src: "https://images.unsplash.com/photo-1523380677598-64d85d015339?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "MEET YOUR MATCH",
      desc: "At Sleek Studio what inspires you shall be designed with intricate work and dedicated artisans.",
    },
    {
      num: "03",
      src: "https://images.unsplash.com/photo-1654707634812-3ce01a6258f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=760&q=80",
      title: " CRAFTSMEN OF GLAMOR",
      desc: "Our team of artisans bind every thread together in-house to create the perfect ensemble for you.",
    },
    {
      num: "04",
      src: "https://images.unsplash.com/photo-1523380677598-64d85d015339?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "GLOBALLY INDIAN!",
      desc: "Designing exemplary collections in collaboration with overseas designers to deliver charismatic apparel across the globe.",
    },
  ];
  return (
    <>
      <div className="pin-target overflow-hidden ">
        <h4 className="h3 max-w-screen-md mx-auto text-center text-primary font-lora">
          Welcome to the Sleek Studio
          <i className="font-semibold"> World ! </i>
        </h4>
        <div className="flex target d mt-10 mb-32">
          {worlds.map((items, index) => (
            <div className="w-[24%] p-5" key={index}>
              <p className="">{items.num}</p>
              <img className="" src={items.src} alt=" " />
              <h6 className="">{items.title}</h6>
              <p className="">{items.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SleekWorld;
