import React from "react";
import "./handcraft.css";
import him from "../../../assets/home/crafted-for-him.png";
import her from "../../../assets/home/crafted-for-her.png";
import { MasterLink } from "../../../modules/atoms";

const Handcraft = () => {
  const cards = [
    {
      img: him,
      title: " Crafted for him",
      link: "men",
    },
    {
      img: her,
      title: "Crafted for her",
      link: "women",
    },
  ];
  return (
    <>
      <section className="blade-padding contain ">
        <h6 className="2xl:text-[28px] xl:text-xl text-center text-secondary font-manrope font-semibold">
          Handcrafted Luxury
        </h6>
        <h4 className="h4 text-primary text-center font-lora 2xl:w-[65%] lg:w-[90%] m-auto leading-tight sm:mt-8 mt-4">
          Catering the best of fashion for contemporary off-the-rack
          <i className="font-lora font-normal"> shoppers </i>
          like you!
        </h4>
        <div className="flex flex-nowrap items-center justify-center flex-col gap-3 sm:flex-row max-w-[100rem] mx-auto  my-12 md:my-16">
          {cards.map((item, ind) => {
            return (
              <div className="relative">
                <div
                  className="w-full relative max-w-4xl 2xl:h-[800px] lg:h-[500px] mx-auto"
                  key={ind}
                >
                  <img
                    src={item.img}
                    alt=""
                    className="object-cover object-top w-full h-full"
                  />

                  <div className="flex flex-col items-center justify-center pb-3 pt-1 gap-2 md:absolute top-0 left-0 right-0 bottom-0 md:bg-black bg-primary md:bg-opacity-80  hover:opacity-100 md:opacity-0 transition-all duration-150 ease-linear text-center">
                    <span className=" text-[24px] sm:text-[34px] color-skin font-lora md:text-secondary text-white">
                      {item.title}
                    </span>

                    <MasterLink
                      title="Shop Now"
                      link={`collections/${item.link}`}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Handcraft;
