import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/features/admin/AdminActions";
import { clearOrders } from "../../../redux/features/order/OrderSlice";
import { clearCoupons } from "../../../redux/features/coupon/CouponSlice";
import { clearUsers } from "../../../redux/features/users/userSlice";
import { useCookies } from "react-cookie";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";

const Profile = () => {
  const { admin } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [cookie, setCookie, removeCookie] = useCookies([
    "sleek_studio_admin_id",
    "sleek_studio_admin_token",
    "sleek_studio_admin_token_id",
  ]);
  const [showPopup, setShowPopup] = useState(false);

  const avatarText = useMemo(
    () =>
      admin?.name
        ?.split(" ")
        ?.map((word, index) => (index < 2 ? word[0] : null))
        ?.join("")
        ?.toUpperCase(),
    [admin.name]
  );

  const onLogout = () => {
    dispatch(
      logout({
        userId: cookie["sleek_studio_admin_id"],
        tokenId: cookie["sleek_studio_admin_token_id"],
        onSuccess: () => {
          sessionStorage.removeItem("sleek_studio_admin_token");
          removeCookie("sleek_studio_admin_id", { path: "/" });
          removeCookie("sleek_studio_admin_token", { path: "/" });
          removeCookie("sleek_studio_admin_token_id", { path: "/" });
          dispatch(clearOrders());
          dispatch(clearCoupons());
          dispatch(clearUsers());
        },
      })
    );
  };
  return (
    <div className="w-full px-12 pt-12">
      <ConfirmationPopup
        show={showPopup}
        title="Are you sure you want to log out?"
        onClose={() => setShowPopup(false)}
        onConfirm={onLogout}
      />
      <div className="w-full max-w-[1000px] bg-white py-12  px-8 rounded-md flex justify-between">
        <div className="flex items-center">
          <span
            className="w-[85px] h-[80px] rounded-[100px] bg-[#f7f7f7]
        flex items-center justify-center text-2xl font-bold text-primary"
          >
            {avatarText}
          </span>
          <div className="flex flex-col justify-center ml-8">
            <h3 className="capitalize;">{admin?.name}</h3>
            <p className=" text-[#8a8a8a]">{admin?.email}</p>
          </div>
        </div>
        <button
          onClick={() => setShowPopup(true)}
          className="text-error text-lg cursor-pointer font-bold"
        >
          Logout
        </button>
      </div>
      <div className="flex w-full max-w-[1000px] mt-12">
        <div className="bg-white mr-[1%] px-8 py-12 w-[49%] rounded-md border border-slate-200">
          <div className="flex items-center justify-between">
            <p className="font-bold text-lg">Password</p>
            <Link
              className="bg-white border border-primary py-1 px-8 text-primary rounded-sm"
              to="/admin/password-reset"
            >
              Change
            </Link>
          </div>
          <p className="mt-6 text-slate-400">
            You can reset your password by clicking here
          </p>
        </div>
        <div className="bg-white ml-[1%] px-8 py-12 w-[49%] rounded-md border border-slate-200">
          <div className="flex items-center justify-between">
            <p className="font-bold text-lg">Email</p>
            <Link
              className="bg-white border border-primary py-1 px-8 text-primary rounded-sm"
              to="/admin/email-reset"
            >
              Change
            </Link>
          </div>
          <p className="mt-6 text-slate-400">
            You can reset your email by clicking here
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
