import React from "react";
import CountrySelector from "./CountrySelector";

const PhoneInput = ({
  name,
  value,
  onChange,
  placeholder,
  selectedCountry,
  onSelectCountry,
  style,
}) => {
  return (
    <div
      className={`${
        style ? style : "input-secondary"
      } flex relative items-center px-0 `}
    >
      <CountrySelector
        showCountryCode
        onSelectCountry={onSelectCountry}
        selectedCountry={selectedCountry}
      />
      <input
        placeholder={placeholder}
        maxLength="12"
        name={name}
        value={value}
        type="number"
        onChange={onChange}
        className="focus:outline-none w-[78%] py-[10px] md:py-0 h-full focus:border-none border-none"
      />
    </div>
  );
};

export default PhoneInput;
