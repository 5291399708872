import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { baseURL } from "../../../helpers";
import moment from "moment";
import {
  paymentMethods,
  orderStatus,
  paymentStatus,
} from "../../../assets/data/orderData";
import BackButton from "../../../modules/atoms/backButton";
import { moveToShiprocket as moveToShiprocketAPI } from "../../../api/Order";
import { toast } from "react-toastify";
import MeasurementPopup from "../../../modules/overlays/warningPopup/MeasurementPopup";

const OrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { country } = useSelector((state) => state.country);
  const [order, setOrder] = useState(null);
  const { orderId } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const order = orders?.data?.find((order) => order.id === parseInt(orderId));
    setOrder(order);
  }, [orders]);

  const moveToShiprocket = async () => {
    await moveToShiprocketAPI({ orderId: order.id }).then((res) => {
      if (res.status && res.data) {
        toast.success("Order has been moved to the Shiprocket successfully", {
          position: "top-right",
        });
      }
    });
  };

  const onSelectProduct = (product) => {
    setSelectedProduct(product);
    setShowPopup(true);
  };

  const orderCountry = useMemo(
    () =>
      country?.find(
        (country) => country.currencyCode === order?.payment?.currency
      ),
    [country, order]
  );

  return (
    <div className="w-full h-full px-12 py-12">
      <MeasurementPopup
        show={showPopup}
        onClose={setShowPopup}
        product={selectedProduct}
      />
      <BackButton />
      <h2 className="h6 font-bold mt-10">Order Details</h2>
      <div className="border rounded-md py-12 mt-6 border-slate-200 bg-white">
        <div className="flex  w-full items-end justify-end px-4">
          <button
            onClick={moveToShiprocket}
            className="text-primary cursor-pointer bg-white border-none font-semibold"
          >
            Add To Shiprocket
          </button>
        </div>
        <div className="w-full flex mt-4 justify-around">
          <div className="w-[48%] p-4 border border-slate-200 rounded-sm">
            <p className="font-semibold text-slate-400 text-lg">
              Customer Details
            </p>
            <div className="flex items-center mt-2">
              <p className="font-semibold">First Name:</p>
              <p className="ml-2">{order?.user?.name}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Last Name:</p>
              <p className="ml-2">{order?.user?.lastName}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Email:</p>
              <p className="ml-2">{order?.user?.email}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Phone Number:</p>
              <p className="ml-2">{order?.user?.phoneNumber}</p>
            </div>
          </div>

          <div className="w-[48%] p-4 border border-slate-200 rounded-sm">
            <p className="font-semibold text-slate-400 text-lg">
              Address Details
            </p>
            <div className="flex items-center mt-2">
              <p className="font-semibold">Address Line 1:</p>
              <p className="ml-2">{order?.address?.addressLine1}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Address Line 2:</p>
              <p className="ml-2">{order?.address?.addressLine2}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">City:</p>
              <p className="ml-2">{order?.address?.city}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">State:</p>
              <p className="ml-2">{order?.address?.state}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Postal Code:</p>
              <p className="ml-2">{order?.address?.postalCode}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Country:</p>
              <p className="ml-2">{order?.address?.country?.name}</p>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-around  mt-4 ">
          <div className="w-[48%] p-4 border border-slate-200 rounded-sm">
            <p className="font-semibold text-slate-400 text-lg">
              Order Summary
            </p>
            <div className="flex items-center mt-2">
              <p className="font-semibold">Status:</p>
              <p className="ml-2">{orderStatus[order?.status]}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Order Total Amount:</p>
              <p className="ml-2">{`${orderCountry?.symbol}${order?.totalAmount}`}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Order Received At:</p>
              <p className="ml-2">
                {order?.created_at
                  ? moment(order?.created_at).format("DD-MM-YYYY")
                  : null}
              </p>
            </div>
            {order?.coupon && (
              <div className="flex mt-1">
                <p className="font-semibold">Coupon Used:</p>
                <p className="ml-2">
                  {order?.coupon?.code}

                  <span className="block">{order?.coupon?.description}</span>
                </p>
              </div>
            )}
          </div>

          <div className="w-[48%] p-4 border border-slate-200 rounded-sm">
            <p className="font-semibold text-slate-400 text-lg">
              Payment Summary
            </p>
            <div className="flex items-center mt-2">
              <p className="font-semibold">Payment Total Amount:</p>
              <p className="ml-2">{`${orderCountry?.symbol}${order?.payment?.amount}`}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Currency Code:</p>
              <p className="ml-2">{order?.payment?.currency}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Status:</p>
              <p className="ml-2">{paymentStatus[order?.payment?.status]}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">Payment Method:</p>
              <p className="ml-2">
                {paymentMethods[order?.payment?.paymentMethod]}
              </p>
            </div>
            <div className="flex items-center mt-1">
              <p className="font-semibold">International Payment:</p>
              <p className="ml-2">
                {order?.payment?.international ? "Yes" : "No"}
              </p>
            </div>
          </div>
        </div>

        <table className="w-[98%] mt-8 border border-slate-200 mx-[1%]">
          <thead className="border-b border-slate-200">
            <tr className="text-sm text-slate-500">
              <th className="text-left px-4 py-4">Product Details</th>
              <th className="text-left">Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {order?.products?.map((product) => (
              <tr className="border-b border-slate-200">
                <td className="flex px-4 py-2 items-center">
                  <div className="w-12 mt-2 h-14">
                    <img
                      className="object-contain w-[100%] h-[100%]"
                      src={`${baseURL}${product.files?.[0]?.url}`}
                      alt="product"
                    />
                  </div>
                  <p className="text-left ml-4 w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
                    {product.title}
                  </p>
                </td>
                <td>{product.mrp}</td>
                <td>
                  <button
                    onClick={() => onSelectProduct(product)}
                    className="border-none text-primary font-semibold"
                  >
                    Show Measurements
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderDetails;
