import React from "react";
import Sleek_studio from "../../../assets/about/philosphy.jpg";
import Leaf_about from "../../../assets/about/leaf-about.png";

const Philosophy = () => {
  return (
    <div className="blade-padding contain">
      <h1 className="h1 font-lora text-primary text-center leading-none  md:-mb-10 mb-5 z-50">
        Sleek Studio
      </h1>
      <div className="flex md:flex-row flex-col-reverse max-w-screen-lg mx-auto relative -z-10">
        <div className="mt-auto  md:w-[40%] md:pr-10  md:py-10 py-5">
          <h6 className="h6 font-manrope text-primary leading-normal">
            With decades at the office, our designs are driven by the philosophy
            that amalgamates modern embellishments with Indian narrative,
            catering to contemporary off-the-rack shoppers around the world.
          </h6>
        </div>
        <div className="relative">
          <img className="w-[100%]" src={Sleek_studio} alt="" />
          <img
            className="absolute md:w-52 w-32 md:-bottom-[8%] bottom-5 md:-right-[8%] right-5 sm:block hidden"
            src={Leaf_about}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Philosophy;
