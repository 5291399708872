import React, { useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";

const navLinks = [
  { name: "Profile", path: "profile", id: 1 },
  { name: "Admins", path: "admins", id: 2 },
];

const Settings = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="w-full h-full px-[20px] py-[8px] flex">
      <ConfirmationPopup
        title="Are you sure you want to log out"
        show={showPopup}
        onClose={() => setShowPopup(false)}
      />
      <div className="w-[20%] h-[500px] bg-white rounded-sm py-6 mr-[2%] border border-slate-100">
        <ul className="list-none ">
          {navLinks.map((link) => (
            <NavLink to={link.path} key={link.id}>
              {({ isActive }) => (
                <li
                  className={`mt-12 text-left px-12 text-lg ${
                    isActive &&
                    "bg-white  mx-6 mt-8 text-primary-dark font-bold px-12"
                  }`}
                >
                  {link.name}
                </li>
              )}
            </NavLink>
          ))}
        </ul>
        <button
          className="bg-white text-error mt-12 border-none cursor-pointer ml-12"
          onClick={() => setShowPopup(true)}
        >
          Log out
        </button>
      </div>
      <div className="w-[78%] flex flex-col h-full overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
