import React from "react";
import { useEffect } from "react";
import InfiniteCarousel from "../../modules/core/infiniteCarousel";
import Banner from "./banner";
import Slider from "./slider";
import Family from "./family";
import Handcraft from "./handcraft";
import SleekWorld from "./sleekWorld";
// import World from "./world";

export default function HomeScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <Banner />
      <Handcraft />
      <Slider />
      {/* <SleekWorld /> */}
      {/* <World /> */}
      <Family />
      <InfiniteCarousel title="GLIMPSES FROM SOCIAL MEDIA" />
    </section>
  );
}
