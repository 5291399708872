import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fashion } from "../../modules/core";
import { fetchUser, register } from "../../redux/features/users/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { verifyOTP, generateOTP, verifyEmail } from "../../api/User";
import OTPPopup from "../../modules/overlays/otpPopup";
import { clearError, setError } from "../../redux/features/users/userSlice";
import "./style.css";
import { useCookies } from "react-cookie";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";

const Signup = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "customer",
  });
  const [passwordType, setPasswordType] = useState("password");
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    error,
    user: loggedInUser,
    isFetching,
  } = useSelector((state) => state.auth);
  const location = useLocation();
  const [cookies, setCookie] = useCookies();

  const handleChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    if (error) {
      dispatch(clearError({ [e.target.name]: null }));
    }

    setUser({
      ...user,
      [name]: val,
    });
  };

  const validate = (e) => {
    e.preventDefault();
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (user.email.match(emailRegex)) {
      handleSubmit();
    } else {
      dispatch(
        setError(
          error
            ? {
                ...error,
                email: ["The email field must be a valid email address"],
              }
            : { email: ["The email field must be a valid email address"] }
        )
      );
    }
  };

  const handleSubmit = () => {
    dispatch(
      register({
        user: user,
        onSuccess: (data) => {
          setCookie("sleek_studio_user_id", data.user.id, {
            maxAge: 2678400,
            path: "/",
          });
          setCookie("sleek_studio_user_token", data.token, {
            maxAge: 2678400,
            path: "/",
          });
          setCookie("sleek_studio_user_token_id", data.tokenId, {
            maxAge: 2678400,
            path: "/",
          });
          setOpenPopup(true);
        },
      })
    );
  };

  const onVerifyOTP = async (otp) => {
    await verifyOTP({ email: user.email, type: "verifyEmail", otp: otp })
      .then(async (res) => {
        if (res.status && res.data) {
          await verifyEmail({ email: user.email }).then((emailVerifyRes) => {
            if (emailVerifyRes.data && emailVerifyRes.status) {
              dispatch(
                fetchUser({ userId: loggedInUser.id, onSuccess: () => null })
              );
              navigate("/");
            }
          });
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onSendOTP = async () => {
    await generateOTP({
      email: user.email,
      type: "verifyEmail",
      role: "customer",
    });
  };

  const onClosePopup = () => {
    dispatch(clearError(null));
    setOpenPopup(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearError(null));
  }, []);

  useEffect(() => {
    if (
      loggedInUser?.email_verified_at &&
      cookies["sleek_studio_user_id"] &&
      cookies["sleek_studio_user_token_id"] &&
      cookies["sleek_studio_user_token"] &&
      location.pathname === "/signup"
    ) {
      navigate("/");
    }
  }, []);

  return (
    <div className="grid place-items-center h-screen">
      <OTPPopup
        data={user.email}
        onClose={onClosePopup}
        show={openPopup}
        onVerify={onVerifyOTP}
        onResend={onSendOTP}
      />
      <section className="md:px-10 px-5 max-w-screen-2xl w-full mx-auto">
        <div className="login-wrapper w-full md:min-h-[45rem] relative md:h-screen h-fit flex lg:flex-row flex-col justify-center">
          <div className="lg:w-[68%] w-full">
            <Fashion />
          </div>
          <div className="lg:w-[38%] w-full max-w-screen-sm mx-auto md:p-10 lg:mt-0 mt-12 pb-20">
            <form onSubmit={validate}>
              <input
                value={user.name}
                onChange={handleChange}
                name="name"
                type="text"
                placeholder="Name"
                className="input-style mt-6"
              />
              {error && error.name && (
                <p className="error-text">{error.name[0]}</p>
              )}
              <input
                value={user.email}
                onChange={handleChange}
                name="email"
                placeholder="Email"
                className="input-style mt-6"
              />
              {error && error?.email && (
                <p className="error-text">{error.email[0]}</p>
              )}
              <div className="relative">
                <input
                  value={user.password}
                  onChange={handleChange}
                  name="password"
                  type={passwordType}
                  placeholder="Password"
                  className="input-style mt-6 pr-10"
                />
                <button
                  onClick={() => {
                    passwordType === "password"
                      ? setPasswordType("text")
                      : setPasswordType("password");
                  }}
                  type="button"
                  className="absolute p-2 bottom-0 right-0 md:h-12 h-8 rounded focus:outline-primary"
                >
                  <span
                    className={`${
                      passwordType === "password" ? "block" : "hidden"
                    } h-5 md:h-8 w-[2.4px] inset-0 m-auto bg-primary absolute rotate-45 `}
                  ></span>
                  <svg
                    className="h-4 md:h-6 fill-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                  </svg>
                </button>
              </div>
              {error && error?.password && (
                <p className="error-text">{error.password[0]}</p>
              )}
              {error && error?.message && (
                <p className="error-text">{error.message}</p>
              )}
              <button
                type="submit"
                className="btn-secondary-fill focus:outline-primary w-full md:py-3 py-2 rounded mt-8 text-lg md:text-xl"
              >
                {isFetching ? (
                  <div className="flex items-center justify-center">
                    <LoadingIndicator
                      indicatorStyle={{
                        width: "30px",
                        height: "30px",
                        borderTop: "5px solid #dab188",
                      }}
                    />
                  </div>
                ) : (
                  "Signup"
                )}
              </button>
            </form>

            <div className="flex justify-end mt-6 ">
              <p className="text-[18px] text-primary font-manrope">
                Already have an account?
              </p>
              <Link
                to="/login"
                className="text-[18px] text-secondary px-1 font-manrope"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
