import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { BsInstagram } from "react-icons/bs";
import leaf from "../../../assets/home/icons/leaf.svg";
import instagram_posts_01 from "../../../assets/instagram/instagram_posts_01.jpg";
import instagram_posts_02 from "../../../assets/instagram/instagram_posts_02.jpg";
import instagram_posts_03 from "../../../assets/instagram/instagram_posts_03.jpg";
import instagram_posts_04 from "../../../assets/instagram/instagram_posts_04.jpg";
import instagram_posts_05 from "../../../assets/instagram/instagram_posts_05.jpg";
import instagram_posts_06 from "../../../assets/instagram/instagram_posts_06.jpg";

const InfiniteCarousel = ({ title }) => {
  const slide = [
    {
      imgSrc: instagram_posts_01,
      alt: "first Slide",
      id: 1,
    },
    {
      imgSrc: instagram_posts_02,
      alt: "Second Slide",
      id: 2,
    },
    {
      imgSrc: instagram_posts_03,
      alt: "Third Slide",
      id: 3,
    },
    {
      imgSrc: instagram_posts_04,
      alt: "Fourth Slide",
      id: 4,
    },
    {
      imgSrc: instagram_posts_05,
      alt: "Fourth Slide",
      id: 5,
    },
    {
      imgSrc: instagram_posts_06,
      alt: "Fourth Slide",
      id: 6,
    },
  ];
  return (
    <>
      <section className="blade-padding">
        <h2 className="text-primary h4 container font-lora uppercase flex gap-4 ">
          {title} <img className="w-[5%]" src={leaf} alt="" />
        </h2>

        <div className="infiniteCarousel">
          <Swiper
            modules={[Navigation, Autoplay]}
            autoplay
            loop
            centeredSlides
            breakpoints={{
              1540: {
                slidesPerView: 4,
              },
              1440: {
                slidesPerView: 4,
              },
              1080: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
            }}
          >
            {slide.map((item, ind) => {
              return (
                <SwiperSlide>
                  <div
                    className="infinte-carousel-slide 2xl:h-[25rem] md:h-[20rem] h-[18rem] p-5 border-2 border-secondary m-5"
                    key={ind}
                  >
                    <img
                      className="w-full h-full object-cover object-top"
                      src={item.imgSrc}
                      alt={item.alt}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="flex justify-center py-10">
          <a
            target="_blank"
            className="btn-secondary flex items-center mx-auto px-8 py-1 text-xl"
            href="https://www.instagram.com/sleekstudioofficial"
          >
            Follow us on
            <BsInstagram className="ml-2" />
          </a>
        </div>
      </section>
    </>
  );
};

export default InfiniteCarousel;
