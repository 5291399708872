import React from "react";
import { useState, useEffect } from "react";
import OTPPopup from "../../../modules/overlays/otpPopup";
import { verifyOTP, resetEmailGenerateOTP } from "../../../api/User";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../../redux/features/users/UserActions";
import { clearError, setError } from "../../../redux/features/users/userSlice";
import { toast } from "react-toastify";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";

const ResetEmail = () => {
  const [email, setEmail] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const { user, error, isFetching } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onVerify = async (otp) => {
    await verifyOTP({ email: user.email, type: "verifyEmail", otp: otp })
      .then((res) => {
        if (res.status && res.data) {
          dispatch(
            updateUser({
              user: { email: email, userId: user.id },
              onSuccess: () => {
                setOpenPopup(false);
                setEmail("");
                toast.success("Email has been updated successfully");
              },
            })
          );
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const validate = () => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email.match(emailRegex)) {
      onSendOTP();
    } else {
      dispatch(
        setError(
          error
            ? {
                ...error,
                newEmail: ["The email field must be a valid email address"],
              }
            : { newEmail: ["The email field must be a valid email address"] }
        )
      );
    }
  };

  const onSendOTP = async () => {
    await resetEmailGenerateOTP({ userId: user.id, newEmail: email })
      .then((res) => {
        if (res.status && res.data) {
          setOpenPopup(true);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onClosePopup = () => {
    dispatch(clearError(null));
    setOpenPopup(false);
  };

  const onChangeEmail = (e) => {
    dispatch(clearError(null));
    setEmail(e.target.value);
  };

  useEffect(() => {
    return () => {
      dispatch(clearError(null));
    };
  }, []);

  return (
    <>
      <OTPPopup
        show={openPopup}
        data={email}
        onClose={onClosePopup}
        onVerify={onVerify}
        onResend={onSendOTP}
      />
      <section>
        <div className="flex md:my-12 my-4 flex-wrap gap-2 justify-between">
          <h6 className="h5 font-semibold text-primary uppercase">
            Reset Email
          </h6>
        </div>
        <hr className="md:mt-10 mt-2 hr-line" />
        <p className="md:mt-12 mt-4">Enter New Email</p>
        <input
          type="text"
          placeholder="Email"
          className="py-3 mt-4 block max-w-[600px] px-4 outline-[#476F66] input-secondary "
          value={email}
          onChange={onChangeEmail}
          name="email"
        />
        {error && error?.newEmail && (
          <p className="error-text">{error?.newEmail[0]}</p>
        )}
        <button
          onClick={validate}
          className="mt-6 btn-secondary md:w-[300px] w-full rounded-md"
        >
          {isFetching ? (
            <div className="flex items-center justify-center">
              <LoadingIndicator
                indicatorStyle={{
                  width: "30px",
                  height: "30px",
                  borderTop: "5px solid #dab188",
                }}
              />
            </div>
          ) : (
            "Verify Email"
          )}
        </button>
      </section>
    </>
  );
};

export default ResetEmail;
