import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "../../modules/atoms";
import "./error.css";

export default function Error404Screen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="errorScreenWrapper">
      <h1 className="h1 p-2 max-w-screen-md text-center leading-tight text-primary font-manrope">
        404
      </h1>
      <h1 className="h6 p-2 max-w-screen-md text-center leading-tight text-primary">
        The page you have requested is not exist or may have been removed.
      </h1>
      <NavLink
        to="/"
        className="p-2 rounded transition-all duration-300 border-primary hover:text-primary mt-4 px-5 font-manrope font-bold bg-primary hover:bg-white text-white border-2"
      >
        Go to Homepage
      </NavLink>
    </div>
  );
}
