import React, { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  matchPath,
  useParams,
} from "react-router-dom";
import {
  HomeScreen,
  AboutScreen,
  ComingSoonScreen,
  Error404Screen,
  ContactScreen,
  WishlistScreen,
  LoginScreen,
  MyAccount,
  Support,
  Checkout,
  CheckoutConfirmation,
  // Product,
  SignupScreen,
  MenProductCollections,
  WomenProductCollections,
  PrivacyPolicy,
  CartlistScreen,
} from "../screens";
import { Header, Footer } from "../modules/core";
import Navbar from "../modules/admin/navbar";
import Test from "../redux/test";
import OrderDetail from "../screens/myAccount/order";
import Address from "../screens/myAccount/address";
import PersonalDetail from "../screens/myAccount/personal";
import PassRecovery from "../screens/passRecovery";
import ProductDetail from "../screens/productDetail";
import ChangePassword from "../screens/myAccount/changePassword";
import ResetEmail from "../screens/myAccount/resetEmail";
import {
  ProductList,
  CategoryList,
  SubcategoryList,
  AddProduct,
  EditProduct,
  UserList,
  UserProfile,
  OrderList,
  CouponList,
  AddCoupon,
  OrderDetails as AdminOrderDetails,
  ProductDetails as AdminProductDetails,
  Settings as AdminSettings,
  Profile,
  ResetPassword as AdminResetPassword,
  ResetEmail as AdminResetEmail,
  Login as AdminLogin,
  ContactMails,
} from "../screens/admin";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

const ViewWithNavAndFooter = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [cookies] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (user?.email_verified_at == null ||
        (!cookies["sleek_studio_user_id"] &&
          !cookies["sleek_studio_user_token_id"] &&
          !cookies["sleek_studio_user_token"])) &&
      (location.pathname === "/checkout/confirmation" ||
        location.pathname === "/checkout")
    ) {
      navigate("/signup");
    }
  }, [cookies, user]);

  return (
    <>
      <Header />
      <div className="2xl:mt-20 xl:mt-[70px] mt-20">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
const ViewWithNav = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { pathname } = location;
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const match = matchPath(
    {
      path: "/profile/:id",
    },
    pathname
  );

  useEffect(() => {
    if (
      ((user && user?.email_verified_at == null) ||
        (!cookies["sleek_studio_user_id"] &&
          !cookies["sleek_studio_user_token_id"] &&
          !cookies["sleek_studio_user_token"])) &&
      (location.pathname === "/profile" || match !== null)
    ) {
      navigate("/signup");
    }
  }, [cookies, user]);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

const AdminMainLayout = () => {
  const [cookies] = useCookies();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !cookies["sleek_studio_admin"] &&
      !cookies["sleek_studio_admin_token"] &&
      !cookies["sleek_studio_admin_token_id"]
    ) {
      navigate("/admin/login");
    } else {
      if (
        location.pathname === "/admin" ||
        location.pathname === "/admin/login"
      ) {
        navigate("/admin/products");
      }
    }
  }, [cookies]);

  return (
    <div className="w-[100vw] bg-secondaryTwoLight md:flex h-[100vh]">
      <Navbar />
      <div className="w-[100vw] md:w-[80%] overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

const routes = () => {
  return [
    {
      path: "/",
      element: <ViewWithNavAndFooter />,
      children: [
        // Public routes
        { path: "", element: <HomeScreen /> },
        { path: "about", element: <AboutScreen /> },
        { path: "coming-soon", element: <ComingSoonScreen /> },
        { path: "contact", element: <ContactScreen /> },
        { path: "support", element: <Support /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        { path: "forgot-password", element: <PassRecovery /> },
        // { path: "products", element: <Product /> },
        {
          path: "collections/men",
          element: <MenProductCollections />,
        },
        { path: "collections/women", element: <WomenProductCollections /> },
        {
          path: "collections/:type/product/:productId",
          element: <ProductDetail />,
        },
        { path: "product/:productId", element: <ProductDetail /> },
      ],
    },
    //Protected routes
    {
      path: "/",
      element: <ViewWithNav />,
      children: [
        {
          path: "profile",
          element: <MyAccount />,
          children: [
            {
              path: "",
              element: <PersonalDetail />,
            },
            {
              path: "address",
              element: <Address />,
            },
            {
              path: "orders",
              element: <OrderDetail />,
            },
            {
              path: "change-password",
              element: <ChangePassword />,
            },
            {
              path: "reset-email",
              element: <ResetEmail />,
            },
          ],
        },
        { path: "wishlist", element: <WishlistScreen /> },
        { path: "cart", element: <CartlistScreen /> },
        { path: "checkout", element: <Checkout /> },
        { path: "checkout/confirmation", element: <CheckoutConfirmation /> },
      ],
    },
    {
      path: "/admin",
      element: <AdminMainLayout />,
      children: [
        { path: "products", element: <ProductList /> },
        {
          path: "categories",
          element: <CategoryList />,
        },
        {
          path: "categories/:categoryId/subcategories",
          element: <SubcategoryList />,
        },
        {
          path: "products/add",
          element: <AddProduct />,
        },
        {
          path: "products/:productId/update",
          element: <EditProduct />,
        },
        { path: "users", element: <UserList /> },
        { path: "users/:userId", element: <UserProfile /> },
        { path: "orders", element: <OrderList /> },
        { path: "coupons", element: <CouponList /> },
        { path: "contact-mails", element: <ContactMails /> },
        { path: "coupons/add", element: <AddCoupon /> },
        { path: "order/:orderId/details", element: <AdminOrderDetails /> },
        {
          path: "products/:productId/details",
          element: <AdminProductDetails />,
        },
        {
          path: "settings",
          element: <AdminSettings />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        { path: "password-reset", element: <AdminResetPassword /> },
        { path: "email-reset", element: <AdminResetEmail /> },
      ],
    },
    { path: "/admin/login", element: <AdminLogin /> },
    { path: "/login", element: <LoginScreen /> },
    { path: "/signup", element: <SignupScreen /> },
    {
      path: "*",
      element: <Error404Screen />,
    },
  ];
};

export default routes;
