import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const register = (body) => api.post("register", body);

export const login = (body) => api.post("login", body);

export const logout = (body) => api.post("logout", body);

export const update = (body) => api.put("user/update", body);

export const getProfile = (body) => api.post("user/profile", body);

export const generateOTP = (body) => api.post("generate-otp", body);

export const verifyOTP = (body) => api.post("verify-otp", body);

export const verifyEmail = (body) => api.post("verify-email", body);

export const verifyPhone = (body) => api.post("verify-phone", body);

export const resetEmailGenerateOTP = (body) =>
  api.post("generate-otp/reset-email", body);

export const resetPasswordGenerateOTP = (body) =>
  api.post("generate-otp/reset-password", body);

export const changePassword = (body) => api.post("change-password", body);

export const loginWithOTP = (body) => api.post("login-with-otp", body);

export const getAllUsers = (params) => api.get("users", params, "admin");
