import { createSlice } from "@reduxjs/toolkit";
import { fetchSubcategory } from "../category/CategoryActions";
import {
  createProduct,
  updateProduct,
  removeProduct,
  fetchByCategory,
  fetchBySubcategory,
  removeProductImage,
  searchProduct,
  fetchAll,
} from "./productActions";

const initialState = {
  products: null,
  menProducts: null,
  womenProducts: null,
  error: null,
  isFetching: false,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearError: (state, { payload }) => {
      if (state.error?.message || !payload) {
        state.error = null;
      } else {
        state.error = { ...state.error, ...payload };
      }
    },
  },
  extraReducers: {
    //create product reducers
    [createProduct.pending]: (state) => {
      state.error = null;
    },
    [createProduct.fulfilled]: (state, { payload }) => {
      if (state.products) {
        state.products.data.push(payload);
      } else {
        state.products = { data: [payload] };
      }
    },
    [createProduct.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    //remove image reducers
    [removeProductImage.pending]: (state) => {
      state.error = null;
    },
    [removeProductImage.fulfilled]: (state, { payload }) => {
      const index = state.products.data.findIndex(
        (product) => product.id === payload.productId
      );
      state.products.data[index].files = state.products.data[
        index
      ].files.filter((file) => file.id !== payload.imageId);
    },
    [removeProductImage.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    //update product reducers
    [updateProduct.pending]: (state) => {
      state.error = true;
    },
    [updateProduct.fulfilled]: (state, { payload }) => {
      const index = state.products.data.findIndex(
        (product) => product.id === payload.id
      );
      state.products.data[index] = {
        ...payload,
        files: payload?.files
          ? [...state.products.data[index].files, ...payload.files]
          : state.products.data[index].files,
      };
    },
    [updateProduct.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    //remove product reducers
    [removeProduct.pending]: (state) => {
      state.error = null;
    },
    [removeProduct.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    //products by category reducers
    [fetchByCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchByCategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.products = payload.products;
      if (payload.type && payload.type === "men") {
        state.menProducts = payload.products;
      }
      if (payload.type && payload.type === "women") {
        state.womenProducts = payload.products;
      }
    },
    [fetchByCategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
    },

    //products by subcategory reducers
    [fetchBySubcategory.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchBySubcategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.products = payload.products;
      if (payload.type && payload.type === "men") {
        state.menProducts = payload.products;
      }
      if (payload.type && payload.type === "women") {
        state.womenProducts = payload.products;
      }
    },
    [fetchSubcategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
    },

    [searchProduct.pending]: (state) => {
      state.isFetching = true;
    },
    [searchProduct.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.products = payload.products;
    },
    [searchProduct.rejected]: (state) => {
      state.isFetching = false;
    },

    [fetchAll.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchAll.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.products = payload.products;
    },
    [fetchAll.rejected]: (state, { payload }) => {
      state.isFetching = false;
    },
  },
});

export const { clearError } = productSlice.actions;
