import React from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../helpers";

export default function Itemstable() {
  const { cartProducts } = useSelector((state) => state.cart);
  const { selectedCountry } = useSelector((state) => state.country);

  const ProductRow = ({ product }) => {
    return (
      <div className="flex flex-row flex-nowrap gap-3 items-start p-2 py-5">
        <div className="basis-36 grow-0 shrink min-w-[100px]">
          <img
            src={`${baseURL}${product?.files[0]?.url}`}
            alt="man in suit"
            height="1"
            width="1"
            className="h-44 w-full object-cover"
          />
        </div>
        <div className="font-franklin py-1">
          <h6 className="font-bold">{product.title}</h6>
          <p className="mt-1 text-slate-400">{product.subTitle}</p>
          <p className="font-bold mt-2 text-primary">{`${
            selectedCountry.symbol
          } ${parseInt(product.mrp)}`}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="contain">
      {cartProducts &&
        cartProducts?.length &&
        cartProducts.map((product) => <ProductRow product={product} />)}
    </div>
  );
}
