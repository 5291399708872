import React from "react";
import DatePicker from "react-datepicker";
import "./dateInput.css";

const DateInput = ({
  startDate,
  endDate,
  onSetStartDate,
  onSetEndDate,
  containerStyle,
  onFilter,
}) => {
  return (
    <div className={`flex items-center  justify-center ${containerStyle}`}>
      <span className="flex items-center relative">
        <label className="mr-[8px] text-slate-400">From</label>

        <DatePicker
          onChange={onSetStartDate}
          selected={startDate !== "" ? startDate : new Date()}
          dateFormat="dd-MM-yyyy"
          calendarClassName="date-input-picker"
          placeholderText="dd/mm/yyyy"
          className="border focus:outline-none px-2 w-[120px] border-solid border-slate-300 rounded-sm py-[5px]"
        />
      </span>

      <span className="flex items-center relative">
        <label className="mr-[8px] ml-2 text-slate-400">To</label>
        <DatePicker
          selected={endDate !== "" ? endDate : new Date()}
          onChange={onSetEndDate}
          placeholderText="dd/mm/yyyy"
          dateFormat="dd-MM-yyyy"
          className="border px-2 w-[120px] border-solid border-slate-300 rounded-sm py-[5px]"
        />
      </span>
      <button
        onClick={() => onFilter()}
        className="bg-primary text-white px-4 py-[6px] rounded-sm ml-2"
      >
        Filter
      </button>
    </div>
  );
};

export default DateInput;
