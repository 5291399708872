import React, { useState, useEffect } from "react";
import Search from "../../../modules/atoms/search";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllUsers } from "../../../redux/features/users/UserActions";
import Dropdown from "../../../modules/atoms/dropdown";
import Pagination from "../../../modules/atoms/pagination";
import moment from "moment/moment";
import DateInput from "../../../modules/atoms/dateInput";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";

const UserList = () => {
  const { users, isFetching } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [page, setPage] = useState(1);

  const getUsers = (pageNumber = 1, params = {}) => {
    if (Object.keys(params).length > 0) {
      setIsAllSelected(false);
    }
    dispatch(fetchAllUsers({ ...params, page: pageNumber }));
  };

  useEffect(() => {
    getUsers(1);
  }, []);

  const onSelectAll = () => {
    setPage(1);
    if (!isAllSelected) {
      setSearch("");
      setStartDate("");
      setEndDate("");
      setIsAllSelected(true);
      getUsers(1);
    }
  };

  const onFilter = () => {
    setPage(1);
    const params = {};
    startDate !== "" &&
      Object.assign(params, {
        startDate: moment(startDate).format("YYYY-MM-DD"),
      });
    endDate !== "" &&
      Object.assign(params, { endDate: moment(endDate).format("YYYY-MM-DD") });
    getUsers(1, params);
  };

  const onChangePage = (pageNumber) => {
    setPage(pageNumber);
    if (isAllSelected) {
      getUsers(pageNumber, {});
    } else if (search) {
      getUsers(pageNumber, { search });
    } else if (startDate && endDate) {
      getUsers(pageNumber, {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      });
    } else {
      getUsers(pageNumber, {
        startDate: moment(startDate).format("YYYY-MM-DD"),
      });
    }
  };

  const onSearch = () => {
    setPage(1);
    getUsers(1, { search: search.split(" ").join("_") });
  };

  return (
    <div className="w-full h-full px-12">
      <h2 className="text-xl font-bold mt-12">Users</h2>
      <div className="mt-6 bg-white border border-solid rounded-md border-slate-200">
        <div className="flex px-4 py-8 border-b border-slate-200 items-center">
          <button
            onClick={onSelectAll}
            className={`${
              isAllSelected ? "border-primary text-primary" : "border-slate-200"
            } py-[6px] rounded-md px-4 border`}
          >
            All
          </button>
          <Search
            value={search}
            containerStyle="ml-4"
            onChange={(e) => setSearch(e.target.value)}
            onSearch={onSearch}
          />
          <DateInput
            startDate={startDate}
            endDate={endDate}
            containerStyle="ml-4"
            onSetStartDate={setStartDate}
            onSetEndDate={setEndDate}
            onFilter={onFilter}
          />
        </div>
        {!isFetching && users?.data?.length === 0 ? (
          <div className="flex p-4 items-center justify-center">
            <h2 className="text-xl">No users found</h2>
          </div>
        ) : isFetching ? (
          <div className="flex justify-center my-12">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <table className="w-full">
              <thead>
                <tr className="text-black text-sm">
                  <th className="text-left p-4">First Name</th>
                  <th className="text-left py-4">Last Name</th>
                  <th className="text-left py-4">Email</th>
                  <th className="text-left py-4">Phone Number</th>
                  <th className="text-left py-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users?.data?.map((user) => (
                  <tr
                    key={user.id}
                    className="border-t border-solid border-b-slate-200"
                  >
                    <td className="px-4 py-3">{user.name}</td>
                    <td>{user.lastName ?? "-"}</td>
                    <td>{user.email}</td>
                    <td>{user.phoneNumber ?? "-"}</td>

                    <td>
                      <Link
                        className="py-1 text-primary font-semibold"
                        to={`${user.id}`}
                      >
                        Profile
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <Pagination
          totalData={users?.total}
          onChangePage={onChangePage}
          pageNumber={page}
          pageData={10}
        />
      </div>
    </div>
  );
};

export default UserList;
