export const modifiedPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const numberArray = phoneNumber.split("");
    if (numberArray[0] === "1") {
      return phoneNumber.slice(1);
    }
    return phoneNumber.slice(2);
  }
  return phoneNumber;
};
export const baseURL = "https://api.thesleekstudio.com/";
