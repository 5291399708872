import React, { useState, useEffect } from "react";
import {
  verifyOTP,
  resetPasswordGenerateOTP,
  changePassword,
} from "../../../api/User";
import OTPPopup from "../../../modules/overlays/otpPopup";
import { useSelector, useDispatch } from "react-redux";
import { setError, clearError } from "../../../redux/features/users/userSlice";
import { toast } from "react-toastify";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";

const ChangePassword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, []);

  const onVerify = async (otp) => {
    await verifyOTP({ email: email, type: "passwordReset", otp: otp })
      .then((res) => {
        if (res.status && res.data) {
          setIsVerified(true);
          setOpenPopup(false);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onSendOTP = async () => {
    setLoading(true);
    await resetPasswordGenerateOTP({ email: email, userId: user?.id })
      .then((res) => {
        if (res.status && res.data) {
          setOpenPopup(true);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const onChangePassword = async () => {
    setLoading(true);
    await changePassword({
      userId: user.id,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    })
      .then((res) => {
        if (res.status && res.data) {
          setNewPassword("");
          setConfirmPassword("");
          setEmail("");
          toast.success("Password has been updated successfully");
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    dispatch(clearError({ [e.target.name]: null }));
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "newPassword":
        setNewPassword(e.target.value);
        break;
      case "confirmPassword":
        setConfirmPassword(e.target.value);
        break;
      default:
        return;
    }
  };

  const onClosePopup = () => {
    dispatch(setError(null));
    setOpenPopup(false);
  };

  const onCancel = () => {
    setNewPassword("");
    setConfirmPassword("");
    setIsVerified(false);
  };

  return (
    <>
      <OTPPopup
        show={openPopup}
        data={email}
        onClose={onClosePopup}
        onVerify={onVerify}
        onResend={onSendOTP}
      />
      <section>
        <div className="flex my-12 flex-wrap gap-2  justify-between">
          <h6 className="h5 font-semibold text-primary uppercase">
            Change Password
          </h6>
        </div>
        <hr className="md:mt-10 hr-line mt-2" />
        {isVerified ? (
          <div>
            <input
              placeholder="new password"
              className="py-3 mt-8 block max-w-[600px] px-4 outline-[#476F66] input-secondary "
              value={newPassword}
              onChange={handleChange}
              type="password"
              name="newPassword"
            />
            {error && error?.newPassword && (
              <p className="error-text">{error?.newPassword}</p>
            )}
            <input
              placeholder="confirm password"
              className="py-3 mt-6 block max-w-[600px] px-4 outline-[#476F66] input-secondary "
              value={confirmPassword}
              onChange={handleChange}
              type="password"
              name="confirmPassword"
            />
            {error && error?.confirmPassword && (
              <p className="error-text">{error.confirmPassword}</p>
            )}
            {error && error?.message && (
              <p className="error-text">{error.message}</p>
            )}
            <div className="grid grid-cols-2 mt-8 gap-5">
              <button onClick={onCancel} className="btn-primary">
                Cancel
              </button>
              <button
                onClick={onChangePassword}
                className="btn-secondary rounded "
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <LoadingIndicator
                      indicatorStyle={{
                        width: "30px",
                        height: "30px",
                        borderTop: "5px solid #dab188",
                      }}
                    />
                  </div>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
        ) : (
          <>
            <p className="font-medium mt-12">Enter Your Email</p>
            <input
              type="text"
              placeholder="Email"
              className="py-3 mt-4 block max-w-[600px] px-4 outline-[#476F66] input-secondary "
              value={email}
              onChange={handleChange}
              name="email"
            />
            {error && error?.email && (
              <p className="error-text">{error.email[0]}</p>
            )}
            {error && error?.message && (
              <p className="error-text">{error?.message}</p>
            )}
            <div className="mt-6">
              <button
                onClick={onSendOTP}
                className="btn-secondary w-[300px] rounded-md"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <LoadingIndicator
                      indicatorStyle={{
                        width: "30px",
                        height: "30px",
                        borderTop: "5px solid #dab188",
                      }}
                    />
                  </div>
                ) : (
                  "Get OTP"
                )}
              </button>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default ChangePassword;
