export class ApiClient {
  baseUrl = "https://api.thesleekstudio.com/api/";
  // baseUrl = "http://192.168.1.75:8000/api/";

  async request(method, endpoint, data, params, type = "", entity = "user") {
    let url = `${this.baseUrl}${endpoint}`;

    Object.keys(params).map((key, index) => {
      url =
        index === 0
          ? `${url}?${key}=${params[key]}`
          : `${url}&${key}=${params[key]}`;
    });

    const token =
      entity === "user"
        ? sessionStorage.getItem("sleek_studio_user_token")
        : sessionStorage.getItem("sleek_studio_admin_token");

    const tokenObject = { Authorization: `Bearer ${token}` };
    const contentType = (type === "" || type !== "formData") && {
      "Content-Type": "application/json",
    };

    const apiConfig = {
      withCredentials: true,
      headers: {
        Accept:
          type !== "" && type === "formData"
            ? "multipart/form-data"
            : "application/json",
        ...contentType,
        ...tokenObject,
      },
    };

    const body = method !== "GET" && {
      body: type !== "" && type === "formData" ? data : JSON.stringify(data),
    };

    // console.log("Method", method);
    // console.log("url", url);
    // console.log("type", type);
    // console.log("body", body);
    // console.log("token", token);
    // console.log("apiConfig", apiConfig);
    // console.log("params", params);

    return await fetch(url, {
      ...apiConfig,
      method: method,
      ...body,
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  async post(endpoint = "", data, params = {}, type = "", entity = "user") {
    return this.request("POST", endpoint, data, params, type, entity);
  }

  async get(endpoint = "", params = {}, entity = "user") {
    return this.request("GET", endpoint, null, params, "", entity);
  }

  async put(endpoint = "", data, params = {}, entity = "user") {
    return this.request("PUT", endpoint, data, params, "", entity);
  }

  async delete(endpoint = "", data, params = {}, entity = "user") {
    return this.request("DELETE", endpoint, data, params, "", entity);
  }
}
