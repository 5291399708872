import React, { useState } from "react";

import OTPPopup from "../../../modules/overlays/otpPopup";
import {
  verifyOTP,
  resetPasswordGenerateOTP,
  changePassword,
} from "../../../api/Admin";
import { setError, clearError } from "../../../redux/features/admin/AdminSlice";
import { useSelector, useDispatch } from "react-redux";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../modules/atoms/backButton";

const ResetPassword = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const { admin, error } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onVerify = async (otp) => {
    await verifyOTP({ email: email, type: "passwordReset", otp: otp })
      .then((res) => {
        if (res.status && res.data) {
          setIsVerified(true);
          setOpenPopup(false);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onSendOTP = async () => {
    await resetPasswordGenerateOTP({ email: email, userId: admin?.id })
      .then((res) => {
        if (res.status && res.data) {
          setOpenPopup(true);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onChangePassword = async () => {
    await changePassword({
      userId: admin.id,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    })
      .then((res) => {
        if (res.status && res.data) {
          navigate("/admin/profile");
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    dispatch(clearError({ [e.target.name]: null }));
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "newPassword":
        setNewPassword(e.target.value);
        break;
      case "confirmPassword":
        setConfirmPassword(e.target.value);
        break;
      default:
        return;
    }
  };

  const onClosePopup = () => {
    dispatch(setError(null));
    setOpenPopup(false);
  };

  const onCancel = () => {
    setNewPassword("");
    setConfirmPassword("");
    setIsVerified(false);
  };

  return (
    <div className="w-full px-12 pt-12">
      <BackButton />
      <div className="w-full max-w-[1000px] mt-12 bg-white py-12  px-8 rounded-md">
        <OTPPopup
          show={openPopup}
          data={email}
          onClose={onClosePopup}
          onVerify={onVerify}
          onResend={onSendOTP}
        />
        <div>
          <h2 className="font-bold text-xl">Reset Password</h2>
          <p className="text-slate-400 mt-4">
            Please enter your email address to reset your password. You will
            receive an email shortly.
          </p>
        </div>
        <div>
          {isVerified ? (
            <div>
              <input
                placeholder="new password"
                className="py-3 mt-8 block max-w-[600px] px-4 outline-[#476F66] input-secondary "
                value={newPassword}
                onChange={handleChange}
                type="password"
                name="newPassword"
              />
              {error && error?.newPassword && (
                <p className="error-text">{error?.newPassword}</p>
              )}
              <input
                placeholder="confirm password"
                className="py-3 mt-6 block max-w-[600px] px-4 outline-[#476F66] input-secondary "
                value={confirmPassword}
                onChange={handleChange}
                type="password"
                name="confirmPassword"
              />
              {error && error?.confirmPassword && (
                <p className="error-text">{error.confirmPassword}</p>
              )}
              {error && error?.message && (
                <p className="error-text">{error?.message}</p>
              )}
              <div className="flex items-center mt-8 gap-5">
                <button
                  onClick={onCancel}
                  className=" text-error bg-white border border-error h-full py-2 px-12 rounded-sm"
                >
                  Cancel
                </button>
                <button
                  className=" bg-white text-primary border border-primary h-full py-2 px-12 rounded-sm"
                  onClick={onChangePassword}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <>
              <p className="font-medium mt-12">
                Enter Your Email To Change Password
              </p>
              <input
                type="text"
                placeholder="email"
                className="py-3 mt-4 block max-w-[600px] px-4 outline-[#476F66] input-secondary "
                value={email}
                onChange={handleChange}
                name="email"
              />
              {error && error?.email && (
                <p className="error-text">{error.email[0]}</p>
              )}

              {error && error?.message && !openPopup && (
                <p className="error-text">{error?.message}</p>
              )}
              <div className="mt-8">
                <button
                  onClick={onSendOTP}
                  className="text-primary border border-primary bg-white h-full py-2 px-12 rounded-sm"
                >
                  Get OTP
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
