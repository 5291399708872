import React from "react";
import { useMemo } from "react";
import { RxCross1 } from "react-icons/rx";

const measurementKey = {
  suitLength: "Suit Length",
  chest: "Chest",
  shoulder: "Shoulder",
  belly: "Belly",
  suitHips: "Suit Hips",
  waist: "Waist",
  pantLength: "Pant Length",
  crossBack: "Cross Back",
  armLength: "Arm Length",
  insideLeg: "Inside Leg",
  pantHips: "Pant Hips",
  bottom: "Bottom",
  thighs: "Thighs",
};

const MeasurementPopup = ({ show, onClose, product }) => {
  const measurements = useMemo(() => {
    if (product?.pivot) {
      const { orderId, productId, id, ...newMeasurements } = product.pivot;
      return newMeasurements;
    } else {
      return {};
    }
  }, [product]);

  return (
    <>
      {show && (
        <div className="h-full fixed top-0 left-0 z-[9999] w-full bg-[#00000094] flex items-center justify-center">
          <div className="w-[40%] overflow-auto h-[300px] py-12 relative bg-white mr-[2%] px-6 rounded-md border border-solid border-slate-200">
            <span className="cursor-pointer" onClick={() => onClose()}>
              <RxCross1 className="absolute right-4 top-4" size={16} />
            </span>
            <h2 className="text-center text-slate-500 font-semibold">
              Product
            </h2>
            <h2 className="text-xl font-medium text-center mt-2">
              {product?.title}
            </h2>
            <h2 className="font-semibold text-center text-slate-500 mt-8">
              Measurements
            </h2>
            <div className="mt-2">
              <table className="w-full border border-slate-200">
                <tbody>
                  {Object.keys(measurements).map((key) => (
                    <tr className="border-b border-solid border-b-slate-200">
                      <td className="p-2">{measurementKey[key]}</td>
                      <td>{`${measurements[key]} inchs`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MeasurementPopup;
