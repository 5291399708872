import React from "react";
import "./banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import banner_01 from "../../../assets/home/banner_01.jpg";
import banner_02 from "../../../assets/home/banner_02.jpg";
import banner_03 from "../../../assets/home/banner_03.jpg";
import banner_04 from "../../../assets/home/banner_04.jpg";

export default function Banner() {
  const slide = [
    {
      imgSrc: banner_01,
      alt: "first Slide",
    },
    {
      imgSrc: banner_02,
      alt: "Second Slide",
    },
    {
      imgSrc: banner_03,
      alt: "Third Slide",
    },
    {
      imgSrc: banner_04,
      alt: "Fourth Slide",
    },
  ];
  return (
    <section className=" blade-padding-bottom contain ">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        autoplay
        speed={1000}
        loop={true}
        pagination={{
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        }}
      >
        {slide.map((item, ind) => {
          return (
            <SwiperSlide>
              <div className="md:h-[60vh] h-[300px]" key={ind}>
                <img
                  className="h-full w-full object-cover object-center"
                  src={item.imgSrc}
                  alt={item.alt}
                />
              </div>
            </SwiperSlide>
          );
        })}
        <div className="swiper-pagination"></div>
      </Swiper>
    </section>
  );
}
