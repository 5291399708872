import React from "react";

const Search = ({ onChange, value, onSearch, containerStyle }) => {
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };
  return (
    <div
      className={`border w-[250px] border-solid border-slate-300 rounded-sm py-[5px] flex ${containerStyle}`}
    >
      <input
        placeholder="search"
        value={value}
        className="px-2 bg-inherit outline-[none] focus:outline-none"
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default Search;
