import React from "react";
import "./header.css";
import { IoMenu } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import MobileModal from "./mobileModal";
import sleekLogo from "../../../assets/home/icons/logo.svg";
import { Cart } from "../../../screens";
import CartIcon from "../../icons/CartFill";
import Profile from "../../icons/Profile";
import CountrySelector from "../../atoms/CountrySelector";
import { useSelector } from "react-redux";
import { AiFillHeart } from "react-icons/ai";
import { useCookies } from "react-cookie";

export default function Header() {
  const [openCart, setCartOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { cartProducts } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);
  const [cookies] = useCookies();

  const { wishlistProducts } = useSelector((state) => state.wishlist);

  const handleModal = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <header className="bg-white fixed top-0 left-0 right-0 z-[99]">
        <div className="header_wrapper contain">
          <NavLink to="/" className="">
            <div className="2xl:h-full h-16 p-1">
              <img
                className="w-full h-full object-contain"
                src={sleekLogo}
                alt="sleek logo"
              />
            </div>
          </NavLink>
          <ul className="flex items-center">
            <NavLink
              className="px-3 font-manrope font-normal text-lg"
              to="/about"
            >
              {({ isActive }) => (
                <li
                  className={`${isActive ? "text-secondary" : "text-primary"}`}
                >
                  About Us
                </li>
              )}
            </NavLink>

            <NavLink
              className="px-3 font-manrope font-normal text-lg"
              to="/collections/men"
            >
              {({ isActive }) => (
                <li
                  className={`${isActive ? "text-secondary" : "text-primary"}`}
                >
                  Men's Collection
                </li>
              )}
            </NavLink>

            <NavLink
              className="px-3 font-manrope font-normal text-lg"
              to="/collections/women"
            >
              {({ isActive }) => (
                <li
                  className={`${isActive ? "text-secondary" : "text-primary"}`}
                >
                  Women's Collection
                </li>
              )}
            </NavLink>

            {(user && user.email_verified_at == null) ||
            (!cookies["sleek_studio_user_id"] &&
              !cookies["sleek_studio_user_token"] &&
              !cookies["sleek_studio_user_token_id"]) ? (
              <NavLink
                className="px-3 font-manrope font-normal text-lg"
                to="/login"
              >
                {({ isActive }) => (
                  <li
                    className={`${
                      isActive ? "text-secondary" : "text-primary"
                    }`}
                  >
                    Login
                  </li>
                )}
              </NavLink>
            ) : (
              <>
                <NavLink
                  className="px-2 rounded-full py-2 mx-2 grid place-content-center text-lg"
                  to="/profile"
                >
                  {({ isActive }) => (
                    <li>
                      <Profile color={isActive ? "#DAB188" : "#476F66"} />
                    </li>
                  )}
                </NavLink>

                <NavLink
                  className="px-2 rounded-full py-2  mx-2 group grid place-content-center text-lg"
                  to="wishlist"
                >
                  {({ isActive }) => (
                    <li
                      className={`relative ${
                        isActive ? "text-secondary" : "text-primary"
                      }`}
                    >
                      <AiFillHeart className=" duration-200" size={25} />
                      {wishlistProducts?.data &&
                        wishlistProducts.data?.length > 0 && (
                          <span
                            className="w-[18px]
                              flex items-center justify-center font- bottom-[14px] group-hover:-translate-y-1 duration-150
                              left-[14px] h-[18px] text-white text-xs bg-primary p-2 rounded-full absolute -z-[1]"
                          >
                            {wishlistProducts.data?.length}
                          </span>
                        )}
                    </li>
                  )}
                </NavLink>

                <NavLink
                  className="px-2 rounded-full py-2  mx-2 group grid place-content-center text-lg"
                  to="cart"
                >
                  {({ isActive }) => (
                    <li
                      className={`relative ${
                        isActive
                          ? "fill-secondary text-secondary"
                          : "fill-primary"
                      }`}
                    >
                      <CartIcon
                        className={`${
                          isActive
                            ? "fill-secondary text-secondary"
                            : "fill-primary"
                        }`}
                      />
                      {cartProducts && cartProducts?.length > 0 && (
                        <span
                          className="w-[18px] 
                            flex items-center justify-center font- bottom-[14px] group-hover:-translate-y-1 duration-150
                            left-[14px] h-[18px] text-white text-xs bg-primary p-2 rounded-full absolute -z-[1]"
                        >
                          {cartProducts?.length}
                        </span>
                      )}
                    </li>
                  )}
                </NavLink>

                {/* <li>
                  <button
                    className="px-2 relative py-2 grid place-content-center text-sm"
                    onClick={() => {
                      setCartOpen(true);
                    }}
                  >
                    <CartIcon
                      className="hover:fill-secondary"
                      color={openCart && "#DAB188"}
                    />
                    {cartProducts && cartProducts?.length > 0 && (
                      <span
                        className="w-[18px] 
                      flex items-center justify-center font- bottom-[22px] 
                      left-[28px] h-[18px] text-white text-xs bg-primary p-2 rounded-full absolute -z-[1]"
                      >
                        {cartProducts?.length}
                      </span>
                    )}
                  </button>
                </li> */}
              </>
            )}
            <li>
              <CountrySelector showIcon />
            </li>

            {/* <select options={options} value={value} onChange={changeHandler} /> */}
          </ul>
          <IoMenu className="burger-icon" onClick={handleModal} />
          <MobileModal
            className={`${isOpen ? "showModal" : "hideModal"} `}
            onClick={handleModal}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            openCart={() => setCartOpen(true)}
          />
        </div>
        {/* <div className="hr_gradient"></div> */}
      </header>
      {openCart && <Cart closeCart={setCartOpen} />}
    </>
  );
}
