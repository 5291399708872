import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const create = (body) => api.post("address", body);

export const update = (body) => api.put("address/update", body);

export const remove = (body) => api.delete("address/remove", body);

export const getByUser = (body) => api.post("user/addresses", body);
