import React, { useEffect, useState } from "react";
import "./contact.css";
import contact from "../../assets/contact/contact.jpg";
import Maps from "./map/maps";
import { create } from "../../api/ContactUs";
import { toast } from "react-toastify";
import PhoneInput from "../../modules/atoms/PhoneInput";

const Contact = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [error, setError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const onChange = (e) => {
    e.preventDefault();
    if (error?.message) {
      setError(null);
    } else {
      setError({ [e.target.name]: null });
    }
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const sendMail = async (e) => {
    e.preventDefault();
    await create(contactData).then((res) => {
      if (res.status && res.data) {
        toast.success("Your request has been send successfully", {
          position: "bottom-center",
          autoClose: 3000,
        });
        setContactData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setError(res.data ?? res);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="contain">
        <h2 className="h2 font-lora text-primary my-12  mx-2 lg:mx-0">
          Contact Us
        </h2>
        <div className="text-center border md:p-28 p-4">
          <h3 className="h3 text-primary font-lora ">Connect with us</h3>
          <h6 className="h6 color-skin lg:w-[45%] w-[85%] m-auto font-manrope text-secondary mt-3">
            Reach out to us!
          </h6>
          <h6 className="h6 color-skin mt-3 lg:w-[45%] w-[85%] m-auto font-manrope text-secondary">
            We value your opinions and queries. Let's connect to talk further!
          </h6>
          <div className="flex flex-wrap gap-8 md:gap-[6%]  mx-auto md:pt-20 pt-10">
            <div className="flex-1 basis-60">
              <img
                className="w-full h-full object-cover"
                src={contact}
                alt=" "
              />
            </div>
            <div className="flex-1 basis-60">
              <form onSubmit={sendMail}>
                <input
                  label="Full Name"
                  name="name"
                  placeholder="Full Name"
                  type="text"
                  className="input-style"
                  value={contactData.name}
                  onChange={onChange}
                />
                {error && error?.name && (
                  <p className="error-text text-left">{error.name[0]}</p>
                )}
                <PhoneInput
                  selectedCountry={selectedCountry}
                  onChange={onChange}
                  placeholder="phone number"
                  onSelectCountry={(country) => setSelectedCountry(country)}
                  name="phone"
                  style="input-style mt-3 md:mt-6"
                  value={contactData.phone}
                />
                {error && error?.phone && (
                  <p className="error-text text-left">{error.phone[0]}</p>
                )}
                <input
                  label="Email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  className="input-style mt-3 md:mt-6"
                  value={contactData.email}
                  onChange={onChange}
                />
                {error && error?.email && (
                  <p className="error-text text-left">{error.email[0]}</p>
                )}
                <textarea
                  label="Message"
                  name="message"
                  placeholder="Your Message"
                  type="textarea"
                  className="input-style mt-3 md:mt-6"
                  rows="5"
                  value={contactData.message}
                  onChange={onChange}
                />
                {error && error?.message && (
                  <p className="error-text text-left">{error.message[0]}</p>
                )}
                <button
                  type="submit"
                  className="btn-secondary border-2 w-full rounded text-xl font-lora font-medium mt-3 md:mt-6 p-3"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <div>
          <Maps />
        </div>
      </section>
    </>
  );
};

export default Contact;
