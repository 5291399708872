import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BackButton = ({ goBack }) => {
  const navigate = useNavigate();
  return (
    <span
      onClick={() => (goBack ? goBack() : navigate(-1))}
      className="bg-primaryTwo cursor-pointer flex sm:w-[10%] 2xl:w-[5%] py-2 pr-1 rounded-md items-center justify-center text-secondaryTwo"
    >
      <MdArrowBackIosNew size={18} />
      <span className="ml-1">Back</span>
    </span>
  );
};

export default BackButton;
