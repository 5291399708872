import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiEdit3 } from "react-icons/fi";
import { selectAddress } from "../../redux/features/address/AddressSlice";
import { useNavigate } from "react-router";

export default function MetaInfo() {
  const { user } = useSelector((state) => state.auth);
  const { selectedAddress, addresses } = useSelector((state) => state.address);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedAddress && addresses) {
      dispatch(selectAddress(addresses[0]));
    }
  }, [addresses]);

  return (
    <div className="contain mt-16 sm:my-0 sm:mt-16 md:my-16 xl:my-20 rounded-xl overflow-hidden">
      <div className="bg-primary rounded-xl grid lg:grid-cols-2 grid-cols-1 gap-x-32 gap-y-5 text-white p-3   md:p-6 lg:p-10">
        <div className="flex flex-col flex-nowrap gap-2 px-4">
          <div className="py-3 mb-4 flex items-center justify-between border-b border-slate-400 font-semibold text-lg ">
            <h6 className="h6 ">Your Information</h6>
            <span
              onClick={() =>
                navigate("/profile", { state: { profileDetails: true } })
              }
              className="bg-white cursor-pointer rounded-full p-1 text-primary"
            >
              <FiEdit3 size={22} />
            </span>
          </div>
          <div className="flex flex-col gap-1 flex-nowrap items-start font-light  ">
            <span>{user.name}</span>
            <span>{user.email}</span>
            <span>{user.phoneNumber}</span>
          </div>
        </div>
        <div className="flex flex-col flex-nowrap gap-2 px-4">
          <div className="py-3 flex items-center justify-between mb-4 border-b border-slate-400 font-semibold text-lg ">
            <h6 className="h6 ">Shipping Address</h6>
            <span
              onClick={() => navigate("/profile/address")}
              className="bg-white cursor-pointer rounded-full p-1 text-primary"
            >
              <FiEdit3 size={22} />
            </span>
          </div>
          <div className="flex flex-col gap-1 flex-nowrap items-start font-light">
            <span className="mb-2 font-medium">{selectedAddress?.type}</span>
            <span>{`${selectedAddress?.addressLine1}, ${selectedAddress?.addressLine2},`}</span>
            <span>{`${selectedAddress?.postalCode}, ${selectedAddress?.city}, ${selectedAddress?.state}`}</span>
            <span>{`${selectedAddress?.country?.name}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
