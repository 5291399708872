import { configureStore } from "@reduxjs/toolkit";
import {
  userSlice,
  categorySlice,
  productSlice,
  cartSlice,
  wishlistSlice,
  countrySlice,
  addressSlice,
  couponSlice,
  orderSlice,
  adminSlice,
} from "../features";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "auth",
    "category",
    "product",
    "wishlist",
    "country",
    "address",
    "coupon",
    "order",
    "admin",
  ],
};

const CountryPersistConfig = {
  key: "country",
  storage,
  blacklist: ["error", "isFetching", "country"],
};

const CartPersistConfig = {
  key: "cart",
  storage,
  blacklist: ["error", "isfetching"],
};

const AddressPersistConfig = {
  key: "address",
  storage,
  blacklist: ["addresses", "error", "isFetching"],
};

const CouponPersistConfig = {
  key: "coupon",
  storage,
  blacklist: ["coupons", "error", "isFetching"],
};

const CategoryPersistConfig = {
  key: "category",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["error", "isFetching"],
};

const ProductPersistConfig = {
  key: "product",
  storage,
  blacklist: ["error", "isFetching"],
};

const UserPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["error", "isFetching"],
};

const AdminPersistConfig = {
  key: "admin",
  storage,
  blacklist: ["error", "isFetching"],
};

const OrderPersistConfig = {
  key: "order",
  storage,
  blacklist: ["error", "isFetching"],
};

const WishlistConfig = {
  key: "wishlist",
  storage,
  blacklist: ["error", "isFetching"],
};

const rootReducer = combineReducers({
  auth: persistReducer(UserPersistConfig, userSlice.reducer),
  admin: persistReducer(AdminPersistConfig, adminSlice.reducer),
  category: persistReducer(CategoryPersistConfig, categorySlice.reducer),
  product: persistReducer(ProductPersistConfig, productSlice.reducer),
  wishlist: persistReducer(WishlistConfig, wishlistSlice.reducer),
  cart: persistReducer(CartPersistConfig, cartSlice.reducer),
  country: persistReducer(CountryPersistConfig, countrySlice.reducer),
  address: persistReducer(AddressPersistConfig, addressSlice.reducer),
  coupon: persistReducer(CouponPersistConfig, couponSlice.reducer),
  order: persistReducer(OrderPersistConfig, orderSlice.reducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(Store);
