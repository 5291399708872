import React, { useReducer, useMemo, useState, useEffect } from "react";
import Dropdown from "../../../modules/atoms/dropdown";
import UploadImage from "./uploadImage";
import BackButton from "../../../modules/atoms/backButton";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "../../../redux/features/product/productActions";
import { clearError } from "../../../redux/features/product/ProductSlice";
import { fetchCategory } from "../../../redux/features/category/CategoryActions";
import { useNavigate } from "react-router-dom";

const initialState = {
  title: "",
  subTitle: "",
  description: "",
  weight: "",
  height: "",
  length: "",
  breadth: "",
  mrp: null,
  category: null,
  subcategory: null,
};

const reducer = (product, action) => {
  return { ...product, [action.type]: action.value };
};

const AddProduct = () => {
  const [product, setProduct] = useReducer(reducer, initialState);
  const { _, error } = useSelector((state) => state.product);
  const [images, setImages] = useState([]);
  const { categories } = useSelector((state) => state.category);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productInputs = useMemo(
    () => [
      {
        title: "Product Title",
        name: "title",
        id: 1,
        type: "normal",
        value: product.title,
      },
      {
        title: "Short Description",
        name: "subTitle",
        id: 2,
        type: "normal",
        value: product.subTitle,
      },
      {
        title: "Long Description",
        name: "description",
        id: 3,
        type: "textArea",
        value: product.description,
      },
      {
        title: "Price",
        id: 4,
        name: "mrp",
        type: "normal",
        value: product.mrp,
      },
      {
        title: "Weight (in kgs)",
        name: "weight",
        id: 5,
        type: "normal",
        value: product.weight,
      },
      {
        title: "Height (in cms)",
        name: "height",
        id: 6,
        type: "normal",
        value: product.height,
      },
      {
        title: "Breadth (in cms)",
        name: "breadth",
        id: 7,
        type: "normal",
        value: product.breadth,
      },
      {
        title: "Length (in cms)",
        id: 8,
        name: "length",
        type: "normal",
        value: product.length,
      },
    ],
    [product]
  );

  useEffect(() => {
    dispatch(fetchCategory({ type: "all" }));
    return () => {
      dispatch(clearError(null));
    };
  }, []);

  const onAdd = () => {
    dispatch(
      createProduct({
        product: product,
        images: images,
        onSuccess: () => {
          navigate("/admin/products");
        },
      })
    );
  };

  const onSelectCategory = (selectedItem) => {
    dispatch(clearError({ category: null }));
    const category = categories.find(
      (category) => category.name === selectedItem.name
    );
    setSelectedCategory(category);
    setProduct({ type: "category", value: category.id });
  };

  const onSelectSubcategory = (selectedItem) => {
    dispatch(clearError({ subcategory: null }));
    const subcategory = selectedCategory?.subcategories.find(
      (subcategory) => subcategory.name === selectedItem.name
    );
    setProduct({ type: "subcategory", value: subcategory.id });
  };

  return (
    <div className="w-full h-full py-12 px-12 lg:px-20">
      <BackButton />
      <h2 className="h6 font-bold mt-10">Add Product</h2>
      <div className="bg-white rounded-md grid grid-cols-2 gap-4 px-8 py-4 mt-6">
        {productInputs.map((input) => (
          <div>
            <label className="block text-slate-600 mt-4">{`${input.title}`}</label>
            {input.type === "normal" ? (
              <>
                <input
                  onChange={(e) => {
                    dispatch(clearError({ [input.name]: null }));
                    setProduct({ type: input.name, value: e.target.value });
                  }}
                  value={input.value}
                  className="border focus:outline-none px-2 py-[8px] w-full rounded-sm mt-2 border-slate-300"
                />
                {error && error?.[input.name] && (
                  <p className="error-text">{error?.[input.name][0]}</p>
                )}
              </>
            ) : (
              <>
                <textarea
                  onChange={(e) => {
                    dispatch(clearError({ description: null }));
                    setProduct({ type: input.name, value: e.target.value });
                  }}
                  className="border h-[95px] px-2 focus:outline-none border-slate-300 w-full mt-2 hover:outline-none"
                />
                {error && error?.description && (
                  <p className="error-text">{error?.description[0]}</p>
                )}
              </>
            )}
          </div>
        ))}

        <div>
          <Dropdown
            placeholder="Select Category"
            containerStyle="w-full mt-4"
            dropdownInputStyle="w-full px-2 py-[8px] mt-2 border border-slate-300"
            dropdownStyle="w-full h-[100px] top-[80px] left-0"
            emptyListText="No category found"
            label="Category"
            labelStyle="text-md text-slate-600"
            data={categories}
            selected={selectedCategory}
            onSelect={onSelectCategory}
          />
          {error && error?.category && (
            <p className="error-text">{error?.category[0]}</p>
          )}
        </div>

        <div>
          <Dropdown
            placeholder="Select Subcategory"
            containerStyle="w-full mt-4"
            dropdownInputStyle="w-full px-2 py-[8px] border mt-2 border-slate-300"
            dropdownStyle="w-full h-[100px] top-[80px] left-0"
            emptyListText="No subcategory found"
            labelStyle="text-md text-slate-600"
            label="Subcategory"
            selected={selectedCategory?.subcategories.find(
              (subcategory) => subcategory.id === product.subcategory
            )}
            data={selectedCategory?.subcategories}
            onSelect={onSelectSubcategory}
          />
          {error && error?.subcategory && (
            <p className="error-text">{error.subcategory[0]}</p>
          )}
        </div>
        <div className="lg:col-span-2 xl:col-auto">
          <UploadImage
            images={images}
            onSetImage={(e) => setImages([...images, e.target.files[0]])}
            onDeleteImage={(index) =>
              setImages(images.filter((image, i) => i !== index))
            }
          />
        </div>
        <div className="flex items-end justify-end lg:col-span-2 xl:col-auto">
          <button
            onClick={onAdd}
            className="border-none  w-full lg:w-[60%] bg-primary text-white py-2 rounded-sm"
          >
            Add Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
