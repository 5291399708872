import React, { useState } from "react";
import { MdOutlineCloudUpload, MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../../helpers";
import { clearError } from "../../../../redux/features/product/ProductSlice";

const UploadImage = ({ images, onSetImage, onDeleteImage }) => {
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const { _, error } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const setImageHandler = (e) => {
    dispatch(clearError(null));
    onSetImage(e);
  };

  return (
    <>
      <div className="flex gap-2">
        <label
          className="w-[100px] mt-4 text-slate-500 rounded-md bg-slate-100 h-[100px] 
      border border-slate-200 cursor-pointer flex-col flex items-center justify-center"
        >
          <input
            onChange={setImageHandler}
            className="hidden"
            type="file"
            accept="image/png, image/jpg, image/jpeg"
          />
          <MdOutlineCloudUpload size={20} />
          <p>Upload</p>
        </label>
        {images?.length > 0 &&
          images?.map((image, index) => {
            return (
              <div
                key={index}
                onMouseOver={() => setHoveredImageIndex(index)}
                onMouseOut={() => setHoveredImageIndex(null)}
                className="w-[100px] bg-slate-500 relative rounded-md mt-4 h-[100px] border border-slate-200"
              >
                {image && (
                  <img
                    src={
                      image.url
                        ? `${baseURL}${image.url}`
                        : URL.createObjectURL(image)
                    }
                    alt="products"
                    className="w-full rounded-md h-full"
                  />
                )}
                <div
                  className={`absolute w-full flex items-center justify-center h-full top-0 left-0
           bg-[#00000087] ${hoveredImageIndex === index ? "block" : "hidden"}`}
                >
                  <span
                    className="bg-white cursor-pointer p-2 rounded-[50px]"
                    onClick={() => onDeleteImage(index)}
                  >
                    <MdDeleteOutline size={16} />{" "}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      {error && error?.message && (
        <p className="error-text">{error?.message}</p>
      )}
    </>
  );
};

export default UploadImage;
