import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BackButton from "../../../modules/atoms/backButton";
import { useMemo } from "react";

const UserProfile = () => {
  const { users } = useSelector((state) => state.auth);
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (users && users?.data?.length > 0) {
      const user = users.data.find((user) => user.id === parseInt(userId));
      setUser(user);
    }
  }, []);

  const profileData = useMemo(
    () => [
      { label: "First Name:", value: user?.name },
      { label: "Last Name:", value: user?.lastName },
      { label: "Gender:", value: user?.gender },
      { label: "Date Of Birth:", value: user?.dateOfBirth },
      { label: "Email:", value: user?.email },
      { label: "Phone Number:", value: user?.phoneNumber },
    ],
    [user]
  );

  const addresses = useMemo(
    () =>
      user?.addresses?.map((address) => [
        { label: "Type", value: address.type },
        { label: "Address Line 1:", value: address.addressLine1 },
        { label: "Address Line 2", value: address.addressLine2 },
        { label: "City", value: address.city },
        { label: "Postal Code", value: address.postalCode },
        { label: "State", value: address.state },
        { label: "Country", value: address.country.name },
      ]),
    [user?.addresses]
  );

  return (
    <div className="w-full h-full px-12 py-12 lg:py-16">
      <BackButton />
      <div className="mx-auto rounded-sm border border-slate-200 mt-12 py-6 px-2 bg-white">
        <p className="font-semibold text-slate-400 text-lg">Personal Details</p>
        <div className="grid grid-cols-2 mt-4 gap-1 border p-4 border-slate-200 rounded-sm">
          {profileData.map((data) => (
            <div className="flex items-center mt-2">
              <p className="inline-block font-semibold">{data.label}</p>
              <p className="ml-4">{data.value}</p>
            </div>
          ))}
        </div>
        <p className="text-xl text-slate-400 mt-8 font-semibold">Addresses</p>
        <div className="grid grid-cols-2 gap-4">
          {addresses?.map((address) => (
            <div className="mt-4 border border-slate-200 rounded-sm p-4">
              <p className="font-semibold">
                {address[0]?.value?.toUpperCase()}
              </p>
              {address?.map((key, index) =>
                index === 0 ? null : (
                  <div className="mt-2 flex mt-4 items-center">
                    <p className="font-semibold">{key.label}</p>
                    <p className="ml-4 ">{key.value}</p>
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
