import { createAsyncThunk } from "@reduxjs/toolkit";
import { create, remove, getByUser, getAll } from "../../../api/Order";

export const createOrder = createAsyncThunk(
  "order/create",
  async ({ order, onSuccess }, thunkAPI) => {
    return await create(order)
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data.order;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const removeOrder = createAsyncThunk(
  "order/remove",
  async ({ orderId, onSuccess }, thunkAPI) => {
    return await remove({ orderId: orderId })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        } else {
          onSuccess();
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const fetchAll = createAsyncThunk(
  "order/all",
  async (params, thunkAPI) => {
    return await getAll(params)
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data.orders;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const fetchByUser = createAsyncThunk(
  "user/order",
  async (userId, thunkAPI) => {
    return await getByUser({ userId })
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data.orders;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);
