import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getById } from "../../../api/category";
import { baseURL } from "../../../helpers";
import BackButton from "../../../modules/atoms/backButton";

const ProductDetails = () => {
  const { products } = useSelector((state) => state.product);
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { selectedCountry } = useSelector((state) => state.country);
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);

  const fetchCategory = async () => {
    await getById({ categoryId: product?.categoryId }).then((res) => {
      if (res.status && res.data) {
        setCategory(res.data.category);
        setSubcategory(
          res.data.category.subcategories.find(
            (subcategory) => subcategory.id === product?.subcategoryId
          )
        );
      }
    });
  };

  useEffect(() => {
    setProduct(
      products?.data?.find((product) => product.id === parseInt(productId))
    );
  }, [products]);

  useEffect(() => {
    if (product) {
      fetchCategory();
    }
  }, [product]);

  return (
    <div className="w-full h-full px-12 py-12">
      <BackButton />
      <h2 className="h6 font-bold mt-10">Product Details</h2>
      <div className="border py-6 rounded-md mt-6 border-slate-200 bg-white">
        <div className="flex items-center">
          <div className="w-[300px] mx-12 h-[350px]">
            <img
              className="w-full h-full object-cover"
              src={`${baseURL}${product?.files?.[0]?.url}`}
              alt="product"
            />
          </div>
          <div className="">
            <p className="font-semibold w-[70%]">{product?.title}</p>
            <p className="text-sm mt-2 w-[70%]">{product?.subTitle}</p>
            <p className="mt-2 text-slate-400 text-sm w-[70%]">
              {product?.description}
            </p>
            <p className="mt-4 font-semibold">
              <span className="text-slate-400 mr-2">Price:</span>

              {`${selectedCountry?.symbol}${product?.mrp}`}
            </p>
            <div className="mt-4 flex items-center">
              <p className="font-semibold">
                <span className="text-slate-400 mr-2">Weight:</span>

                {`${product?.weight}`}
              </p>
              <p className="ml-4 font-semibold">
                <span className="text-slate-400 mr-2">Breadth:</span>

                {`${product?.breadth}`}
              </p>
            </div>
            <div className="mt-2 flex items-center">
              <p className=" font-semibold">
                <span className="text-slate-400 mr-2">Length:</span>

                {`${product?.length}`}
              </p>
              <p className=" ml-4 font-semibold">
                <span className="text-slate-400 mr-2">Height:</span>

                {`${product?.height}`}
              </p>
            </div>

            <div className="mt-4 flex items-center">
              <p className=" font-semibold">
                <span className="text-slate-400 mr-2">Category:</span>

                {category?.name}
              </p>
              <p className=" ml-4 font-semibold">
                <span className="text-slate-400 mr-2">Subcategory:</span>

                {subcategory?.name}
              </p>
            </div>
          </div>
        </div>
        <div>
          {product?.files?.map((image, index) => {
            if (index !== 0) {
              return (
                <div key={image?.id} className="w-[300px] mt-4 mx-12 h-[350px]">
                  <img
                    className="w-full h-full object-cover"
                    src={`${baseURL}${image?.url}`}
                    alt="product"
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
