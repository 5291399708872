import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  loginWithOTP,
  logout,
  fetchAdmin,
  updateAdmin,
} from "./AdminActions";

const initialState = {
  admin: {
    name: "",
    email: "",
    email_verified_at: null,
    role: "",
    id: null,
  },
  error: null,
  isFetching: false,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    clearError: (state, { payload }) => {
      if (state.error?.message) {
        state.error = null;
      } else if (payload) {
        state.error = { ...state.error, ...payload };
      } else {
        state.error = payload;
      }
    },
    setError: (state, { payload }) => {
      if (payload?.data) {
        state.error = payload.data;
      } else {
        state.error = payload;
      }
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.isFetching = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.admin = {
        name: payload.user.name,
        email: payload.user.email,
        email_verified_at: payload.user.email_verified_at,
        role: payload.user.role,
        id: payload.user.id,
      };
    },
    [login.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [loginWithOTP.pending]: (state) => {
      state.isFetching = true;
    },
    [loginWithOTP.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.admin = {
        name: payload.user.name,
        email: payload.user.email,
        email_verified_at: payload.user.email_verified_at,
        role: payload.user.role,
        id: payload.user.id,
      };
    },
    [loginWithOTP.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [logout.pending]: (state) => {
      state.isFetching = true;
    },
    [logout.fulfilled]: (state) => {
      state.isFetching = false;
      state.admin = {
        name: "",
        email: "",
        email_verified_at: null,
        token: "",
        role: "",
        id: null,
      };
    },
    [logout.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [fetchAdmin.fulfilled]: (state, { payload }) => {
      state.admin = {
        name: payload.user.name,
        email: payload.user.email,
        email_verified_at: payload.user.email_verified_at,
        role: payload.user.role,
        id: payload.user.id,
      };
    },
    [updateAdmin.fulfilled]: (state, { payload }) => {
      state.admin = { ...state.admin, email: payload.user.email };
    },
  },
});

export const { clearError, setError } = adminSlice.actions;
