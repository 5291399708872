import React from "react";
import "./banner.css";
import FooterVedio from "../../../assets/home/footer/footer-video.mp4";

export default function Banner() {
  return (
    <section className="hero-section">
      <div className="video-gradien md:h-[75vh] h-[300px]  ">
        <video
          autoPlay
          loop
          muted
          className="h-full w-full object-cover object-center"
        >
          <source src={FooterVedio} type="video/mp4"></source>
        </video>
      </div>
    </section>
  );
}
