import React, { useState } from "react";
import "./index.css";

const ToggleButton = ({ onClick, toggled }) => {
  const [isToggled, setIsToggled] = useState(toggled);

  const onClickToggle = () => {
    setIsToggled(!isToggled);
    onClick(!isToggled);
  };

  return (
    <label className="toggle">
      <input
        type="checkbox"
        defaultChecked={isToggled}
        onClick={onClickToggle}
      />
      <span className="toggleIndicator" />
    </label>
  );
};

export default ToggleButton;
