import React, { useState, useEffect } from "react";
import { EditPersonal } from "./editPersonal";
import "./personal.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";

const PersonalDetail = () => {
  const [profileDetails, setProfileDetails] = useState(false);
  const { user: loggedInUser } = useSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setProfileDetails(location.state.profileDetails);
    }
  }, []);

  const personaldetails = [
    {
      category: "Full Name",
      data: `${loggedInUser.name} ${loggedInUser.lastName ?? ""}`,
      id: 1,
    },
    {
      category: "Mobile",
      data: `${loggedInUser.phoneNumber ?? "-"}`,
      id: 2,
    },
    {
      category: "Email",
      data: loggedInUser.email,
      id: 3,
    },
    {
      category: "Gender",
      data: `${loggedInUser.gender ?? "-"}`,
      id: 4,
    },
    {
      category: "Date Of Birth",
      data: `${
        loggedInUser.dob ? moment(loggedInUser.dob).format("DD-MM-YYYY") : "-"
      }`,
      id: 5,
    },
  ];
  return (
    <>
      <section className="">
        {profileDetails ? (
          <EditPersonal setProfileDetail={setProfileDetails} />
        ) : (
          <div className="slider my-12">
            <div className="flex flex-wrap gap-2  justify-between">
              <h6 className="h5 text-primary font-semibold ">
                PERSONAL DETAILS
              </h6>
              <button
                className="btn-primary-sm p"
                onClick={() => {
                  setProfileDetails(true);
                }}
              >
                EDIT DETAILS
              </button>
            </div>
            <hr className="md:mt-10 mt-4md:mt-10 mt-4 hr-line" />
            <div className="">
              {personaldetails.map((detail, index) => {
                return (
                  <div
                    key={detail.id}
                    className="max-w-screen-sm grid grid-cols-2"
                  >
                    <p className="my-6 font-manrope">{detail.category}</p>
                    <p className="my-6 font-manrope font-semibold break-words">
                      {detail.data}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default PersonalDetail;
