import React, { useReducer, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import Pagination from "../../../modules/atoms/pagination";
import { paymentMethods, orderStatus } from "../../../assets/data/orderData";

import {
  fetchAll,
  removeOrder,
} from "../../../redux/features/order/OrderActions";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";
import moment from "moment/moment";
import Filter from "../../../modules/atoms/filter";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";
import { clearError } from "../../../redux/features/order/OrderSlice";
import WarningPopup from "../../../modules/overlays/warningPopup";

const filterInitialState = {
  status: null,
  paymentMethod: null,
  country: null,
  receivedFrom: null,
  receivedTo: null,
  deliveredFrom: null,
  deliveredTo: null,
  price: null,
};

const widthSize = window.innerWidth;

const filterReducer = (state, action) => {
  switch (action.type) {
    case "status":
      return { ...state, status: action.value };
    case "paymentMethod":
      return { ...state, paymentMethod: action.value };
    case "country":
      return { ...state, country: action.value };
    case "price":
      return { ...state, price: action.value };
    case "receivedFrom":
      return { ...state, receivedFrom: action.value };
    case "receivedTo":
      return { ...state, receivedTo: action.value };
    case "deliveredFrom":
      return { ...state, deliveredFrom: action.value };
    case "deliveredTo":
      return { ...state, deliveredTo: action.value };
    default:
      return {
        status: null,
        paymentMethod: null,
        country: null,
        receivedFrom: null,
        receivedTo: null,
        deliveredFrom: null,
        deliveredTo: null,
        price: null,
      };
  }
};

const OrderList = () => {
  const { orders, isFetching, error } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const [filters, setFilters] = useReducer(filterReducer, filterInitialState);
  const [selected, setSelected] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [page, setPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const { country } = useSelector((state) => state.country);

  const fetchOrders = (page = 1, removeFilters = false) => {
    const params = { page: page };

    if (removeFilters) {
      setFilters({ type: null, value: null });
    }

    if (!removeFilters) {
      Object.keys(filters).map((key) => {
        if (filters[key] !== null) {
          if (
            key === "receivedFrom" ||
            key === "receivedTo" ||
            key === "deliveredFrom" ||
            key === "deliveredTo"
          ) {
            Object.assign(params, {
              [key]: moment(filters[key].value).format("DD-MM-YYYY"),
            });
          } else {
            Object.assign(params, { [key]: filters[key].value });
          }
        }
        return 0;
      });
    }
    dispatch(fetchAll(params));
  };

  const onSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(orders?.data?.map((order) => order.id));
    } else {
      setSelected([]);
    }
  };

  const onDelete = () => {
    dispatch(
      removeOrder({
        orderId: selected,
        onSuccess: () => {
          setSelected([]);
          fetchOrders(page);
        },
      })
    );
  };

  const onClickDelete = () => {
    if (selected.length > 0) {
      setOpenPopup(true);
    } else {
      return;
    }
  };

  const onChangePage = (pageNumber) => {
    setPage(pageNumber);
    fetchOrders(pageNumber);
  };

  const onApplyFilters = () => {
    setIsAllSelected(false);
    setPage(1);
    fetchOrders(1);
  };

  const onClickAll = () => {
    setIsAllSelected(true);
    fetchOrders(1, true);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="w-full h-full px-12">
      <ConfirmationPopup
        title={`Are you sure you want to delete ${
          selected.at.length > 1 ? "these orders" : "this order"
        }`}
        show={openPopup}
        onClose={() => setOpenPopup(false)}
        onConfirm={onDelete}
      />
      <WarningPopup
        show={error}
        title={error?.message}
        onClose={() => dispatch(clearError(null))}
      />
      <h2 className="text-lg font-bold mt-10">Orders</h2>
      <div className="border rounded-md mt-6 border-slate-200 bg-white">
        <div
          className={`flex px-4 pt-8 items-end 
        ${
          !Object.values(filters).some((value) => value !== null) &&
          "border-slate-200 border pb-8"
        }`}
        >
          <span
            className={`${
              isAllSelected ? "border-primary text-primary" : "border-slate-200"
            } py-[6px] rounded-md px-4 border cursor-pointer`}
            onClick={onClickAll}
          >
            All
          </span>
          <Filter setFilters={setFilters} filters={filters} />
        </div>
        <div
          className={` ${
            Object.values(filters).some((value) => value !== null)
              ? "flex flex-wrap"
              : "hidden"
          }
          gap-2 px-4 border-b pb-8 pt-6 border-slate-200 items-center`}
        >
          {Object.keys(filters).map(
            (key) =>
              key !== "isAllSelected" &&
              key !== "page" &&
              filters[key] !== null &&
              filters[key] !== 0 && (
                <div className="flex py-1 px-2 rounded-md border border-slate-300">
                  <p className="text-slate-500 text-lg">{`${filters[key].label} is `}</p>
                  {"  "}
                  <p className="ml-2 text-lg font-semibold">
                    {filters[key].valueLabel}
                  </p>
                  <IoClose
                    onClick={() => setFilters({ type: key, value: null })}
                    size={20}
                    className="text-slate-500 cursor-pointer mt-1 ml-2"
                  />
                </div>
              )
          )}
          <button
            onClick={onApplyFilters}
            className="bg-primary ml-2 py-1 px-4 text-white rounded-sm"
          >
            Apply
          </button>
        </div>
        {!isFetching && orders?.data?.length === 0 ? (
          <div className="flex p-4 items-center justify-center">
            <h2 className="text-xl">No orders found</h2>
          </div>
        ) : isFetching ? (
          <div className="flex justify-center my-12">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <table className="w-full">
              <thead>
                <tr className="text-sm text-black">
                  <th className="text-left px-4">
                    <input
                      onChange={onSelectAll}
                      className="w-5 cursor-pointer"
                      type="checkbox"
                    />
                  </th>
                  <th className="text-left py-4">Customer Details</th>
                  <th className="text-left">Total Price</th>

                  <th className="text-left">Payment Method</th>
                  <th className="text-left">Received At</th>

                  <th className="text-left">Status</th>
                  <th className="text-left">Actions</th>
                  <th>
                    <span
                      onClick={onClickDelete}
                      className={`${
                        selected?.length > 0
                          ? "cursor-pointer text-error"
                          : "text-slate-400"
                      } flex items-center`}
                    >
                      {`${widthSize >= 1156 ? "Delete" : ""}`}{" "}
                      <MdDeleteOutline size={18} />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders?.data?.map((order) => (
                  <tr className="border-t border-slate-200">
                    <td className="py-6 px-4">
                      <input
                        onChange={() => onSelect(order.id)}
                        checked={selected.includes(order.id)}
                        type="checkbox"
                        className="w-5 cursor-pointer"
                      />
                    </td>
                    <td className="mr-1">
                      <p className={`${widthSize <= 1156 && "w-[120px]"}`}>
                        {order.user.name}
                      </p>
                      <p
                        className={`text-sm text-slate-500 ${
                          widthSize <= 1156 &&
                          "w-[120px] whitespace-normal break-words"
                        }`}
                      >
                        {order.user.email}
                      </p>
                    </td>
                    <td>{`${
                      country?.find(
                        (country) =>
                          country.currencyCode === order?.payment?.currency
                      )?.symbol
                    }
                      ${order.totalAmount}`}</td>

                    <td>{paymentMethods[order?.payment?.paymentMethod]}</td>

                    <td>{moment(order.created_at).format("DD-MM-YYYY")}</td>

                    <td className="text-left">
                      <div>{orderStatus[order.status]}</div>
                    </td>
                    <td className="text-left">
                      <Link
                        to={`/admin/order/${order.id}/details`}
                        className="text-primary font-semibold py-1 "
                      >
                        Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <Pagination
          totalData={orders?.total}
          pageData={10}
          pageNumber={page}
          onChangePage={onChangePage}
        />
      </div>
    </div>
  );
};

export default OrderList;
