import React from "react";
import { BsArrowUp } from "react-icons/bs";

import footerImg from "../../../assets/footer.jpg";
import FooterVedio from "../../../assets/home/footer/footer-video.mp4";
import FooterArrow from "../../../assets/home/footer/footer-arrow-white.svg";
import "./footer.css";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [showScroll, setshowScroll] = useState(false);

  const handleScroll = () => {
    var scrollPosition = window.scrollY;
    // console.log(scrollPosition, "showScroll");
    if (scrollPosition > 700) {
      setshowScroll(true);
    } else setshowScroll(false);
  };

  const toHeader = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
  }, [showScroll]);

  return (
    <footer className="bg-primary">
      <div className="relative">
        <div className="video-gradient relative md:h-[70vh] h-[14rem]">
          <img src={footerImg} className=" h-full w-full object-cover" />

          <a href="/contact">
            <h2 className="h2 z-10 text-white font-lora absolute md:left-[37%] left-10 md:bottom-40 bottom-12 hover:translate-x-6 duration-[1s] lets-talk">
              Let's talk
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="212.935"
                height="69.245"
                viewBox="0 0 212.935 69.245"
                className="fill-white absolute md:w-48 w-20 md:-right-32 -right-8 -bottom-8 "
              >
                <g
                  id="Group_266"
                  data-name="Group 266"
                  transform="translate(0 0)"
                >
                  <rect
                    id="Rectangle_18"
                    data-name="Rectangle 18"
                    width="51.064"
                    height="4.255"
                    rx="1"
                    transform="translate(174.986 0) rotate(42)"
                    fill="inherit"
                  />
                  <rect
                    id="Rectangle_1045"
                    data-name="Rectangle 1045"
                    width="208.615"
                    height="4.255"
                    rx="1"
                    transform="translate(0 32.292)"
                    fill="inherit"
                  />
                  <rect
                    id="Rectangle_19"
                    data-name="Rectangle 19"
                    width="51.064"
                    height="4.255"
                    rx="1"
                    transform="translate(172.139 66.083) rotate(-42)"
                    fill="inherit"
                  />
                </g>
              </svg>
            </h2>
          </a>
        </div>
        <div className="">
          <div className="flex md:flex-row gap-1 flex-col sm:bg-transparent bg-primary sm:py-0 py-5 justify-between w-full mx-auto md:absolute static bottom-16 left-0 md:px-[8%] px-4">
            <div className="z-10">
              <Link
                to="/privacy-policy"
                className="hover:text-[#dab188] text-white font-manrope font-extralight z-10 md:px-2 pr-3"
              >
                Privacy Policy
              </Link>
              <Link
                to="/support"
                className="hover:text-[#dab188] text-white  font-manrope font-extralight md:px-2 pr-3"
              >
                Support
              </Link>
            </div>
            <h6 className="text-white font-manrope font-extralight z-10 md:block hidden">
              © Sleek Studio 2023
            </h6>
            <div className="flex">
              <a
                href="https://www.facebook.com/SleekStudioOfficial"
                className="z-10"
              >
                <h6 className="hover:text-[#dab188] text-white md:px-2 pr-3 font-manrope font-extralight">
                  Facebook
                </h6>
              </a>
              <a
                href="https://www.instagram.com/sleekstudioofficial/"
                className="z-10"
              >
                <h6 className="hover:text-[#dab188] text-white md:px-2 pr-3 font-manrope font-extralight">
                  Instagram
                </h6>
              </a>
            </div>

            <h6 className="text-white text-base font-manrope font-extralight z-10 md:hidden block">
              © Sleek Studio 2023
            </h6>
          </div>

          <div
            onClick={toHeader}
            className={`${
              showScroll ? "block" : "hidden"
            } z-10 fixed bottom-12 md:bottom-12 md:right-10 right-8 cursor-pointer`}
          >
            <div
              className={`${
                showScroll ? "opacity-1" : "opacity-0"
              } hover:bg-secondary md:p-3 p-2 rounded-full bg-primary transition-all duration-500`}
            >
              <BsArrowUp className="md:text-[2.2rem] text-2xl text-white" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
