import React, { useState, useMemo, useEffect, useRef } from "react";
import { FiFilter } from "react-icons/fi";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "./filter.css";
import moment from "moment";

const Filter = ({ setFilters, filters, dropdown1Style, dropdown2Style }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { country } = useSelector((state) => state.country);

  const filterOptions = useMemo(
    () => [
      {
        name: "Status",
        label: "status",
        data: [
          { name: "Received", id: 1, label: "received" },
          { name: "Delivered", id: 2, label: "delivered" },
          { name: "Out for delivery", id: 3, label: "out_for_delivery" },
          { name: "Picked up", id: 4, label: "picked_up" },
          { name: "In transit", id: 5, label: "in_transit" },
        ],
        id: 1,
      },
      {
        name: "Payment Method",
        label: "paymentMethod",
        data: [
          { name: "Credit", id: 1, label: "credit" },
          { name: "Debit", id: 2, label: "debit" },
          { name: "Amazon Pay", id: 3, label: "amazonpay" },
          { name: "Phone Pe", id: 4, label: "phonepe" },
          { name: "Pay Pal", id: 5, label: "paypal" },
        ],
        id: 2,
      },
      { name: "Country", id: 3, label: "country", data: country },
      { name: "Price", label: "price", data: null, id: 4 },
      { name: "Received From", label: "receivedFrom", data: null, id: 5 },
      { name: "Received To", label: "receivedTo", data: null, id: 6 },
      { name: "Delivered From", label: "deliveredFrom", data: null, id: 7 },
      { name: "Delivered To", label: "deliveredTo", data: null, id: 8 },
    ],
    [country]
  );

  const onClickFilter = () => {
    setShowOptions(!showOptions);
    setSelectedOption(null);
  };

  const onSelectOption = ({ selectedOptionName, value }) => {
    switch (selectedOptionName) {
      case "status":
        setFilters({
          type: selectedOptionName,
          value: {
            label: "status",
            value: value.label,
            valueLabel: value.name,
          },
        });
        break;
      case "paymentMethod":
        setFilters({
          type: selectedOptionName,
          value: {
            label: "payment method",
            value: value.label,
            valueLabel: value.name,
          },
        });
        break;
      case "country":
        setFilters({
          type: selectedOptionName,
          value: { label: "country", value: value.id, valueLabel: value.name },
        });
        break;
      default:
        return;
    }
  };

  const filterContainerRef = useRef();

  const onClose = (e) => {
    if (filterContainerRef && !filterContainerRef.current.contains(e.target)) {
      setSelectedOption(null);
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClose);
  }, []);

  return (
    <div ref={filterContainerRef} className="relative">
      <span
        onClick={onClickFilter}
        className="border cursor-pointer text-slate-600 hover:bg-slate-100 ml-4 px-4 
          rounded-md border-slate-200 h-[38px] flex items-center"
      >
        <FiFilter size={20} />
      </span>
      {showOptions && (
        <div
          className={`bg-white border z-10 border-slate-200 overflow-auto absolute ${
            dropdown1Style ?? "top-12 left-0 w-[200px] h-[200px]"
          }`}
        >
          {filterOptions.map((filter) => (
            <span onClick={() => setSelectedOption(filter)}>
              <p
                className={`text-sm 
              ${
                selectedOption && filter.name === selectedOption?.name
                  ? "text-primary font-semibold bg-slate-200"
                  : "text-slate-600 font-medium hover:bg-slate-100 "
              }
              leading-8 py-1 px-4 cursor-pointer border-b borer-b-slate-100`}
              >
                {filter.name}
              </p>
            </span>
          ))}
        </div>
      )}
      {selectedOption && (
        <div
          className={`bg-white border z-10 border-slate-200 absolute ${
            dropdown2Style ?? "top-12 left-[220px] w-[200px] h-[200px]"
          } ${
            ((selectedOption.name === "Status") |
              (selectedOption.name === "Payment Method") ||
              selectedOption.name === "Country") &&
            "overflow-auto"
          }
`}
        >
          {selectedOption.data ? (
            selectedOption.data.map((option) => (
              <span
                onClick={() =>
                  onSelectOption({
                    selectedOptionName: selectedOption.label,
                    value: option,
                  })
                }
              >
                <p
                  className={`text-sm 
                  ${
                    filters[selectedOption.label] &&
                    (filters[selectedOption.label]?.valueLabel ===
                      option.name ||
                      filters.country?.value === option.id)
                      ? "text-primary font-semibold bg-slate-200"
                      : "text-slate-600 font-medium hover:bg-slate-100"
                  }
                 text-slate-500 leading-8
                   py-1 px-4
                  cursor-pointer 
                  border-b borer-b-slate-100`}
                >
                  {option.name}
                </p>
              </span>
            ))
          ) : selectedOption.name === "Price" ? (
            <>
              <label className="block mt-2 text-slate-600 ml-2 text-sm">
                Enter Price
              </label>
              <input
                type="number"
                onChange={(e) =>
                  setFilters({
                    type: "price",
                    value: {
                      label: "price",
                      value: e.target.value,
                      valueLabel: e.target.value,
                    },
                  })
                }
                value={filters.price?.value !== 0 ? filters.price?.value : ""}
                placeholder="12000"
                className="border w-[90%] ml-2
            focus:outline-none mt-1 border-slate-200 rounded-sm px-2 py-[4px]"
              />
            </>
          ) : (
            <span className="relative">
              <label className="block mt-2 text-slate-600 ml-2 text-sm">
                Enter Date
              </label>
              <DatePicker
                onChange={(date) =>
                  setFilters({
                    type: selectedOption.label,
                    value: {
                      label: selectedOption.name.toLowerCase(),
                      value: date,
                      valueLabel: moment(date).format("DD-MM-YYYY").toString(),
                    },
                  })
                }
                selected={
                  filters[selectedOption.label]?.value
                    ? filters[selectedOption.label]?.value
                    : ""
                }
                dateFormat="dd-MM-yyyy"
                placeholderText="dd/mm/yyyy"
                calendarClassName="filter-date-picker"
                className="border ml-2 mt-1 focus:outline-none px-2 w-[90%]
            border-solid border-slate-300 rounded-sm py-[5px]"
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Filter;
