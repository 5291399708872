import { createSlice } from "@reduxjs/toolkit";
import {
  addCartProduct,
  removeCartProduct,
  fetchCartProducts,
} from "./CartActions";

const initialState = {
  isFetching: false,
  error: null,
  cartProducts: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cartProducts = [];
    },
    clearError: (state, { payload }) => {
      if (state.error?.message || !payload) {
        state.error = null;
      } else {
        state.error = { ...state.error, ...payload };
      }
    },
  },
  extraReducers: {
    [addCartProduct.pending]: (state) => {
      state.isFetching = true;
    },
    [addCartProduct.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      if (state.cartProducts) {
        state.cartProducts.push(payload.product);
      } else {
        state.cartProducts = { data: [payload.product] };
      }
    },
    [addCartProduct.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [removeCartProduct.pending]: (state) => {
      state.error = null;
    },
    [removeCartProduct.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    [fetchCartProducts.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchCartProducts.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.cartProducts = payload.products;
    },
    [fetchCartProducts.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { clearCart, clearError } = cartSlice.actions;
