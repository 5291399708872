import React, { useReducer, useMemo, useState, useEffect } from "react";
import Dropdown from "../../../modules/atoms/dropdown";
import UploadImage from "../addProduct/uploadImage";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../../../redux/features/product/ProductSlice";
import {
  updateProduct,
  removeProductImage,
} from "../../../redux/features/product/productActions";
import BackButton from "../../../modules/atoms/backButton";

const reducer = (product, action) => {
  return { ...product, [action.type]: action.value };
};

const initialState = {
  title: null,
  subTitle: null,
  description: null,
  mrp: null,
  category: null,
  subcategory: null,
  weight: null,
  height: null,
  breadth: null,
  length: null,
};

const EditProduct = () => {
  const [product, setProduct] = useReducer(reducer, initialState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, products } = useSelector((state) => state.product);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { categories } = useSelector((state) => state.category);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (products?.data) {
      const product = products?.data.find(
        (product) => product.id === parseInt(productId)
      );
      const category = categories.find(
        (category) => category.id === product.categoryId
      );
      setSelectedCategory(category);
      setSelectedProduct(product);
      setImages(product?.files);
    }

    return () => {
      dispatch(clearError(null));
    };
  }, [products]);

  const onUpdate = () => {
    dispatch(
      updateProduct({
        product: { ...product, productId: parseInt(productId) },
        images: images,
        onSuccess: () => {
          navigate("/admin/products");
        },
      })
    );
  };

  const productInputs = useMemo(
    () => [
      {
        title: "Product Title",
        name: "title",
        id: 1,
        type: "normal",
        value: product.title ?? selectedProduct?.title,
      },
      {
        title: "Short Description",
        name: "subTitle",
        id: 2,
        type: "normal",
        value: product.subTitle ?? selectedProduct?.subTitle,
      },
      {
        title: "Long Description",
        name: "description",
        id: 3,
        type: "textArea",
        value: product.description ?? selectedProduct?.description,
      },
      {
        title: "Price",
        id: 4,
        name: "mrp",
        type: "normal",
        value: product.mrp ?? selectedProduct?.mrp,
      },
      {
        title: "Weight (in kgs)",
        name: "weight",
        id: 5,
        type: "normal",
        value: product.weight ?? selectedProduct?.weight,
      },
      {
        title: "Height (in cms)",
        name: "height",
        id: 6,
        type: "normal",
        value: product.height ?? selectedProduct?.height,
      },
      {
        title: "Breadth (in cms)",
        name: "breadth",
        id: 7,
        type: "normal",
        value: product.breadth ?? selectedProduct?.breadth,
      },
      {
        title: "Length (in cms)",
        id: 8,
        name: "length",
        type: "normal",
        value: product?.length ?? selectedProduct?.length,
      },
    ],
    [product, selectedProduct]
  );

  const onDeleteImage = (index) => {
    if (images[index]?.url) {
      dispatch(
        removeProductImage({
          imageId: images[index].id,
          productId: parseInt(productId),
        })
      );
    } else {
      setImages(images.filter((image, i) => i !== index));
    }
  };

  const onSelectCategory = (selectedItem) => {
    dispatch(clearError({ ...error, category: null }));
    const category = categories.find(
      (category) => category.name === selectedItem.name
    );
    setSelectedCategory(category);
    setProduct({ type: "category", value: category.id });
  };

  const onSelectSubcategory = (selectedItem) => {
    dispatch(clearError({ ...error, subcategory: null }));
    const subcategory = selectedCategory.subcategories.find(
      (subcategory) => subcategory.name === selectedItem.name
    );
    setProduct({ type: "subcategory", value: subcategory.id });
  };

  return (
    <div className="w-full h-full py-12 px-12 lg:px-20">
      <BackButton />
      <h2 className="h6 font-bold mt-10">Update Product</h2>
      <div className="bg-white rounded-md grid grid-cols-2 gap-4 px-8 py-4 mt-6">
        {productInputs.map((input) => (
          <div>
            <label className="block text-slate-600 mt-4">{input.title}</label>
            {input.type === "normal" ? (
              <>
                <input
                  onChange={(e) => {
                    dispatch(clearError({ ...error, [input.name]: null }));
                    setProduct({ type: input.name, value: e.target.value });
                  }}
                  value={input.value}
                  className="border focus:outline-none px-2 w-full rounded-sm h-[35px] mt-2 border-slate-200"
                />
                {error && error?.[input.name] && (
                  <p className="error-text">{error?.[input.name][0]}</p>
                )}
              </>
            ) : (
              <>
                <textarea
                  onChange={(e) => {
                    dispatch(clearError({ ...error, description: null }));
                    setProduct({ type: input.name, value: e.target.value });
                  }}
                  value={input.value}
                  className="border h-[95px] px-2 focus:outline-none border-slate-200 w-full mt-2 hover:outline-none"
                />
                {error && error?.description && (
                  <p className="error-text">{error?.description[0]}</p>
                )}
              </>
            )}
          </div>
        ))}
        <div>
          <Dropdown
            placeholder="Select Category"
            containerStyle="w-full mt-4"
            dropdownInputStyle="w-full px-2 py-[8px] mt-2 border border-slate-300"
            emptyListText="No category found"
            label="Category"
            labelStyle="text-md text-slate-600"
            data={categories}
            selected={selectedCategory}
            onSelect={onSelectCategory}
          />
          {error && error?.category && (
            <p className="error-text">{error?.category[0]}</p>
          )}
        </div>

        <div>
          <Dropdown
            placeholder="Select Subcategory"
            containerStyle="w-full mt-4"
            dropdownInputStyle="w-full px-2 py-[8px] border mt-2 border-slate-300"
            emptyListText="No subcategory found"
            labelStyle="text-md text-slate-600"
            label="Subcategory"
            data={selectedCategory?.subcategories}
            selected={selectedCategory?.subcategories.find((subcategory) => {
              if (product.subcategory) {
                return subcategory.id === product.subcategory;
              } else {
                return subcategory.id === selectedProduct.subcategoryId;
              }
            })}
            onSelect={onSelectSubcategory}
          />
          {error && error?.subcategory && (
            <p className="error-text">{error.subcategory[0]}</p>
          )}
        </div>

        <div className="lg:col-span-2 xl:col-auto">
          <UploadImage
            images={images}
            onSetImage={(e) => setImages([...images, e.target.files[0]])}
            onDeleteImage={onDeleteImage}
          />
        </div>
        <div className="flex items-end justify-end lg:col-span-2 xl:col-auto">
          <button
            onClick={onUpdate}
            className="border-none w-full lg:w-[60%] bg-primary text-white py-2 rounded-sm"
          >
            Update Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
