import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAdress } from "../../../redux/features/address/AddressActions";
import { clearError } from "../../../redux/features/address/AddressSlice";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";

const initialState = {
  addressLine1: null,
  addressLine2: null,
  city: null,
  postalCode: null,
  state: null,
  country: null,
  type: null,
};

const EditAddress = ({ setEditAddress, selectedAddress }) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState(initialState);
  const { country } = useSelector((state) => state.country);
  const { error, isFetching } = useSelector((state) => state.address);

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedCountryId = address.country
      ? country.find((country) => country.name === address.country)?.id
      : null;

    dispatch(
      updateAdress({
        address: {
          ...address,
          countryId: selectedCountryId,
          addressId: selectedAddress.id,
        },
        onSuccess: () => {
          setAddress(initialState);
          setEditAddress(false);
        },
      })
    );
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(clearError({ [name]: null }));
    setAddress({ ...address, [name]: value });
  };

  const onCancel = () => {
    dispatch(clearError(null));
    setAddress(initialState);
    setEditAddress(false);
  };

  return (
    <>
      <div className="my-9">
        <h6 className="text-[22px] font-semibold text-[#7A7A7A]">
          Edit Address
        </h6>
        <form
          onSubmit={handleSubmit}
          className="my-10 grid md:grid-cols-2 gap-6"
        >
          <span>
            <input
              type="text"
              placeholder="Address Line 1"
              value={address.addressLine1 ?? selectedAddress?.addressLine1}
              onChange={handleChange}
              name="addressLine1"
              className="py-3 px-4 outline-[#476F66] input-secondary"
            />
            {error && error?.addressLine1 && <p>{error.addressLine1[0]}</p>}
          </span>
          <span>
            <input
              type="text"
              placeholder="Address Line 2"
              value={address.addressLine2 ?? selectedAddress?.addressLine2}
              onChange={handleChange}
              name="addressLine2"
              className="py-3 px-4 outline-[#476F66] input-secondary"
            />
            {error && error?.addressLine2 && <p>{error.addressLine2[0]}</p>}
          </span>
          <span>
            <input
              type="text"
              placeholder="City"
              value={address.city ?? selectedAddress?.city}
              onChange={handleChange}
              name="city"
              className="py-3 px-4 outline-[#476F66] input-secondary"
            />
            {error && error?.city && <p>{error.city[0]}</p>}
          </span>
          <span>
            <input
              type="text"
              placeholder="State"
              value={address.state ?? selectedAddress?.state}
              onChange={handleChange}
              name="state"
              className="py-3 px-4 outline-[#476F66] input-secondary"
            />
            {error && error?.state && <p>{error.state[0]}</p>}
          </span>
          <span>
            <input
              type="text"
              placeholder="Postal Code/Pin Code"
              value={address.postalCode ?? selectedAddress?.postalCode}
              onChange={handleChange}
              name="postalCode"
              className="py-3 px-4 outline-[#476F66] input-secondary"
            />
            {error && error?.postalCode && <p>{error.postalCode[0]}</p>}
          </span>
          <span>
            <select
              type="name"
              className="py-3 px-4 outline-[#476F66] input-secondary"
              value={address.country ?? selectedAddress?.country?.name}
              onChange={handleChange}
              name="country"
            >
              <option hidden disabled selected>
                Country
              </option>
              {country?.map((country) => (
                <option key={country.id}>{country.name}</option>
              ))}
            </select>
            {error && error?.countryId && (
              <p className="error-text">Country field is required</p>
            )}
          </span>
          <span>
            <select
              type="number"
              className="py-3 px-4 outline-[#476F66] input-secondary"
              value={address.type ?? selectedAddress?.type}
              onChange={handleChange}
              name="type"
            >
              <option hidden disabled selected>
                Address Type
              </option>
              <option>Home</option>
              <option>Office</option>
            </select>
            {error && error?.type && <p className="error-text">{error.type}</p>}
          </span>
        </form>
        <div className="my-10 grid md:grid-cols-2 gap-6">
          <button className="btn-primary" onClick={onCancel}>
            CANCEL
          </button>
          <button onClick={handleSubmit} className="btn-secondary rounded">
            {isFetching ? (
              <div className="flex items-center justify-center">
                <LoadingIndicator
                  indicatorStyle={{
                    width: "30px",
                    height: "30px",
                    borderTop: "5px solid #dab188",
                  }}
                />
              </div>
            ) : (
              "SAVE"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditAddress;
