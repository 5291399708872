import React, { useEffect } from "react";
import MetaInfo from "./metaInfo";
import ItemsTable from "./itemstable";
import ItemsTableMobile from "./itemstableMobile";
import BillingMeta from "./billingMeta";

export default function ConfirmCheckout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-screen-2xl mx-auto">
      <p className="h4 text-primary">Checkout Confirmation</p>
      <MetaInfo />
      <div className="md:block hidden">
        <ItemsTable />
      </div>
      <div className="md:hidden block">
        <ItemsTableMobile />
      </div>
      <BillingMeta />
    </div>
  );
}
