import { createAsyncThunk } from "@reduxjs/toolkit";
import { add, remove, get } from "../../../api/WishList";

export const addWishlistProduct = createAsyncThunk(
  "wishlist/add",
  async ({ userId, productId, onSuccess }, thunkAPI) => {
    return await add({ userId, productId })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const removeWishlistProduct = createAsyncThunk(
  "wishlist/remove",
  async ({ userId, productId }, thunkAPI) => {
    return await remove({ userId, productId })
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchWishlistProduct = createAsyncThunk(
  "wishlist/products",
  async ({ body, params }, thunkAPI) => {
    return await get(body, params)
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
