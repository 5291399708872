import React, { useMemo } from "react";
import "./cart.css";
import { IoClose } from "react-icons/io5";
import { useEffect } from "react";
import { useState } from "react";
import CartItem from "./cartItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartProducts } from "../../redux/features/cart/CartActions";
import { applyCoupon } from "../../redux/features/coupon/CouponActions";
import {
  clearError,
  removeAppliedCoupon,
} from "../../redux/features/coupon/CouponSlice";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";
import { toast, ToastContainer } from "react-toastify";

const Cart = ({ closeCart }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const { cartProducts, isFetching } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);
  const { appliedCoupon, subtotal, error } = useSelector(
    (state) => state.coupon
  );
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState("");
  const { selectedCountry } = useSelector((state) => state.country);
  const navigate = useNavigate();

  const total = useMemo(
    () => cartProducts?.reduce((total, product) => total + product.mrp, 0),
    [cartProducts]
  );

  const onApplyCoupon = () => {
    dispatch(
      applyCoupon({
        userId: user.id,
        couponCode: coupon,
        countryId: selectedCountry.id,
        onSuccess: () => {
          toast.success("Coupon has been applied successfully", {
            position: "bottom-right",
          });
        },
      })
    );
    setCoupon("");
  };

  const onSetCoupon = (e) => {
    dispatch(clearError(null));
    setCoupon(e.target.value);
  };

  const onCheckout = () => {
    navigate("/checkout");
    closeCart();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setStartAnimation(true);
    dispatch(
      fetchCartProducts({ userId: user.id, countryId: selectedCountry.id })
    );
    return () => {
      dispatch(clearError(null));
    };
  }, []);

  useEffect(() => {
    if (total && selectedCountry?.id) {
      if (
        parseInt(total) < appliedCoupon?.minCartAmount ||
        selectedCountry?.currencyCode !== appliedCoupon?.currencyCode
      ) {
        dispatch(removeAppliedCoupon());
      }
    }
  }, [selectedCountry, total]);

  return (
    <div className="cart-background">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick={true}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <section
        className={`cart-wrapper ${startAnimation && "cart-wrapper-open"}`}
      >
        <div
          className={`${
            !cartProducts || cartProducts?.length === 0 ? "relative h-full" : ""
          }`}
        >
          <div className="flex justify-between mt-12 mb-4 md:mx-12 mx-5 border-b border-[#EBEBEB] pb-4">
            <h6 className="font-bold text-[#000000]">{`My Shopping Cart (${cartProducts?.length})`}</h6>
            <span className="cancel-icon">
              <IoClose
                fontSize={26}
                onClick={async () => {
                  await setStartAnimation(false);
                  setTimeout(() => closeCart(false), 400);
                }}
              />
            </span>
          </div>
          {!isFetching && !cartProducts?.length ? (
            <div className="flex flex-col items-center w-full h-full top-[40%] absolute">
              <HiOutlineShoppingBag
                className="text-secondary font-normal"
                size={68}
              />
              <h4 className="text-xl mt-4 font-bold">Your basket is empty</h4>
              <p className="mt-4 text-sm text-[#7B7B7B]">
                You have no items in your shopping cart yet.
              </p>
            </div>
          ) : isFetching ? (
            <div className="flex mt-16 items-center justify-center">
              <LoadingIndicator />
            </div>
          ) : (
            <>
              {cartProducts?.map((product) => (
                <CartItem key={product?.pivot?.id} product={product} />
              ))}
            </>
          )}
        </div>
        {cartProducts && cartProducts?.length > 0 && (
          <div>
            <div className="mt-6 border-b md:mx-12 mx-5 py-4 border-[#EBEBEB]">
              <div className="flex justify-between">
                <input
                  placeholder="Coupon Code"
                  value={coupon}
                  onChange={onSetCoupon}
                  className="border px-2 py-1 focus:outline-none text-sm rounded-sm w-[76%] border-[#DBDBDB]"
                />
                <button
                  onClick={onApplyCoupon}
                  className="bg-[#FAFAFA] border border-[#DBDBDB] py-[6px] rounded-sm px-8 ml-4"
                >
                  Apply
                </button>
              </div>
              {error && error?.coupon && (
                <p className="error-text">{error.coupon[0]}</p>
              )}
              {error && error?.message && (
                <p className="error-text">{error.message}</p>
              )}
            </div>
            <div className="flex mt-6 md:mx-12 mx-5 items-center justify-between">
              <p className="text-slate-400 text-sm font-bold">Order Total</p>
              <p className="text-primary font-bold text-lg">
                {`${selectedCountry?.symbol}${parseInt(total)}`}
              </p>
            </div>
            {appliedCoupon && (
              <div className="flex mt-2 md:mx-12 mx-5 items-center justify-between">
                <p className="text-slate-400 text-sm font-bold">Discount</p>
                <p className="text-primary font-bold text-lg">
                  {`- ${selectedCountry?.symbol}${parseInt(
                    appliedCoupon?.amount && appliedCoupon?.amount !== 0
                      ? appliedCoupon?.amount
                      : total * (appliedCoupon?.percentage / 100)
                  )}`}
                </p>
              </div>
            )}
            <div className="flex mt-2 md:mx-12 mx-5 items-center justify-between">
              <p className="text-slate-400 text-sm font-bold">Sub Total</p>
              <p className="text-primary font-bold text-lg">
                {subtotal !== 0
                  ? `${selectedCountry?.symbol}${parseInt(subtotal)}`
                  : `${selectedCountry?.symbol}${parseInt(total)}`}
              </p>
            </div>
            <div className="my-6 md:mx-12 mx-5 flex">
              <button
                onClick={onCheckout}
                className="border-2 hover:bg-secondary hover:text-white text-secondary font-bold text-lg py-2 rounded-sm w-full border-secondary"
              >
                Checkout
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Cart;
