import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const create = (body) =>
  api.post("product/upload", body, {}, "formData", "admin");

export const removeImage = (body) =>
  api.post("product/remove-image", body, {}, "", "admin");

export const update = (body) =>
  api.post("product/update", body, {}, "formData", "admin");

export const remove = (body) => api.delete("product/remove", body, {}, "admin");

export const getByCategory = (body, params) =>
  api.post("category/products", body, params);

export const getBySubcategory = (body, params) =>
  api.post("subcategory/products", body, params);

export const getRelatedProducts = (body, params) =>
  api.post("product/related", body, params);

export const search = (body, params) =>
  api.post("product/search", body, params);

export const getAll = (params) => api.get("products", params);

export const getById = (body) => api.post("product/details", body);
