import React, { useEffect, useState } from "react";
import { MdRemove } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWishlistProduct,
  removeWishlistProduct,
} from "../../redux/features/wishlist/WishlistActions";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";
import { baseURL } from "../../helpers";
import { Link, useNavigate } from "react-router-dom";
import PaginationContainer from "../../modules/atoms/pagination";

function Wishlist() {
  const { user } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const { selectedCountry } = useSelector((state) => state.country);
  const dispatch = useDispatch();
  const { wishlistProducts, isFetching } = useSelector(
    (state) => state.wishlist
  );
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user?.id && selectedCountry?.id) {
      dispatch(
        fetchWishlistProduct({
          body: { userId: user.id, countryId: selectedCountry.id },
          params: { page: page },
        })
      );
    }
  }, [user, selectedCountry]);

  const onDeleteProduct = (id) => {
    dispatch(removeWishlistProduct({ userId: user.id, productId: id }));
    let pageNumber = page;

    if (wishlistProducts?.data?.length < 2 && page > 1) {
      pageNumber = page - 1;
    }
    dispatch(
      fetchWishlistProduct({
        body: { userId: user.id, countryId: selectedCountry.id },
        params: { page: pageNumber },
      })
    );
  };

  const onChangePage = (page) => {
    setPage(page);
    dispatch(
      fetchWishlistProduct({
        body: { userId: user.id, countryId: selectedCountry.id },
        params: { page: page },
      })
    );
  };

  return (
    <>
      <section className="contain">
        <div className="flex flex-wrap justify-between items-center m-auto md:pt-32 pt-20">
          <h4 className="h4 text-primary basis-[300px] font-medium font-manrope">
            Your Wishlist
          </h4>
          <h6 className="text-xl text-primary font-lora">
            <b>Total</b> {wishlistProducts?.data?.length} items
          </h6>
        </div>
        {!isFetching && !wishlistProducts?.data?.length ? (
          <div className="w-full min-h-[40vh] my-16 flex flex-col items-center justify-center h-full">
            <div className="w-full grid place-content-center h-full">
              <p className="text-center text-5xl font-light text-primary">
                oops..
              </p>
              <p className="text-center font-light text-2xl mt-4 text-primary">
                Your wishlist is empty!
              </p>
              <div className="md:w-[300px] w-full mt-6">
                <button
                  onClick={() => navigate("/")}
                  className="border-2 hover:bg-white bg-secondary hover:text-secondary text-white font-semibold text-lg py-2 rounded-sm w-full border-secondary"
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        ) : isFetching ? (
          <div className="flex min-h-[60vh] my-20 items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-6 justify-start  md:mt-16 mb-28">
              {wishlistProducts?.data?.map((data, index) => (
                <div
                  key={index}
                  className="mt-4 md:basis-[280px]  flex flex-col justify-between grow-0 shrink relative"
                >
                  <div>
                    <div className="md:min-h-[280px]">
                      <Link
                        to={`/product/${data?.id}`}
                        className="md:text-base text-[14px] underline font-semibold"
                      >
                        <img
                          src={`${baseURL}${data?.files[0]?.url}`}
                          className="2xl:h-[450px] xl:h-[380px] md:h-[300px] h-fit min-h-[200px] w-full object-cover object-center "
                          alt=""
                          loading="lazy"
                          decoding="async"
                        />
                      </Link>
                    </div>
                    <div className="mt-2 flex md:flex-row flex-col gap-2 ">
                      <div className="flex-1">
                        <p className="md:text-base text-[14px] text-primary font-manrope font-bold">
                          {`${data?.title.slice(0, 80)}`}
                        </p>
                      </div>
                      <div className="flex-0 px-2 md:text-base text-[14px] text-secondary">
                        {`${selectedCountry?.symbol}${parseInt(data?.mrp)}`}
                      </div>
                    </div>
                  </div>
                  <MdRemove
                    className="absolute cursor-pointer fill-white text-white hover:bg-[#C40808] -top-3 -right-3 rounded-full bg-[#476F66] p-1"
                    size={26}
                    onClick={() => onDeleteProduct(data?.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <PaginationContainer
          totalData={wishlistProducts?.total}
          pageData={10}
          onChangePage={onChangePage}
          pageNumber={page}
        />
      </section>
    </>
  );
}

export default Wishlist;
