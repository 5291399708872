import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartProducts,
  removeCartProduct,
} from "../../redux/features/cart/CartActions";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";
import { baseURL } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { applyCoupon } from "../../redux/features/coupon/CouponActions";
import { toast } from "react-toastify";
import {
  clearError,
  removeAppliedCoupon,
} from "../../redux/features/coupon/CouponSlice";
import Measurement from "../measurement";

function CartList() {
  const { user } = useSelector((state) => state.auth);
  const { selectedCountry } = useSelector((state) => state.country);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartProducts, isFetching } = useSelector((state) => state.cart);
  const { appliedCoupon, subtotal, error } = useSelector(
    (state) => state.coupon
  );
  const [coupon, setCoupon] = useState("");
  const [showMeasurement, setShowMeasurement] = useState(false);
  const [measurements, setMeasurements] = useState(null);

  const total = useMemo(
    () => cartProducts?.reduce((total, product) => total + product.mrp, 0),
    [cartProducts]
  );

  useEffect(() => {
    dispatch(
      fetchCartProducts({ userId: user.id, countryId: selectedCountry.id })
    );
    return () => {
      dispatch(clearError(null));
    };
  }, [selectedCountry]);

  useEffect(() => {
    if (total && selectedCountry?.id) {
      if (
        parseInt(total) < appliedCoupon?.minCartAmount ||
        selectedCountry?.currencyCode !== appliedCoupon?.currencyCode
      ) {
        dispatch(removeAppliedCoupon());
      }
    }
  }, [selectedCountry, total]);

  const onApplyCoupon = () => {
    dispatch(
      applyCoupon({
        userId: user.id,
        couponCode: coupon,
        countryId: selectedCountry.id,
        onSuccess: () => {
          toast.success("Coupon has been applied successfully", {
            position: "bottom-right",
          });
        },
      })
    );
    setCoupon("");
  };

  const onSetCoupon = (e) => {
    dispatch(clearError(null));
    setCoupon(e.target.value);
  };

  const onDeleteProduct = (id) => {
    dispatch(
      removeCartProduct({
        cartId: [id],
        userId: user.id,
        onSuccess: () => {
          dispatch(
            fetchCartProducts({
              userId: user.id,
              countryId: selectedCountry.id,
            })
          );
        },
      })
    );
  };

  const onCheckout = () => {
    navigate("/checkout");
  };

  const onSelectProduct = (product) => {
    const { userId, productId, id, ...newMeasurements } = product.pivot;
    setMeasurements(newMeasurements);
    setShowMeasurement(true);
  };

  return (
    <>
      <section className="contain">
        <div className="flex flex-wrap  justify-between items-center m-auto md:pt-32 pt-20">
          <h4 className="h4 text-primary  basis-[300px] font-medium font-manrope">
            Your Cart
          </h4>
          {cartProducts?.length > 0 ? (
            <h6 className="text-xl text-primary font-lora">
              <b>Total</b> {cartProducts?.length} items
            </h6>
          ) : null}
        </div>

        <div className="flex gap-12 md:flex-row flex-col">
          <div className="flex-1 ">
            {!isFetching && !cartProducts?.length ? (
              <div className="w-full min-h-[40vh] my-16 flex flex-col items-center justify-center h-full">
                <div className="w-full grid place-content-center h-full">
                  <p className="text-center text-5xl font-light text-primary">
                    oops..
                  </p>
                  <p className="text-center font-light text-2xl mt-4 text-primary">
                    Your cart is empty!
                  </p>
                  <div className="md:w-[300px] w-full mt-6">
                    <button
                      onClick={() => navigate("/")}
                      className="border-2 hover:bg-white bg-secondary hover:text-secondary text-white font-semibold text-lg py-2 rounded-sm w-full border-secondary"
                    >
                      Continue Shopping
                    </button>
                  </div>
                </div>
              </div>
            ) : isFetching ? (
              <div className="flex min-h-[55vh] my-20 items-center justify-center">
                <LoadingIndicator />
              </div>
            ) : (
              <div className="flex flex-col justify-center ">
                {/* <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-6 justify-start  md:mt-16 mb-16"> */}
                {cartProducts?.map((data, index) => (
                  // <div
                  //   key={index}
                  //   className="mt-4 d md:basis-[280px] group  flex flex-col justify-between grow-0 shrink relative"
                  // >
                  //   <div>
                  //     <div className="md:min-h-[280px]">
                  //       <div className="md:text-base text-[14px] underline font-semibold">
                  //         <img
                  //           src={`${baseURL}${data?.files[0]?.url}`}
                  //           className="2xl:h-[450px] xl:h-[380px] md:h-[300px] h-[250px] min-h-[200px] w-full object-cover object-center "
                  //           alt=""
                  //           loading="lazy"
                  //           decoding="async"
                  //         />
                  //       </div>
                  //     </div>
                  //     <div className="mt-2 flex md:flex-row flex-col gap-2 ">
                  //       <div className="flex-1">
                  //         <p className="md:text-base text-[14px] text-primary font-manrope font-bold">
                  //           {`${data?.title.slice(0, 80)}`}
                  //         </p>
                  //       </div>
                  //       <div className="flex-0 px-2 md:text-base text-[14px] text-secondary">
                  //         {`${selectedCountry?.symbol}${parseInt(data?.mrp)}`}
                  //       </div>
                  //     </div>
                  //   </div>
                  //   <MdRemove
                  //     className="absolute cursor-pointer fill-white text-white hover:bg-[#C40808] -top-3 -right-3 rounded-full bg-[#476F66] p-1"
                  //     size={26}
                  //     onClick={() => onDeleteProduct(data?.pivot?.id)}
                  //   />
                  // </div>
                  <div className="flex flex-row flex-nowrap gap-3 items-start p-2 py-5">
                    <div className="basis-36 grow-0 shrink min-w-[100px]">
                      <img
                        src={`${baseURL}${data?.files[0]?.url}`}
                        alt="man in suit"
                        height="1"
                        width="1"
                        className="h-44 w-full object-cover"
                      />
                    </div>
                    <div className="font-franklin py-1">
                      <h6 className="font-bold">{data.title}</h6>
                      <p className="text-slate-400">{data.subTitle}</p>
                      <div className="flex mt-1 justify-between items-center">
                        <div>
                          <p className="font-bold mt-2 text-primary">{`${
                            selectedCountry.symbol
                          } ${parseInt(data.mrp)}`}</p>
                        </div>
                        <div>
                          <button
                            onClick={() => onSelectProduct(data)}
                            className=" cursor-pointer text-secondary"
                          >
                            Measurements
                          </button>
                          <button
                            onClick={() => onDeleteProduct(data?.pivot?.id)}
                            className="border-none ml-2 cursor-pointer text-error "
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* </div> */}
              </div>
            )}
          </div>

          {cartProducts?.length && cartProducts?.length > 0 ? (
            <div className="md:w-[35%]  min-w-[300px] shadow">
              <div className="mt-6 border-b md:mx-12 mx-5 py-4 border-[#EBEBEB]">
                <div className="flex justify-between">
                  <input
                    placeholder="Coupon Code"
                    value={coupon}
                    onChange={onSetCoupon}
                    className="border px-2 py-1 focus:outline-none text-sm rounded-sm w-[76%] border-[#DBDBDB]"
                  />
                  <button
                    onClick={onApplyCoupon}
                    className="bg-[#FAFAFA] border border-[#DBDBDB] py-[6px] rounded-sm px-8 ml-4"
                  >
                    Apply
                  </button>
                </div>
                {error && error?.coupon && (
                  <p className="error-text">{error.coupon[0]}</p>
                )}
                {error && error?.message && (
                  <p className="error-text">{error.message}</p>
                )}
              </div>
              <div className="flex mt-6 md:mx-12 mx-5 items-center justify-between">
                <p className="text-slate-400 text-sm font-bold">Order Total</p>
                <p className="text-primary font-bold text-lg">
                  {`${selectedCountry?.symbol}${parseInt(total)}`}
                </p>
              </div>
              {appliedCoupon && (
                <div className="flex mt-2 md:mx-12 mx-5 items-center justify-between">
                  <p className="text-slate-400 text-sm font-bold">Discount</p>
                  <p className="text-primary font-bold text-lg">
                    {`- ${selectedCountry?.symbol}${parseInt(
                      appliedCoupon?.amount && appliedCoupon?.amount !== 0
                        ? appliedCoupon?.amount
                        : total * (appliedCoupon?.percentage / 100)
                    )}`}
                  </p>
                </div>
              )}
              <div className="flex mt-2 md:mx-12 mx-5 items-center justify-between">
                <p className="text-slate-400 text-sm font-bold">Sub Total</p>
                <p className="text-primary font-bold text-lg">
                  {subtotal !== 0
                    ? `${selectedCountry?.symbol}${parseInt(subtotal)}`
                    : `${selectedCountry?.symbol}${parseInt(total)}`}
                </p>
              </div>
              <div className="my-6 md:mx-12 mx-5 flex">
                <button
                  onClick={onCheckout}
                  className="border-2 hover:bg-secondary hover:text-white text-secondary font-bold text-lg py-2 rounded-sm w-full border-secondary"
                >
                  Checkout
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      {showMeasurement ? (
        <Measurement
          closeCart={setShowMeasurement}
          existingMeasurements={measurements}
        />
      ) : null}
    </>
  );
}

export default CartList;
