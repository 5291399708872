//Creating the slice for the user only
// for signup user
// for Login user
// for fetching user by token

// define initial state in the same file

import { createSlice } from "@reduxjs/toolkit";
import {
  register,
  login,
  logout,
  updateUser,
  fetchUser,
  loginWithOTP,
  fetchAllUsers,
} from "./UserActions";

const initialState = {
  user: {
    name: "",
    email: "",
    email_verified_at: null,
    phone_verified_at: null,
    lastName: "",
    role: "",
    dob: null,
    phoneNumber: "",
    gender: "",
    isActivated: false,
    id: null,
  },
  isFetching: false,
  error: null,
  users: null,
};

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.users = null;
    },
    clearError: (state, { payload }) => {
      if (state.error?.message) {
        state.error = null;
      } else if (payload) {
        state.error = { ...state.error, ...payload };
      } else {
        state.error = payload;
      }
    },
    setError: (state, { payload }) => {
      if (payload?.data) {
        state.error = payload.data;
      } else {
        state.error = payload;
      }
    },
  },
  extraReducers: {
    //sign up reducers

    [register.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.error = null;
      state.user = payload.user;
    },
    [register.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [register.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    //sign in reducers

    [login.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.user = payload.user;
    },

    [login.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },

    [login.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    // sign out reducers

    [logout.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },

    [logout.fulfilled]: (state) => {
      state.isFetching = false;
      state.user = {
        name: "",
        email: "",
        email_verified_at: null,
        phone_verified_at: null,
        lastName: "",
        role: "",
        dob: null,
        phoneNumber: "",
        gender: "",
        isActivated: false,
        id: null,
      };
      state.token = "";
      state.tokenId = null;
    },

    [logout.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    //update user reducers

    [updateUser.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.user = payload.user;
    },

    [updateUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    //fetch user reducers

    [fetchUser.pending]: (state) => {
      state.isFetching = true;
    },

    [fetchUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.user = payload.user;
    },

    [fetchUser.rejected]: (state) => {
      state.isFetching = false;
    },

    [loginWithOTP.pending]: (state) => {
      state.error = null;
      state.isFetching = true;
    },

    [loginWithOTP.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.user = payload.user;
    },

    [loginWithOTP.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    [fetchAllUsers.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchAllUsers.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.users = payload.users;
    },

    [fetchAllUsers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { clearError, setError, clearUsers } = userSlice.actions;
