import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { RxCross1 } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import { clearError } from "../../redux/features/users/userSlice";
import { clearError as adminClearError } from "../../redux/features/admin/AdminSlice";

const OTPPopup = ({ data, onVerify, onResend, onClose, show }) => {
  const [otp, setOtp] = useState("");
  const { error } = useSelector((state) => state.auth);
  const { error: adminError } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const handleverify = () => {
    onVerify(otp);
    if (error === null || adminError === null) {
      setOtp("");
    }
  };

  const handleChange = (otp) => {
    if (adminError) {
      dispatch(adminClearError(null));
    } else {
      dispatch(clearError(null));
    }
    setOtp(otp);
  };

  return (
    <>
      {show && (
        <div className="h-full fixed top-0 left-0 z-[9999] w-full bg-[#00000094] flex items-center justify-center">
          <div className="w-[500px] relative h-[350px] p-8 bg-white">
            <span className="cursor-pointer" onClick={onClose}>
              <RxCross1 className="absolute right-4 top-4" size={16} />
            </span>
            <p className="text-center text-lg font-medium text-primary">{`Please enter the OTP sent to ${data}`}</p>
            <div className="flex item-center justify-center">
              <OTPInput
                numInputs={6}
                value={otp}
                onChange={handleChange}
                inputStyle={{
                  borderBottom: "1px solid #c6c6c6",
                  width: "50px",
                  height: "50px",
                  marginLeft: "12px",
                }}
                focusStyle={{
                  outline: "none",
                  borderBottom: "1px solid #476f66",
                }}
              />
            </div>
            {error && error?.message && (
              <p className="error-text text-sm text-center">{error.message}</p>
            )}
            {adminError && adminError?.message && (
              <p className="error-text text-sm text-center">
                {adminError?.message}
              </p>
            )}
            <div className="flex flex-col items-center justify-center">
              <button
                onClick={handleverify}
                className="bg-secondary mt-20 text-white rounded-sm w-[90%] py-2"
              >
                Verify
              </button>
            </div>
            <div className="flex items-center justify-center mt-2">
              <span className="text-primary text-center">
                Not received OTP?
              </span>
              <span
                onClick={onResend}
                className="text-secondary cursor-pointer ml-1"
              >
                Resent OTP
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OTPPopup;
