import React, { useEffect, useState } from "react";
import "./measurement.css";
import { IoClose } from "react-icons/io5";
import { addCartProduct } from "../../redux/features/cart/CartActions";
import { clearError } from "../../redux/features/cart/CartSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Measurement = ({ closeCart, productId, existingMeasurements }) => {
  const [startAnimation, setStartAnimation] = useState(false);

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.cart);
  const [measurements, setMeasurements] = useState({
    suitLength: "",
    chest: "",
    shoulder: "",
    pantLength: "",
    belly: "",
    suitHips: "",
    pantHips: "",
    waist: "",
    armLength: "",
    bottom: "",
    crossBack: "",
    thighs: "",
    insideLeg: "",
  });
  const dispatch = useDispatch();

  const onChangeMeasurement = (e) => {
    dispatch(clearError({ [e.target.name]: null }));

    const value = e.target.value;
    setMeasurements({ ...measurements, [e.target.name]: value });
  };

  // Add current product to the cart then redirect to the cart page

  const onAddProductToCart = () => {
    dispatch(
      addCartProduct({
        userId: user?.id,
        productId: productId,
        measurements: measurements,
        onSuccess: () => {
          toast.success("Product added to the cart successfully", {
            position: "bottom-center",
            autoClose: 300,
          });
          navigate("/cart");
        },
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setStartAnimation(true);
    if (existingMeasurements) {
      setMeasurements(existingMeasurements);
    }
    return () => {
      dispatch(clearError(null));
    };
  }, []);

  return (
    <div className="measurement-background">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick={true}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <section
        className={`measurement-wrapper ${
          startAnimation && "measurement-wrapper-open"
        }`}
      >
        <div className="relative h-full mt-12 mb-4 md:mx-12 mx-5 ">
          <div className="flex justify-between items-center border-b border-[#EBEBEB] pb-4">
            <h5 className="font-semibold text-2xl uppercase text-secondary">
              Measurements
            </h5>
            <span className="cancel-icon">
              <IoClose
                fontSize={26}
                onClick={async () => {
                  await setStartAnimation(false);
                  setTimeout(() => closeCart(false), 400);
                }}
              />
            </span>
          </div>

          <div className="mt-5">
            <form className="grid grid-cols-2 md:gap-x-16 gap-x-5 gap-y-4 mt-5">
              <h1 className="h6 text-cente font-bold uppercase text-secondary col-span-full mt-4">
                Suit
              </h1>
              <div className="flex justify-between items-center gap-2 ">
                <label className="text-primary font-medium">Length</label>
                <div className="flex-col items-end ">
                  <input
                    onChange={onChangeMeasurement}
                    placeholder=" inch"
                    name="suitLength"
                    disabled={existingMeasurements != null}
                    type="number"
                    step="0.01"
                    value={measurements.suitLength}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.suitLength && (
                    <p className="error-text">{error?.suitLength[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2">
                <label className="text-primary font-medium">Chest</label>
                <div className="flex-col item-center">
                  <input
                    placeholder=" inch"
                    onChange={onChangeMeasurement}
                    name="chest"
                    disabled={existingMeasurements != null}
                    type="number"
                    step="0.01"
                    value={measurements.chest}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.chest && (
                    <p className="error-text">{error?.chest[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2">
                <label className="text-primary font-medium">Belly</label>
                <div>
                  <input
                    placeholder=" inch"
                    type="number"
                    step="0.01"
                    onChange={onChangeMeasurement}
                    disabled={existingMeasurements != null}
                    value={measurements.belly}
                    name="belly"
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.belly && (
                    <p className="error-text">{error?.belly[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Hips</label>
                <div>
                  <input
                    placeholder=" inch"
                    type="number"
                    step="0.01"
                    onChange={onChangeMeasurement}
                    disabled={existingMeasurements != null}
                    value={measurements.suitHips}
                    name="suitHips"
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.suitHips && (
                    <p className="error-text">{error?.suitHips[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Shoulder</label>
                <div>
                  <input
                    placeholder=" inch"
                    type="number"
                    step="0.01"
                    onChange={onChangeMeasurement}
                    disabled={existingMeasurements != null}
                    value={measurements.shoulder}
                    name="shoulder"
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.shoulder && (
                    <p className="error-text">{error?.shoulder[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Arm length</label>
                <div>
                  <input
                    placeholder=" inch"
                    type="number"
                    step="0.01"
                    onChange={onChangeMeasurement}
                    disabled={existingMeasurements != null}
                    name="armLength"
                    value={measurements.armLength}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.armLength && (
                    <p className="error-text">{error?.armLength[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Cross Back</label>
                <div>
                  <input
                    placeholder=" inch"
                    onChange={onChangeMeasurement}
                    disabled={existingMeasurements != null}
                    value={measurements.crossBack}
                    name="crossBack"
                    required
                    type="number"
                    step="0.01"
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.crossBack && (
                    <p className="error-text">{error?.crossBack[0]}</p>
                  )}
                </div>
              </div>

              <h1 className="h6 text-cente font-bold uppercase text-secondary col-span-full  mt-4">
                Trouser
              </h1>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Length</label>
                <div>
                  <input
                    placeholder=" inch"
                    disabled={existingMeasurements != null}
                    onChange={onChangeMeasurement}
                    name="pantLength"
                    value={measurements.pantLength}
                    required
                    type="number"
                    step="0.01"
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.pantLength && (
                    <p className="error-text">{error?.pantLength[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Waist</label>
                <div>
                  <input
                    placeholder=" inch"
                    disabled={existingMeasurements != null}
                    onChange={onChangeMeasurement}
                    name="waist"
                    type="number"
                    step="0.01"
                    value={measurements.waist}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.waist && (
                    <p className="error-text">{error.waist[0]}</p>
                  )}
                </div>
              </div>

              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Hips</label>
                <div>
                  <input
                    placeholder=" inch"
                    name="pantHips"
                    type="number"
                    step="0.01"
                    disabled={existingMeasurements != null}
                    onChange={onChangeMeasurement}
                    value={measurements.pantHips}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.pantHips && (
                    <p className="error-text">{error.pantHips[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Thighs</label>
                <div>
                  <input
                    placeholder=" inch"
                    onChange={onChangeMeasurement}
                    name="thighs"
                    disabled={existingMeasurements != null}
                    type="number"
                    step="0.01"
                    value={measurements.thighs}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.thighs && (
                    <p className="error-text">{error.thighs[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Inside leg</label>
                <div>
                  <input
                    placeholder=" inch"
                    onChange={onChangeMeasurement}
                    name="insideLeg"
                    disabled={existingMeasurements != null}
                    type="number"
                    step="0.01"
                    value={measurements.insideLeg}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.insideLeg && (
                    <p className="error-text">{error.insideLeg[0]}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 flex-co">
                <label className="text-primary font-medium">Bottom</label>
                <div>
                  <input
                    placeholder=" inch"
                    onChange={onChangeMeasurement}
                    name="bottom"
                    disabled={existingMeasurements != null}
                    type="number"
                    step="0.01"
                    value={measurements.bottom}
                    required
                    className="border px-3 py-2 mt-1 text-center focus:outline-none text-sm w-[60px] md:w-[80px] rounded-sm border-gray-400"
                  />
                  {error && error?.bottom && (
                    <p className="error-text">{error?.bottom[0]}</p>
                  )}
                </div>
              </div>
            </form>
          </div>
          {productId != null ? (
            <div className="py-8 flex w-full">
              <button
                onClick={onAddProductToCart}
                className="border hover:bg-secondary hover:text-white text-secondary font-bold text-lg py-2 rounded-sm w-full border-secondary"
              >
                Add to cart
              </button>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default Measurement;
