import React, { useState } from "react";
import Search from "../../../modules/atoms/search";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  createCategory,
  fetchCategory,
  removeCategory,
} from "../../../redux/features/category/CategoryActions";
import AddCategoryPopup from "../../../modules/overlays/AddCategoryPopup";
import { useEffect } from "react";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";
import { clearError } from "../../../redux/features/category/CategorySlice";
import WarningPopup from "../../../modules/overlays/warningPopup";

const CategoryList = () => {
  const { categories, isFetching, error } = useSelector(
    (state) => state.category
  );
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("all");
  const dispatch = useDispatch();
  const [showAddPopup, setAddShowPopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const onAdd = (newCategory) => {
    dispatch(
      createCategory({
        category: newCategory,
        onSuccess: () => {
          setAddShowPopup(false);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(fetchCategory({ type: "all" }));
  }, []);

  const tabHandler = (tabName) => {
    dispatch(fetchCategory({ type: tabName, searchKey: null }));
    setActiveTab(tabName);
  };

  const onSearch = () => {
    dispatch(fetchCategory({ type: "all", searchKey: search }));
    setActiveTab(null);
  };

  const onSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(categories?.map((category) => category.id));
    } else {
      setSelected([]);
    }
  };

  const onDelete = () => {
    dispatch(
      removeCategory({
        categoryIds: selected,
        onSuccess: () => {
          setSelected([]);
          dispatch(
            fetchCategory({
              type: activeTab,
              search: search !== "" ? search : null,
            })
          );
        },
      })
    );
  };

  const onClickDelete = () => {
    if (selected.length > 0) {
      setShowConfirmationPopup(true);
    } else {
      return;
    }
  };

  return (
    <div className="w-full h-full px-12">
      <ConfirmationPopup
        show={showConfirmationPopup}
        popupStyle="h-[200px]"
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={onDelete}
        title={`Are you sure you want to delete ${
          selected.length > 1 ? "these categories" : "this category"
        }? All products linked with 
        ${selected.length > 1 ? "these categories" : "this category"} 
        will be deleted.`}
      />
      <WarningPopup
        show={error}
        title={error?.message}
        onClose={() => dispatch(clearError(null))}
      />
      <AddCategoryPopup
        show={showAddPopup}
        onClose={() => setAddShowPopup(false)}
        popupType="category"
        onSubmit={onAdd}
      />
      <div className="flex mt-12 items-center justify-between">
        <h2 className="h6 font-bold">Categories</h2>
        <button
          onClick={() => setAddShowPopup(true)}
          className="text-white bg-primary h-full py-2 px-4 rounded-sm"
        >
          Add Category
        </button>
      </div>

      <div className="w-full mt-6 bg-white border border-solid pb-12 rounded-md border-slate-200">
        <div className="flex items-center py-8 px-4 border-b border-slate-200 ">
          <span
            className={`${
              activeTab === "all"
                ? "border-primary text-primary"
                : "border-slate-200"
            } py-[6px] rounded-md px-4 border cursor-pointer`}
            onClick={() => tabHandler("all")}
          >
            All
          </span>
          <span
            className={`${
              activeTab === "men"
                ? "border-primary text-primary"
                : "border-slate-200"
            } py-[6px] rounded-md px-4 ml-4 border cursor-pointer`}
            onClick={() => tabHandler("men")}
          >
            Men
          </span>
          <span
            className={`${
              activeTab === "women"
                ? "border-primary text-primary"
                : "border-slate-200"
            } py-[6px] mx-4 rounded-md px-4 border cursor-pointer`}
            onClick={() => tabHandler("women")}
          >
            Women
          </span>
          <Search
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onSearch={onSearch}
          />
        </div>

        {!isFetching && categories?.length === 0 ? (
          <div className="flex p-4 items-center justify-center">
            <h2 className="text-xl">No categories found</h2>
          </div>
        ) : isFetching ? (
          <div className="flex justify-center my-12">
            <LoadingIndicator />
          </div>
        ) : (
          <table className="w-full">
            <thead className="border-b border-solid border-b-slate-200">
              <tr className="text-black text-sm">
                <th className="text-left px-4">
                  <input
                    onChange={onSelectAll}
                    className="w-5 mt-2 cursor-pointer"
                    type="checkbox"
                  />
                </th>
                <th className="text-left py-4">Name</th>
                <th className="text-left">Type</th>
                <th className="text-left">Actions</th>
                <th className="text-left">
                  <span
                    onClick={onClickDelete}
                    className={`${
                      selected?.length > 0
                        ? "text-error cursor-pointer"
                        : "text-slate-400"
                    } flex items-center `}
                  >
                    Delete <MdDeleteOutline size={18} />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {categories?.map((category) => (
                <tr className="border-b border-solid border-b-slate-200">
                  <td className="py-2 px-4">
                    <input
                      checked={selected.includes(category.id)}
                      onChange={() => onSelect(category.id)}
                      className="w-5 mt-2 cursor-pointer"
                      type="checkbox"
                    />
                  </td>
                  <td>{category.name}</td>
                  <td>{category.type}</td>
                  <td>
                    <span
                      onClick={() => navigate(`${category.id}/subcategories`)}
                      className="text-primary cursor-pointer font-semibold"
                    >
                      View Subcategories
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
