import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  create,
  update,
  remove,
  getByCategory,
  getBySubcategory,
  removeImage,
  search,
  getAll,
} from "../../../api/Product";

export const createProduct = createAsyncThunk(
  "product/create",
  async ({ product, images, onSuccess }, thunkAPI) => {
    const formData = new FormData();
    Object.keys(product).map((key) => {
      if (product[key] !== null) {
        formData.append(key, product[key]);
      }
    });
    images.map((image) => formData.append("images[]", image));

    return await create(formData).then(async (res) => {
      if (res?.status && res?.data) {
        onSuccess();
        return {
          ...res.data.product,
          files: res.data.images,
        };
      } else {
        return thunkAPI.rejectWithValue(res?.data ?? res);
      }
    });
  }
);

export const removeProductImage = createAsyncThunk(
  "product/remove-image",
  async ({ imageId, productId }, thunkAPI) => {
    return await removeImage({ imageId: imageId, productId: productId }).then(
      (res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      }
    );
  }
);

export const updateProduct = createAsyncThunk(
  "product/update",
  async ({ product, images, onSuccess }, thunkAPI) => {
    const formData = new FormData();
    Object.keys(product).map((key) => {
      if (product[key] !== null) {
        formData.append(key, product[key]);
      }
    });
    images.map((image) => {
      if (!image.url) {
        formData.append("images[]", image);
      }
    });

    return await update(formData).then(async (res) => {
      if (res?.status && res?.data) {
        onSuccess();
        return {
          ...res.data.product,
          files: res.data?.images ?? null,
        };
      } else {
        return thunkAPI.rejectWithValue(res?.data ?? res);
      }
    });
  }
);

export const removeProduct = createAsyncThunk(
  "product/remove",
  async ({ productIds, onSuccess }, thunkAPI) => {
    return await remove({ productId: productIds })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        onSuccess();
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
export const fetchByCategory = createAsyncThunk(
  "product/category",
  async ({ body, params, type = null }, thunkAPI) => {
    return await getByCategory(body, params)
      .then((res) => {
        if (res?.status && res?.data) {
          return {
            products: res.data.products,
            type: type,
          };
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchBySubcategory = createAsyncThunk(
  "product/subcategory",
  async ({ body, params, type = null }, thunkAPI) => {
    return await getBySubcategory(body, params)
      .then((res) => {
        if (res?.status && res?.data) {
          return {
            products: res.data.products,
            type: type,
          };
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const searchProduct = createAsyncThunk(
  "product/search",
  async ({ body, params }, thunkAPI) => {
    return await search(body, params)
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const fetchAll = createAsyncThunk(
  "product/all",
  async (params = {}, thunkAPI) => {
    return await getAll(params)
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);
