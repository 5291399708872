import { createSlice } from "@reduxjs/toolkit";
import { fetchCountry, updateCountry } from "./CountryActions";

const initialState = {
  country: [],
  isFetching: false,
  error: null,
  selectedCountry: null,
};

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    selectCountry: (state, { payload }) => {
      state.selectedCountry = payload;
    },
  },
  extraReducers: {
    [fetchCountry.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchCountry.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.country = payload.countries;
    },
    [fetchCountry.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
    [updateCountry.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCountry.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      const index = state.country.findIndex(
        (country) => country.id === payload.country.id
      );
      state.countries[index] = payload.country;
    },
  },
});

export const { selectCountry } = countrySlice.actions;
