import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartProducts,
  removeCartProduct,
} from "../../../redux/features/cart/CartActions";
import { baseURL } from "../../../helpers";

const CartItem = ({ product }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { selectedCountry } = useSelector((state) => state.country);

  const onDeleteProduct = () => {
    dispatch(
      removeCartProduct({
        cartId: [product.pivot.id],
        userId: user.id,
        onSuccess: () => {
          dispatch(
            fetchCartProducts({
              userId: user.id,
              countryId: selectedCountry.id,
            })
          );
        },
      })
    );
  };

  return (
    <div className="flex py-6 border-b md:mx-12 mx-5 border-[#EBEBEB]">
      <div className="w-[120px] h-[108px] rounded-sm border border-salte-100 p-[4px]">
        <div className="w-[110px] rounded-sm bg-slate-300 2xl:h-[140px] lg:h-[130px] h-[130px]">
          <img
            className="w-full h-full object-cover object-top"
            src={`${baseURL}${product?.files[0]?.url}`}
            alt="product"
          />
        </div>
      </div>
      <div className="flex w-[85%] flex-col ml-6">
        <p className="text-lg font-bold">{[product?.title.slice(0, 60)]}</p>
        <p className="text-[16px] mt-1 font-semibold md:block hidden">
          {product.subTitle}
        </p>
        <div className="flex mt-4 items-center justify-between">
          <p className="text-primary font-bold">
            {`${selectedCountry.symbol.slice(0, 60)} ${parseInt(product.mrp)}`}
          </p>
          <span
            onClick={onDeleteProduct}
            className="text-[13px] cursor-pointer text-error"
          >
            Remove
          </span>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
