import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createPaymentOrder, capturePayment } from "../../api/Payment";
import { createOrder } from "../../redux/features/order/OrderActions";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";
import { toast } from "react-toastify";
import {
  fetchCartProducts,
  removeCartProduct,
} from "../../redux/features/cart/CartActions";
import { clearCart } from "../../redux/features/cart/CartSlice";
import { removeAppliedCoupon } from "../../redux/features/coupon/CouponSlice";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function BillingMeta() {
  const { appliedCoupon, subtotal } = useSelector((state) => state.coupon);
  const { cartProducts } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const { selectedCountry } = useSelector((state) => state.country);
  const { selectedAddress } = useSelector((state) => state.address);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productIds = useMemo(
    () =>
      cartProducts && cartProducts.length
        ? cartProducts?.map((product) => {
            const { userId, id, productId, ...productDetails } = product.pivot;
            return { id: product.pivot.productId, ...productDetails };
          })
        : [],
    [cartProducts]
  );

  const total = useMemo(
    () => cartProducts?.reduce((total, product) => total + product.mrp, 0),
    [cartProducts]
  );

  const cartProductIds = useMemo(
    () =>
      cartProducts && cartProducts.length
        ? cartProducts.map((product) => product.pivot.id)
        : [],
    [cartProducts]
  );

  useEffect(() => {
    dispatch(
      fetchCartProducts({ userId: user.id, countryId: selectedCountry.id })
    );
    if (total && selectedCountry?.id) {
      if (
        parseInt(total) < appliedCoupon?.minCartAmount ||
        selectedCountry?.currencyCode !== appliedCoupon?.currencyCode
      ) {
        dispatch(removeAppliedCoupon());
      }
    }
  }, [selectedCountry, total]);

  const createPayment = async () => {
    setLoading(true);
    await createPaymentOrder({
      amount: subtotal !== 0 ? parseInt(subtotal) : parseInt(total),
      currency: selectedCountry.currencyCode,
    })
      .then(async (res) => {
        if (res.status && res.data) {
          const options = {
            key: process.env.RAZORPAY_ID,
            name: "Sleek Studio",
            description: "pay",
            order_id: res.data.order.id,
            amount: res.data.amount,
            currency: res.data.order.currency,
            handler: async (paymentResponse) => {
              if (paymentResponse.status !== "failed") {
                const data = {
                  paymentId: paymentResponse.razorpay_payment_id,
                  orderId: paymentResponse.razorpay_order_id,
                  paymentSignature: paymentResponse.razorpay_signature,
                };
                await capturePayment(data)
                  .then((capturePaymentRes) => {
                    if (capturePaymentRes.status && capturePaymentRes.data) {
                      dispatch(
                        createOrder({
                          order: {
                            products: productIds,
                            userId: user?.id,
                            addressId: selectedAddress?.id,
                            couponId: appliedCoupon?.id ?? null,
                            paymentId: capturePaymentRes.data.payment.id,
                            totalAmount: capturePaymentRes.data.payment.amount,
                          },
                          onSuccess: () => {
                            toast.success(
                              "Your order has been received successfully",
                              {
                                position: "bottom-right",
                              }
                            );
                            dispatch(
                              removeCartProduct({
                                userId: user?.id,
                                cartId: cartProductIds,
                                onSuccess: () => {
                                  dispatch(clearCart());
                                },
                              })
                            );
                            dispatch(removeAppliedCoupon());
                            navigate("/profile/orders");
                          },
                        })
                      );
                      setLoading(false);
                    } else {
                      console.log(
                        "capture payment error res",
                        capturePaymentRes
                      );
                    }
                  })
                  .catch((error) =>
                    console.log("capture payment error res", error)
                  )
                  .finally(() => setLoading(false));
              }
            },
            theme: {
              color: "#476f66",
            },
            prefill: {
              name: `${user.name} ${user.lastName ? user.lastName : ""}`,
              email: user.email,
              contact: user.phoneNumber,
            },
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        } else {
          console.log("create payment order error res", res);
        }
      })
      .catch((error) => console.log("create payment order error res", error))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {appliedCoupon && (
        <div className="contain px-10 mt-6 md:mb-12">
          <div className="w-full flex items-center rounded-md py-4 px-4 bg-[#F8F8F8]">
            <BsCheckCircle color="#008000" size={28} />
            <div className="ml-4">
              <p className="font-semibold">
                <span className="font-bold">{appliedCoupon?.code} </span>{" "}
                applied
              </p>
              <p className=" text-slate-400">{appliedCoupon?.description}</p>
            </div>
          </div>
        </div>
      )}
      <div className="contain mb-10 flex  justify-center lg:justify-end md:mb-20">
        <div className="md:px-10 md:max-w-md w-full ">
          <div className="flex items-center justify-between ">
            <p className="text-primary font-bold text-lg">ORDER TOTAL</p>
            <p className="text-primary font-bold text-lg">
              {`${selectedCountry.symbol}${parseInt(total)}`}
            </p>
          </div>
          {appliedCoupon && (
            <div className="flex mt-2 items-center justify-between">
              <p className="text-primary font-bold text-lg">DISCOUNT</p>
              <p className="text-primary font-bold text-lg">
                {`- ${selectedCountry.symbol}${parseInt(
                  appliedCoupon?.amount && appliedCoupon?.amount !== 0
                    ? appliedCoupon?.amount
                    : total * (appliedCoupon?.percentage / 100)
                )}`}
              </p>
            </div>
          )}
          <div className="flex mt-2 items-center justify-between">
            <p className="text-primary font-bold text-lg">SUB TOTAL</p>
            <p className="text-primary font-bold text-lg">
              {`${selectedCountry.symbol}${
                subtotal !== 0 ? parseInt(subtotal) : parseInt(total)
              }`}
            </p>
          </div>
          <button
            type="submit"
            onClick={createPayment}
            className="btn-secondary rounded-md font-bold w-full text-lg  mt-8 uppercase"
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <LoadingIndicator
                  indicatorStyle={{
                    width: "30px",
                    height: "30px",
                    borderTop: "5px solid #dab188",
                  }}
                />
              </div>
            ) : (
              "Proceed To Pay"
            )}
          </button>
        </div>
      </div>
    </>
  );
}
