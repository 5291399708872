import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export default function MasterLink({ title, className, link }) {
  return (
    <>
      <Link to={link} className={`${className} master-link`}>
        {title}
      </Link>
    </>
  );
}
