import React, { useState, useEffect, useRef } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";

const Dropdown = ({
  placeholder,
  containerStyle,
  label,
  dropdownStyle,
  dropdownInputStyle,
  data,
  emptyListText,
  onSelect,
  selected,
  labelStyle,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const onClickItem = (selectedItem) => {
    onSelect(selectedItem);
    setShowDropdown(false);
  };

  const dropdownContainerRef = useRef();

  const onClose = (e) => {
    if (
      dropdownContainerRef &&
      !dropdownContainerRef.current?.contains(e.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClose);
  }, []);

  return (
    <div ref={dropdownContainerRef} className={`relative  ${containerStyle}`}>
      {label && (
        <label
          className={`${labelStyle ? labelStyle : "text-sm text-slate-500"}`}
        >
          {label}
        </label>
      )}
      <div
        className={`${
          dropdownInputStyle ??
          "w-[200px] cursor-pointer py-1 px-2 border rounded-sm mt-1 border-slate-300"
        } relative`}
      >
        <span
          onClick={() => setShowDropdown(!showDropdown)}
          className="flex cursor-pointer items-center justify-between text-slate-500"
        >
          <p className="text-md">
            {selected ? selected.name.slice(0, 28) : placeholder}
          </p>
          <span
            className={`transition-transform rotate-180 duration-200 ${
              showDropdown && "rotate-0"
            }`}
          >
            <MdKeyboardArrowUp size={26} />
          </span>
        </span>
      </div>
      {showDropdown && (
        <div
          className={`bg-white border z-10 border-slate-200 overflow-auto absolute ${
            dropdownStyle ?? "top-full left-0 w-full h-fit"
          }`}
        >
          {data?.length > 0 ? (
            data?.map((item, index) => (
              <span key={item.id} onClick={() => onClickItem(item)}>
                <p
                  className={`text-sm text-slate-500 leading-8 hover:bg-slate-100 p-1 px-2 cursor-pointer 
                ${
                  index === data.length - 1 ? "border-none" : "border-b"
                } borer-b-slate-100`}
                >
                  {item.name.slice(0, 28)}
                </p>
              </span>
            ))
          ) : (
            <div className="flex h-[100%] items-center justify-center leading-8 px-2 py-1">
              <p className="text-sm">{emptyListText}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
