import React from "react";
import "./support.css";
import { MdAdd, MdMinimize } from "react-icons/md";
import { useState } from "react";
import { useEffect } from "react";

const Support = () => {
  const [selected, setSelected] = useState(null);
  const questions = [
    {
      title: "Consult our experts",
      answer:
        "In case of any queries or thoughts, we provide complimentary one-on-one sessions with our in-house stylist",
    },
    {
      title: "Wedding theme preparation",
      answer:
        "The people who make your special day extra special are the ones that stand by you. Therefore, Sleek studio designs wedding themes for groomsmen and bridesmaids to create a wholesome experience",
    },

    {
      title: "Alterations on the house",
      answer:
        "Customization is our forte. So, when it comes to altering your perfect couture, Sleek studio does it beautifully and free of charge",
    },
    {
      title: "Doorstep delivery ",
      answer:
        "Every couture is delivered with utmost safety and care. In any case, there are very minimal delivery charges. And if your order above Rs.5000 needs to be delivered in any part of India, we will deliver it for free.",
    },
  ];
  const toggle = (index) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="contain md:mb-40 mb-20">
        <h1 className="h2 pt-10 text-primary font-manrope text-center">
          Support
        </h1>
        <div className="support-wrapper ">
          {questions.map((question, index) => (
            <div className="items" key={index}>
              <div
                className="title cursor-pointer"
                onClick={() => {
                  toggle(index);
                }}
              >
                <h3 className="h5 text-[#476F66] font-manrope">
                  {question.title}
                </h3>
                <span>
                  {selected === index ? (
                    <MdMinimize size={26} className="text-[#0e2923]" />
                  ) : (
                    <MdAdd size={26} className="text-[#476F66]" />
                  )}
                </span>
              </div>
              <div
                className={
                  selected === index ? "open-up h-fit" : "h-0 overflow-hidden"
                }
              >
                <p className="h6 mt-4 text-primary leading-relaxed font-manrope transition-all duration-500">
                  {question.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Support;
