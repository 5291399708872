import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";

const WarningPopup = ({ title, show, onClose }) => {
  return (
    <>
      {show && (
        <div className="h-full fixed top-0 left-0 z-[9999] w-full bg-[#00000094] flex items-center justify-center">
          <div className="w-[420px] rounded-sm z-10 bg-white px-4">
            <span className="text-slate-400 justify-center items-center pt-4 flex">
              <RiErrorWarningLine size={45} />
            </span>
            <h6 className="mt-4 text-[16px] text-slate-700 font-[600]">
              {title}
            </h6>
            <div className="flex items-end justify-center p-6 mt-8">
              <button
                onClick={() => onClose()}
                className="border-none text-primary text-[16px]"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WarningPopup;
