import {
  login as loginAPI,
  logout as logoutAPI,
  loginWithOTP as loginWithOTPAPI,
  getProfile,
  update,
} from "../../../api/Admin";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const login = createAsyncThunk(
  "admin/login",
  async ({ admin, onSuccess }, thunkAPI) => {
    return await loginAPI(admin)
      .then((res) => {
        if (res?.status && res?.data) {
          sessionStorage.setItem("sleek_studio_admin_token", res.data.token);
          onSuccess(res.data);
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ? res.data : res);
      })
      .catch((error) => error);
  }
);

export const loginWithOTP = createAsyncThunk(
  "admin/login-with-otp",
  async ({ admin, onSuccess }, thunkAPI) => {
    return await loginWithOTPAPI(admin)
      .then((res) => {
        if (res?.status && res?.data) {
          sessionStorage.setItem("sleek_studio_admin_token", res.data.token);
          onSuccess(res.data);
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const logout = createAsyncThunk(
  "admin/logout",
  async ({ userId, tokenId, onSuccess }, thunkAPI) => {
    return await logoutAPI({ userId, tokenId })
      .then((res) => {
        if (res?.data && res?.status) {
          sessionStorage.removeItem("sleek_studio_admin_token");
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchAdmin = createAsyncThunk(
  "admin/get",
  async ({ userId, onSuccess }, thunkAPI) => {
    return await getProfile({ userId: userId })
      .then((res) => {
        if (res?.data && res?.status) {
          onSuccess();
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/update",
  async ({ user, onSuccess }, thunkAPI) => {
    return await update(user)
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);
