import { createSlice } from "@reduxjs/toolkit";
import {
  createCategory,
  updateCategory,
  removeCategory,
  removeSubcategory,
  fetchCategory,
  fetchSubcategory,
} from "./CategoryActions";

const initialState = {
  categories: [],
  menCategories: [],
  womenCategories: [],
  isFetching: false,
  error: null,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    clearError: (state, { payload }) => {
      if (state.error?.message || payload === null) {
        state.error = null;
      } else {
        state.error = { ...state.error, ...payload };
      }
    },
  },
  extraReducers: {
    //create category reducers
    [createCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [createCategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.categories = [...state.categories, payload.category];
    },
    [createCategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    // update category reducers
    [updateCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      const index = state.categories.findIndex(
        (category) => payload.subcategory.categoryId === category.id
      );
      state.categories[index].subcategories.push(payload.subcategory);
    },
    [updateCategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    // remove category reducers
    [removeCategory.pending]: (state) => {
      state.error = null;
    },
    [removeCategory.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    // get category reducers
    [fetchCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchCategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.categories = payload.categories;
      if (payload.type === "men") {
        state.menCategories = payload.categories;
      }
      if (payload.type === "women") {
        state.womenCategories = payload.categories;
      }
    },
    [fetchCategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },

    // remove subcategory reducers
    [removeSubcategory.pending]: (state) => {
      state.error = null;
    },
    [removeSubcategory.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    //get subcategory reducers
    [fetchSubcategory.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchSubcategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      const index = state.categories.findIndex(
        (category) => category.id === payload.categoryId
      );
      state.categories[index].subcategories = payload.subcategories;
    },
    [fetchSubcategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { clearError } = categorySlice.actions;
