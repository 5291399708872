import React, { useEffect, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import {
  NavLinNavLink,
  k,
  Outlet,
  useLocation,
  useNavigate,
  NavLink,
} from "react-router-dom";
import "./style.css";
import { logout } from "../../redux/features/users/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import ConfirmationPopup from "../../modules/overlays/ConfirmationPopup";
import { clearAuthOrders } from "../../redux/features/order/OrderSlice";
import { clearAddresses } from "../../redux/features/address/AddressSlice";
import { clearCart } from "../../redux/features/cart/CartSlice";
import { clearWishlist } from "../../redux/features/wishlist/WishlistSlice";

export default function MyAccount() {
  const [isOpen, setIsOpen] = useState();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies();

  const [profileDetails, setProfileDetails] = useState(false);
  const { user: loggedInUser } = useSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setProfileDetails(location.state.profileDetails);
    }
  }, []);

  const handleMenu = () => {
    if (isOpen) setIsOpen(false);
    else setIsOpen(true);
  };

  const onLogout = () => {
    dispatch(
      logout({
        userId: cookie["sleek_studio_user_id"],
        tokenId: cookie["sleek_studio_user_token_id"],
        onSuccess: () => {
          sessionStorage.removeItem("sleek_studio_user_token");
          removeCookie("sleek_studio_user_id", { path: "/" });
          removeCookie("sleek_studio_user_token_id", { path: "/" });
          removeCookie("sleek_studio_user_token", { path: "/" });
          dispatch(clearAuthOrders());
          dispatch(clearAddresses());
          dispatch(clearCart());
          dispatch(clearWishlist());
        },
      })
    );
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="my-account-wrapper mt-[80px]">
      <ConfirmationPopup
        title="Are you sure, you want to log out?"
        onClose={() => setShowPopup(false)}
        onConfirm={onLogout}
        show={showPopup}
        confirmButtonText="Logout"
      />

      <div className="contain flex relative min-h-[calc(100vh-80px)] ">
        <BiUserCircle
          className={`${
            isOpen ? "" : ""
          } burger-icon absolute top-0 right-4 text-primary`}
          onClick={handleMenu}
        />
        <div
          className={`${
            isOpen
              ? "md:opacity-100  translate-x-0"
              : "md:opacity-100 opacity-0 md:translate-x-0 -translate-x-[100%]"
          } 2xl:w-[25%] min-w-[250px] z-50 transition-all duration-300 min-h-[calc(100vh-100px)] md:static absolute top-0 left-0 bg-primary overflow-hidden`}
        >
          <div className="md:pt-20 pt-10 md:p-0 p-5 ">
            <h4 className="text-xl text-white pr-4 ">My Account</h4>
            <h4 className="h4 text-secondary capitalize pr-10 mt-2 leading-tight">
              {loggedInUser.name} {loggedInUser.lastName}
            </h4>
            <div className="h-full profile-tab-wrapper">
              <div className="mt-10 tabs flex flex-col">
                <NavLink
                  className={`${(isActive) => (isActive ? "active" : "")} tabs`}
                  to="/profile"
                  end
                  onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
                >
                  Personal Details
                </NavLink>
                <NavLink
                  className={`${(isActive) => (isActive ? "active" : "")} tabs`}
                  to="/profile/address"
                  onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
                >
                  Address
                </NavLink>
                <NavLink
                  className={`${(isActive) => (isActive ? "active" : "")} tabs`}
                  to="/profile/orders"
                  onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
                >
                  My Orders
                </NavLink>
                <NavLink
                  className={`${(isActive) => (isActive ? "active" : "")} tabs`}
                  to="/profile/change-password"
                  onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
                >
                  Change Password
                </NavLink>
                <NavLink
                  className={`${(isActive) => (isActive ? "active" : "")} tabs`}
                  to="/profile/reset-email"
                  onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
                >
                  Reset Email
                </NavLink>
                <button onClick={() => setShowPopup(true)} className="tabs ">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-[75%] w-full md:pl-10 ">
          <Outlet />
        </div>
      </div>
    </section>
  );
}
