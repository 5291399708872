import React from "react";

const Cart = ({ color, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.349"
      height="24.163"
      viewBox="0 0 25.349 24.163"
      fill="none"
      className={className}
    >
      <g
        id="shopping-cart_1_"
        data-name="shopping-cart (1)"
        transform="translate(0 -10.67)"
      >
        <g
          id="Group_4771"
          data-name="Group 4771"
          transform="translate(16.251 28.913)"
        >
          <g id="Group_4770" data-name="Group 4770">
            <path
              id="Path_3271"
              data-name="Path 3271"
              d="M295.312,338.862a2.96,2.96,0,1,0,2.96,2.96A3,3,0,0,0,295.312,338.862Z"
              transform="translate(-292.352 -338.862)"
            />
          </g>
        </g>
        <g
          id="Group_4773"
          data-name="Group 4773"
          transform="translate(0 10.67)"
        >
          <g id="Group_4772" data-name="Group 4772">
            <path
              id="Path_3272"
              data-name="Path 3272"
              d="M24.419,14.8c-.057,0-.142-.028-.228-.028H6.261l-.285-1.907A2.549,2.549,0,0,0,3.444,10.67H1.138a1.138,1.138,0,1,0,0,2.277H3.444a.3.3,0,0,1,.285.256l1.765,12.01A3.12,3.12,0,0,0,8.567,27.86h11.84a3.163,3.163,0,0,0,3.074-2.5l1.85-9.25A1.125,1.125,0,0,0,24.419,14.8Z"
              transform="translate(0 -10.67)"
            />
          </g>
        </g>
        <g
          id="Group_4775"
          data-name="Group 4775"
          transform="translate(6.174 28.913)"
        >
          <g id="Group_4774" data-name="Group 4774">
            <path
              id="Path_3273"
              data-name="Path 3273"
              d="M116.85,341.68a2.943,2.943,0,0,0-2.931-2.818,2.977,2.977,0,0,0-2.846,3.074,2.917,2.917,0,0,0,2.9,2.818h.057A2.954,2.954,0,0,0,116.85,341.68Z"
              transform="translate(-111.07 -338.862)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Cart;
