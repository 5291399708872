import React, { useEffect } from "react";
import { ProductList } from "../../modules/core";
import InfiniteCarousel from "../../modules/core/infiniteCarousel";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategory } from "../../redux/features/category/CategoryActions";

const MenProductCollections = ({}) => {
  const dispatch = useDispatch();
  const { menProducts } = useSelector((state) => state.product);
  const { menCategories } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(fetchCategory({ type: "men" }));
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <ProductList
          type="men"
          products={menProducts}
          categories={menCategories}
        />
        <InfiniteCarousel title="GLIMPSES FROM SOCIAL MEDIA" />
      </div>
    </>
  );
};

export default MenProductCollections;
