import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  create,
  update,
  remove,
  get,
  getsubcategories,
  removeSubcategories,
} from "../../../api/category";

export const createCategory = createAsyncThunk(
  "category/create",
  async ({ category, onSuccess }, thunkAPI) => {
    return await create(category)
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const updateCategory = createAsyncThunk(
  "category/update",
  async ({ categoryId, subcategory, onSuccess }, thunkAPI) => {
    return await update({ categoryId, subcategory })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchCategory = createAsyncThunk(
  "category/get",
  async ({ type, searchKey }, thunkAPI) => {
    return await get({
      body: { type: type },
      params: searchKey ? { search: searchKey } : {},
    })
      .then((res) => {
        if (res?.status && res?.data) {
          return {
            categories: res.data.categories,
            type: type,
          };
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const removeCategory = createAsyncThunk(
  "category/remove",
  async ({ categoryIds, onSuccess }, thunkAPI) => {
    return await remove({ categoryId: categoryIds })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        onSuccess();
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchSubcategory = createAsyncThunk(
  "category/get-subcategory",
  async (categoryId, thunkAPI) => {
    return await getsubcategories({ categoryId })
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const removeSubcategory = createAsyncThunk(
  "category/remove-subcategory",
  async ({ subcategoryIds, onSuccess }, thunkAPI) => {
    return await removeSubcategories({ subcategoryId: subcategoryIds })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        onSuccess();
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
