export const paymentMethods = {
  phonepe: "Phone Pe",
  amazonpay: "Amazon Pay",
  debit: "Debit",
  credit: "Credit",
  paypal: "Pay Pal",
  netbanking: "Net Banking",
  upi: "UPI",
  bank_transfer: "Bank Transfer",
  emi: "EMI",
  wallet: "Wallet",
};

export const orderStatus = {
  out_for_delivery: "Out For Delivery",
  picked_up: "Picked Up",
  in_transit: "In Transit",
  cancelled: "Cancelled",
  received: "Received",
  delivered: "Delivered",
};

export const paymentStatus = {
  authorized: "Authorized",
  captured: "Captured",
};
