import {
  register as registerAPI,
  login as loginAPI,
  logout as logoutAPI,
  update,
  getProfile,
  getAllUsers,
  loginWithOTP as loginWithOTPAPI,
} from "../../../api/User";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const register = createAsyncThunk(
  "auth/register",
  async ({ user, onSuccess }, thunkAPI) => {
    return await registerAPI(user)
      .then(async (res) => {
        if (res?.status && res?.data) {
          sessionStorage.setItem("sleek_studio_user_token", res.data.token);
          onSuccess(res.data);
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ user, onSuccess }, thunkAPI) => {
    return await loginAPI(user)
      .then((res) => {
        if (res?.status && res?.data) {
          sessionStorage.setItem("sleek_studio_user_token", res.data.token);
          onSuccess(res.data);
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ? res.data : res);
      })
      .catch((error) => error);
  }
);

export const loginWithOTP = createAsyncThunk(
  "auth/login-with-otp",
  async ({ user, onSuccess }, thunkAPI) => {
    return await loginWithOTPAPI(user)
      .then((res) => {
        if (res?.status && res?.data) {
          sessionStorage.setItem("sleek_studio_user_token", res.data.token);
          onSuccess(res.data);
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async ({ userId, tokenId, onSuccess }, thunkAPI) => {
    return await logoutAPI({ userId, tokenId })
      .then((res) => {
        if (res?.data && res?.status) {
          sessionStorage.removeItem("sleek_studio_user_token");
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const updateUser = createAsyncThunk(
  "auth/update",
  async ({ user, onSuccess }, thunkAPI) => {
    return await update(user)
      .then(async (res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchUser = createAsyncThunk(
  "auth/profile",
  async ({ userId, onSuccess }, thunkAPI) => {
    return await getProfile({ userId: userId })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);

export const fetchAllUsers = createAsyncThunk(
  "users",
  async (params, thunkAPI) => {
    return await getAllUsers(params)
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
