import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  fetchCoupons,
  removeCoupon,
  updateCoupon,
} from "../../../redux/features/coupon/CouponActions";
import { useSelector, useDispatch } from "react-redux";
import { MdDeleteOutline } from "react-icons/md";
import ToggleButton from "../../../modules/atoms/toggleButton";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";
import moment from "moment";
import { clearError } from "../../../redux/features/coupon/CouponSlice";
import WarningPopups from "../../../modules/overlays/warningPopup";

const widthSize = window.innerWidth;

const CouponList = () => {
  const { coupons, error } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    dispatch(fetchCoupons());
  }, []);

  const onSelectCoupon = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((couponId) => couponId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(coupons.map((coupon) => coupon.id));
    } else {
      setSelected([]);
    }
  };

  const onDelete = () => {
    dispatch(
      removeCoupon({
        couponId: selected,
        onSuccess: () => {
          dispatch(fetchCoupons());
          setSelected([]);
        },
      })
    );
  };

  const changeStatus = (statusData) => {
    dispatch(updateCoupon(statusData));
  };

  const onClickDelete = () => {
    if (selected.length > 0) {
      setOpenPopup(true);
    } else {
      return;
    }
  };

  return (
    <>
      <ConfirmationPopup
        show={openPopup}
        confirmButtonText="Delete"
        title={
          selected > 1
            ? "Are you sure you want delete these coupons?"
            : "Are you sure you want to delete this coupon?"
        }
        onClose={() => setOpenPopup(false)}
        onConfirm={onDelete}
      />
      <WarningPopups
        show={error}
        title={error?.message}
        onClose={() => dispatch(clearError(null))}
      />
      <div className="w-full h-full px-12">
        <div className="flex mt-12 items-center justify-between">
          <p className="font-bold text-xl">Coupons</p>
          <Link
            className="text-white bg-primary h-full py-2 px-4 rounded-sm"
            to="add"
          >
            Add Coupon
          </Link>
        </div>
        {coupons?.length ? (
          <div className="bg-white mt-6 border py-8 border-solid rounded-md border-slate-200">
            <>
              <table className="w-full">
                <thead>
                  <tr className="text-black text-sm">
                    <th className="px-4">
                      <input
                        className="w-5 checked:bg-primary cursor-pointer"
                        onChange={onSelectAll}
                        type="checkbox"
                      />
                    </th>
                    <th className="text-left py-4">Code</th>
                    <th className="text-left ml-4">Description</th>
                    <th className="text-center">Currency</th>
                    <th className="text-center">Type</th>
                    <th className="text-center">Amount</th>
                    <th className="text-center">Percentage</th>
                    <th className="text-center">Expiry Date Time</th>
                    <th className="text-left">Status</th>
                    <th className="text-left">
                      <span
                        onClick={onClickDelete}
                        className={`${
                          selected?.length > 0
                            ? "cursor-pointer text-error"
                            : "text-slate-400"
                        } flex items-center`}
                      >
                        {`${widthSize >= 1156 ? "Delete" : ""}`}{" "}
                        <MdDeleteOutline size={18} />
                      </span>
                    </th>
                  </tr>
                </thead>
                {coupons.map((coupon) => (
                  <tr
                    key={coupon.id}
                    className="border-t  border-solid border-b-slate-200"
                  >
                    <td className="py-2 px-4">
                      <input
                        checked={selected.includes(coupon.id)}
                        onChange={() => onSelectCoupon(coupon.id)}
                        className="w-[28px] cursor-pointer"
                        type="checkbox"
                      />
                    </td>
                    <td className="py-6">{coupon.code}</td>
                    <td className="ml-4">
                      <p
                        className={`mt-2 ${
                          widthSize <= 1280 ? "w-[200px] ml-2" : "w-[250px]"
                        }`}
                      >
                        {coupon.description}
                      </p>
                      <p className="text-slate-400 text-sm">
                        on min purchase:{" "}
                        <span className="font-semibold text-black text-sm">
                          {coupon.minCartAmount}
                        </span>
                      </p>
                    </td>
                    <td className="text-center">{coupon.currencyCode}</td>

                    <td className="text-center">{coupon.type}</td>
                    <td className="text-center">{coupon.amount}</td>
                    <td className="text-center">{coupon.percentage}</td>
                    <td className="text-center">
                      <p>
                        {moment(coupon.expiryDateTime).format("DD-MM-YYYY")}
                      </p>
                      <p>{moment(coupon.expiryDateTime).format("hh:mm A")}</p>
                    </td>
                    <td>
                      <ToggleButton
                        toggled={coupon.enabled}
                        onClick={(isToggled) =>
                          changeStatus({
                            isEnabled: isToggled,
                            couponId: coupon.id,
                          })
                        }
                      />
                    </td>
                  </tr>
                ))}
              </table>
            </>
          </div>
        ) : (
          <div className="flex mt-12 items-center justify-center">
            <h2 className="text-xl">No Coupons Found</h2>
          </div>
        )}
      </div>
    </>
  );
};

export default CouponList;
