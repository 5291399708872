import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fashion } from "../../modules/core";
import "./login.css";
import { login, loginWithOTP } from "../../redux/features/users/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { verifyOTP, generateOTP } from "../../api/User";
import OTPPopup from "../../modules/overlays/otpPopup";
import { clearError, setError } from "../../redux/features/users/userSlice";
import { useCookies } from "react-cookie";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";

const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
    role: "customer",
  });
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();
  const {
    error,
    user: loggedInUser,
    isFetching,
  } = useSelector((state) => state.auth);
  const location = useLocation();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  const handleChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    if (error) {
      dispatch(clearError({ [e.target.name]: null }));
    }

    setUser({
      ...user,
      [name]: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      login({
        user,
        onSuccess: (data) => {
          setCookie("sleek_studio_user_id", data.user.id, {
            maxAge: 2678400,
            path: "/",
          });
          setCookie("sleek_studio_user_token", data.token, {
            maxAge: 2678400,
            path: "/",
          });
          setCookie("sleek_studio_user_token_id", data.tokenId, {
            maxAge: 2678400,
            path: "/",
          });
        },
      })
    );
  };

  const onSendOTP = async () => {
    setLoading(true);
    await generateOTP({
      email: user.email,
      type: "verifyEmail",
      role: "customer",
    })
      .then((res) => {
        if (res.status && res.data) {
          setOpenPopup(true);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const onVerifyOTP = async (otp) => {
    dispatch(clearError(null));
    await verifyOTP({ email: user.email, type: "verifyEmail", otp }).then(
      (res) => {
        if (res.status && res.data) {
          dispatch(
            loginWithOTP({
              user: user,
              onSuccess: (data) => {
                setCookie("sleek_studio_user_id", data.user.id, {
                  maxAge: 2678400,
                  path: "/",
                });
                setCookie("sleek_studio_user_token", data.token, {
                  maxAge: 2678400,
                  path: "/",
                });
                setCookie("sleek_studio_user_token_id", data.tokenId, {
                  maxAge: 2678400,
                  path: "/",
                });
              },
            })
          );
        } else {
          dispatch(setError(res));
        }
      }
    );
  };

  const onClosePopup = () => {
    dispatch(clearError(null));
    setOpenPopup(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearError(null));
  }, []);

  useEffect(() => {
    if (
      cookies["sleek_studio_user_id"] &&
      cookies["sleek_studio_user_token_id"] &&
      cookies["sleek_studio_user_token"] &&
      loggedInUser.email_verified_at &&
      location.pathname === "/login"
    ) {
      navigate("/");
    }
  }, [cookies, loggedInUser]);

  return (
    <div className="grid place-items-center h-screen">
      <OTPPopup
        data={user.email}
        onClose={onClosePopup}
        onVerify={onVerifyOTP}
        onResend={onSendOTP}
        show={openPopup}
      />
      <section className="md:px-10 px-5 max-w-screen-2xl w-full mx-auto ">
        <div className="login-wrapper w-full md:min-h-[45rem] md:h-screen h-fit flex lg:flex-row flex-col justify-center">
          <div className="lg:w-[68%] w-full">
            <Fashion />
          </div>
          <div className="lg:w-[38%] w-full max-w-screen-sm mx-auto md:p-10 lg:mt-0 mt-12 pb-20">
            <form>
              <input
                value={user.email}
                onChange={handleChange}
                name="email"
                type="email"
                placeholder="Email"
                className="input-style mt-6"
              />
              {error && error?.email && (
                <p className="error-text ">{error.email[0]}</p>
              )}
              <div className="relative">
                <input
                  value={user.password}
                  onChange={handleChange}
                  name="password"
                  type={passwordType}
                  placeholder="Password"
                  className="input-style mt-6"
                />
                <button
                  onClick={() => {
                    passwordType === "password"
                      ? setPasswordType("text")
                      : setPasswordType("password");
                  }}
                  type="button"
                  className="absolute p-2 bottom-0 right-0 md:h-12 h-8 rounded focus:outline-primary"
                >
                  <span
                    className={`${
                      passwordType === "password" ? "block" : "hidden"
                    } h-5 md:h-8 w-[2.4px] inset-0 m-auto bg-primary absolute rotate-45 `}
                  ></span>
                  <svg
                    className="h-4 md:h-6 fill-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                  </svg>
                </button>
              </div>
              {error && error?.password && (
                <p className="error-text ">{error.password[0]}</p>
              )}

              {error && error?.message && !openPopup && (
                <p className="error-text ">{error.message}</p>
              )}

              <button
                onClick={handleSubmit}
                className="btn-secondary-fill w-full md:py-3 py-2 rounded mt-6 text-lg md:text-xl"
              >
                {isFetching ? (
                  <div className="flex items-center justify-center">
                    <LoadingIndicator
                      indicatorStyle={{
                        width: "30px",
                        height: "30px",
                        borderTop: "5px solid #dab188",
                      }}
                    />
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </form>

            <div className="bg-gray-200 relative h-[1px] my-10">
              <span className="w-8 h-8 bg-white mx-auto absolute text-lg md:text-xl -top-[14px] right-0 left-0 text-center">
                OR
              </span>
            </div>
            <button
              onClick={onSendOTP}
              className="btn-secondary w-full  rounded text-lg md:text-xl"
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <LoadingIndicator
                    indicatorStyle={{
                      width: "30px",
                      height: "30px",
                      borderTop: "5px solid #dab188",
                    }}
                  />
                </div>
              ) : (
                "Request OTP"
              )}
            </button>
            <div className="flex justify-end mt-6 ">
              <p className="text-[18px] text-primary font-manrope">
                Don't have an account?{" "}
              </p>
              <Link
                to="/signup"
                className="text-[18px] text-secondary px-1 font-manrope"
              >
                {" "}
                Signup
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
