import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const add = (body) => api.post("wishlist/add", body);

export const remove = (body) => api.delete("wishlist/remove", body);

export const get = (body, params) =>
  api.post("wishlist/products", body, params);
