import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, update } from "../../../api/Country";

export const fetchCountry = createAsyncThunk("country", async (_, thunkAPI) => {
  return await get()
    .then((res) => {
      if (res?.status && res?.data) {
        return res?.data;
      }
      return thunkAPI.rejectWithValue(res?.data ?? res);
    })
    .catch((error) => error);
});

export const updateCountry = createAsyncThunk(
  "country/update",
  async (country, thunkAPI) => {
    return await update(country)
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
