import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const navLinks = [
  { name: "Products", path: "products", id: 1 },
  { name: "Categories", path: "categories", id: 2 },
  { name: "Orders", path: "orders", id: 3 },
  { name: "Users", path: "users", id: 4 },
  { name: "Coupons", path: "coupons", id: 5 },
  { name: "Contact Mails", path: "contact-mails", id: 6 },
  { name: "Profile", path: "profile", id: 7 },
];

const width = window.innerWidth;

const Hamburger = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <span onClick={() => setShow(!show)} className="px-4 py-4">
        <span
          className={`w-[22px] h-[2px] bg-slate-500 
        transition-transform duration-400 block rounded-sm ${
          show && "skew-y-[36deg] translate-y-[4px]"
        }`}
        ></span>
        <span
          className={`w-[22px] h-[2px] bg-slate-500 transition-transform duration-400 block mt-1 rounded-sm ${
            show && "skew-y-[-36deg] translate-y-[-2px]"
          }`}
        ></span>
      </span>
      <div
        className={`w-full absolute top-[65px] transition-transform 
        duration-500 shadow-md bg-white`}
        style={
          show
            ? { transform: "translateX(0px)" }
            : { transform: `translateX(${width + "px"})` }
        }
      >
        <ul className="list-none w-[65%] mx-auto">
          {navLinks.map((link) => (
            <NavLink to={link.path} key={link.id}>
              {({ isActive }) => (
                <li
                  className={`text-slate-600 py-2 my-4 rounded-sm font-medium mx-2 text-center ${
                    isActive ? "bg-slate-100" : ""
                  }`}
                >
                  {link.name}
                </li>
              )}
            </NavLink>
          ))}
        </ul>
      </div>
    </>
  );
};

const Navbar = () => {
  return (
    <div className="w-full flex justify-between h-16 shadow-md bg-white pt-4 md:w-[20%] md:h-full md:flex-col md:justify-start">
      <h1 className="h5 ml-9">Sleek Studio</h1>

      {width > 768 ? (
        <div className="md:mt-8 flex flex-col">
          <ul className="list-none">
            {navLinks.map((link) => (
              <NavLink to={link.path} key={link.id}>
                {({ isActive }) => (
                  <li
                    className={`text-slate-600 py-2 my-4 rounded-sm font-medium mx-2 px-8 ${
                      isActive ? "bg-slate-100" : ""
                    }`}
                  >
                    {link.name}
                  </li>
                )}
              </NavLink>
            ))}
          </ul>
        </div>
      ) : (
        <Hamburger />
      )}
    </div>
  );
};

export default Navbar;
