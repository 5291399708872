import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAddress } from "../../../redux/features/address/AddressActions";
import { clearError } from "../../../redux/features/address/AddressSlice";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";

const initialState = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  postalCode: "",
  state: "",
  country: null,
  type: "",
};

const AddAddress = ({ setAddAddress }) => {
  const [address, setAddress] = useState(initialState);
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.country);
  const { error, isFetching } = useSelector((state) => state.address);
  const { user } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(clearError({ [name]: null }));
    setAddress({ ...address, [name]: value });
  };

  const onAddAddress = (e) => {
    e.preventDefault();
    const selectedCountryId = country.find(
      (country) => country.name === address.country
    )?.id;
    dispatch(
      createAddress({
        address: {
          ...address,
          countryId: selectedCountryId,
          userId: user.id,
        },
        onSuccess: () => {
          setAddress(initialState);
          setAddAddress(false);
        },
      })
    );
  };

  const onCancel = () => {
    dispatch(clearError(null));
    setAddress(initialState);
    setAddAddress(false);
  };

  return (
    <>
      <div className="my-9">
        <h6 className="text-[22px] font-semibold text-[#7A7A7A]">
          ADD A NEW ADDRESS
        </h6>
        <form className="my-10 grid md:grid-cols-2 gap-6">
          <span>
            <input
              type="text"
              placeholder="Address Line 1 (House No., Building, Street, Area)*"
              className="py-3 px-4 outline-[#476F66] input-secondary "
              name="addressLine1"
              value={address.addressLine1}
              onChange={handleChange}
            />
            {error && error?.addressLine1 && (
              <p className="error-text">{error.addressLine1[0]}</p>
            )}
          </span>
          <span>
            <input
              type="text"
              placeholder="Address Line 2 (Near by)*"
              className="py-3 px-4 outline-[#476F66] input-secondary"
              name="addressLine2"
              value={address.addressLine2}
              onChange={handleChange}
            />
            {error && error?.addressLine2 && (
              <p className="error-text">{error.addressLine2[0]}</p>
            )}
          </span>
          <span>
            <input
              type="text"
              placeholder="City"
              className="py-3 px-4 outline-[#476F66] input-secondary"
              name="city"
              value={address.city}
              onChange={handleChange}
            />
            {error && error?.city && (
              <p className="error-text">{error.city[0]}</p>
            )}
          </span>
          <span>
            <input
              type="number"
              placeholder="Pin Code/Postal Code"
              className="py-3 px-4 outline-[#476F66] input-secondary"
              name="postalCode"
              value={address.postalCode}
              onChange={handleChange}
            />
            {error && error?.postalCode && (
              <p className="error-text">{error.postalCode[0]}</p>
            )}
          </span>
          <span>
            <input
              type="text"
              placeholder="State"
              className="py-3 px-4 outline-[#476F66] input-secondary"
              name="state"
              value={address.state}
              onChange={handleChange}
            />
            {error && error?.state && (
              <p className="error-text">{error.state[0]}</p>
            )}
          </span>
          <span>
            <select
              type="number"
              placeholder="Country"
              className="py-3 px-4 outline-[#476F66] input-secondary"
              name="country"
              onChange={handleChange}
            >
              <option hidden disabled selected>
                Country
              </option>
              {country.map((country) => (
                <option key={country.id}>{country.name}</option>
              ))}
            </select>
            {error && error?.countryId && (
              <p className="error-text">Country field is required</p>
            )}
          </span>
          <span>
            <select
              onChange={handleChange}
              className="py-3 px-4 outline-[#476F66] input-secondary"
              name="type"
            >
              <option hidden disabled selected>
                Address Type
              </option>
              <option>Home</option>
              <option>Office</option>
            </select>
            {error && error?.type && (
              <p className="error-text">{error.type[0]}</p>
            )}
          </span>
        </form>
        {error && error?.message && (
          <p className="error-text">{error.message}</p>
        )}
        <div className="my-10 grid md:grid-cols-2 gap-6">
          <button className="btn-primary" onClick={onCancel}>
            CANCEL
          </button>
          <button onClick={onAddAddress} className="btn-secondary rounded">
            {isFetching ? (
              <div className="flex items-center justify-center">
                <LoadingIndicator
                  indicatorStyle={{
                    width: "30px",
                    height: "30px",
                    borderTop: "5px solid #dab188",
                  }}
                />
              </div>
            ) : (
              "SAVE"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddAddress;
