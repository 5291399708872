import React, { useState, useEffect } from "react";
import { getAll, remove } from "../../../api/ContactUs";
import DateInput from "../../../modules/atoms/dateInput";
import moment from "moment";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";
import Pagination from "../../../modules/atoms/pagination";
import { MdDeleteOutline } from "react-icons/md";
import ConfirmationPopup from "../../../modules/overlays/ConfirmationPopup";

const ContactMails = () => {
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [contactMails, setContactMails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [openPopup, setOpenPopup] = useState(false);

  const fetchAll = async (pageNumber = 1, params = {}) => {
    setLoading(true);
    if (Object.keys(params).length > 0) {
      setIsAllSelected(false);
    }
    await getAll({ ...params, page: pageNumber })
      .then((res) => {
        if (res.status && res.data) {
          setContactMails(res.data.contactMails);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAll(1);
  }, []);

  const onClickAll = () => {
    setPage(1);
    if (!isAllSelected) {
      setStartDate("");
      setEndDate("");
      setIsAllSelected(true);
    }
    fetchAll(1);
  };

  const onFilter = () => {
    setPage(1);
    const params = {};
    startDate !== "" &&
      Object.assign(params, {
        startDate: moment(startDate).format("YYYY-MM-DD"),
      });
    endDate !== "" &&
      Object.assign(params, { endDate: moment(endDate).format("YYYY-MM-DD") });
    fetchAll(1, params);
  };

  const onChangePage = (pageNumber) => {
    if (startDate) {
      fetchAll(pageNumber, {
        startDate: startDate,
        endDate: endDate !== "" ? endDate : null,
      });
    } else {
      fetchAll(pageNumber);
    }
  };

  const onSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((contactMailId) => contactMailId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(contactMails?.data?.map((contactMail) => contactMail.id));
    } else {
      setSelected([]);
    }
  };

  const onDelete = async () => {
    if (selected.length > 0) {
      await remove({ contactUsId: selected }).then(async (res) => {
        if (res.status && res.data) {
          setSelected([]);
          onChangePage(page);
        }
      });
    }
  };

  return (
    <div className="w-100% h-full px-12">
      <ConfirmationPopup
        show={openPopup}
        onClose={() => setOpenPopup(false)}
        title={`Are you sure you want to delete ${
          selected.length > 1 ? "these contact mails" : "this contact mails"
        }`}
        onConfirm={onDelete}
      />
      <p className="font-bold text-xl">Contact Mails</p>
      <div className="bg-white mt-6 border border-solid rounded-md border-slate-200">
        <div className="flex px-4 py-8 border-b border-slate-200 items-center">
          <button
            onClick={onClickAll}
            className={`${
              isAllSelected ? "border-primary text-primary" : "border-slate-200"
            } py-[6px] rounded-md px-4 border`}
          >
            All
          </button>
          <DateInput
            startDate={startDate}
            endDate={endDate}
            containerStyle="ml-4"
            onSetStartDate={setStartDate}
            onSetEndDate={setEndDate}
            onFilter={onFilter}
          />
        </div>
        {!loading && contactMails?.data?.length === 0 ? (
          <div className="flex p-4 items-center justify-center">
            <h2 className="text-xl">No mails found</h2>
          </div>
        ) : loading ? (
          <div className="flex justify-center my-12">
            <LoadingIndicator />
          </div>
        ) : (
          <table className="w-full">
            <thead>
              <tr className="text-black text-sm">
                <th className="px-4">
                  <input
                    className="w-5 checked:bg-primary cursor-pointer"
                    onChange={onSelectAll}
                    type="checkbox"
                  />
                </th>
                <th className="text-left p-4">Name</th>
                <th className="text-left py-4">Email</th>
                <th className="text-left py-4">Phone Number</th>
                <th className="text-left py-4">Received At</th>
                <th className="text-left">
                  <span
                    onClick={() => setOpenPopup(true)}
                    className={`${
                      selected?.length > 0
                        ? "cursor-pointer text-error"
                        : "text-slate-400"
                    } flex items-center`}
                  >
                    Delete
                    <MdDeleteOutline size={18} />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {contactMails?.data?.map((contactMail) => (
                <tr
                  key={contactMail.id}
                  className="border-t border-solid border-b-slate-200"
                >
                  <td className="py-2 px-4">
                    <input
                      checked={selected.includes(contactMail.id)}
                      onChange={() => onSelect(contactMail.id)}
                      className="w-[28px] cursor-pointer"
                      type="checkbox"
                    />
                  </td>
                  <td className="px-4 py-3">{contactMail.name}</td>
                  <td>{contactMail.email}</td>
                  <td>{contactMail.phone ?? "-"}</td>
                  <td className=" py-3">
                    {moment(contactMail.created_at).format(
                      "DD-MM-YYYY HH:MM:SS"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          totalData={contactMails?.total}
          pageNumber={page}
          pageData={10}
          onChangePage={onChangePage}
        />
      </div>
    </div>
  );
};

export default ContactMails;
