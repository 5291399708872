import React, { useState, useEffect } from "react";
import AddAddress from "./addAddress";
import EditAddress from "./editAddress";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchByUser,
  removeAddress,
} from "../../../redux/features/address/AddressActions";
import {
  clearError,
  selectAddress,
} from "../../../redux/features/address/AddressSlice";
import { useLocation } from "react-router-dom";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";

const Address = () => {
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [selectedAddressToEdit, setSelectedAddressToEdit] = useState(null);
  const { addresses, error, selectedAddress, isFetching } = useSelector(
    (state) => state.address
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleEdit = (address) => {
    setSelectedAddressToEdit(address);
    setEditAddress(true);
  };

  useEffect(() => {
    dispatch(clearError(null));
    dispatch(fetchByUser({ userId: user.id }));
    setSelectedAddressToEdit(addresses[0]);
    if (location.state) {
      setSelectedAddressToEdit(location.state.address);
      setEditAddress(true);
    }
  }, []);

  const onDeleteAddress = (id) => {
    dispatch(
      removeAddress({
        addressId: id,
        onSuccess: () => {
          dispatch(fetchByUser({ userId: user.id }));
          if (selectedAddress?.id === id) {
            dispatch(selectAddress(null));
          }
        },
      })
    );
  };

  const onSelectAddress = (address) => {
    dispatch(selectAddress(address));
  };

  return (
    <section className="pb-10">
      <div className="slider my-12">
        <div className="flex flex-wrap gap-2 justify-between">
          <h6 className="h5 text-primary font-semibold ">MANAGE ADDRESSES</h6>
          {!addAddress ? (
            <button
              className="btn-primary-sm"
              onClick={() => {
                setAddAddress(true);
              }}
            >
              + ADD NEW
            </button>
          ) : (
            ""
          )}
        </div>
        <hr className=" md:mt-10 mt-4 hr-line" />
      </div>

      <div className={`${addAddress ? "h-fit" : "h-0 overflow-hidden"}`}>
        <AddAddress setAddAddress={setAddAddress} />
      </div>
      <div className={`${editAddress ? "h-fit " : "h-0 overflow-hidden"}`}>
        <EditAddress
          setEditAddress={setEditAddress}
          selectedAddress={selectedAddressToEdit}
        />
      </div>

      {!isFetching && addresses?.length === 0 ? (
        <div className="w-full">
          <h6 className="text-center h6 font-medium">No Addresses Found</h6>
        </div>
      ) : isFetching ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        addresses?.map((address) => {
          return (
            <>
              <div key={address.id}>
                <div className="flex items-center justify-between">
                  <h6 className="text-[22px] font-manrope font-semibold text-[#7A7A7A]">
                    {address.type}
                  </h6>
                  <span
                    onClick={() => onSelectAddress(address)}
                    className="w-[20px] h-[20px] flex items-center justify-center rounded-full border-2 border-primary"
                  >
                    {selectedAddress?.id === address.id && (
                      <div className="w-[10px] h-[10px] rounded-full bg-primary"></div>
                    )}
                  </span>
                </div>
                <p className="text-base text-[#000000] pt-5 max-w-md">
                  {`${address.addressLine1}, ${address.addressLine2}`}
                </p>
                <p className="text-base text-[#000000] max-w-md">
                  {address.postalCode}, {address.city}, {address.state},
                  {address.country.name}
                </p>
                <div className="flex gap-3 justify-end">
                  <button
                    className="flex items-center gap-2 btn-primary-sm"
                    onClick={() => handleEdit(address)}
                  >
                    <BiEdit />
                    Edit
                  </button>
                  <button
                    onClick={() => onDeleteAddress(address.id)}
                    className="border-2 border-red-700 rounded px-2 hover:opacity-100 opacity-70 transition-all "
                  >
                    <MdDelete className="text-xl text-red-700" />
                  </button>
                </div>
                <hr className="mt-4 " />
              </div>
              {error && error?.message && !addAddress && !editAddress && (
                <p className="error-text">{error?.message}</p>
              )}
            </>
          );
        })
      )}
    </section>
  );
};

export default Address;
