import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function ComingSoon() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="errorScreenWrapper pb-20">
      <h1 className="h2 p-2 max-w-screen-md text-center leading-tight text-primary font-manrope">
        Coming Soon
      </h1>
      <NavLink
        to="/"
        className="p-2 rounded transition-all duration-300 border-primary hover:text-primary mt-4 px-5 font-manrope font-bold bg-primary hover:bg-white text-white border-2"
      >
        Go to Homepage
      </NavLink>
    </div>
  );
}
