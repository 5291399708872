import React from "react";
import { baseURL } from "../../helpers";
import { useSelector } from "react-redux";

export default function ItemstableMobile() {
  const { cartProducts } = useSelector((state) => state.cart);
  const { selectedCountry } = useSelector((state) => state.country);

  const ProductRow = ({ product }) => {
    return (
      <>
        <div className="flex flex-col sm:flex-row  gap-3 items-start p-2 py-5">
          <div className="basis-40 grow-0 shrink-0 w-full sm:min-w-[100px]">
            <img
              src={`${baseURL}${product?.files[0]?.url}`}
              alt="man in suit"
              height="1"
              width="1"
              className="sm:h-44 w-full object-cover"
            />
          </div>
          <div className="font-franklin py-1">
            <h6 className="font-bold">{product.title}</h6>
            <p className="mt-1 text-slate-400">{product.subTitle}</p>
            <p className="font-bold mt-2 text-primary">{`${
              selectedCountry.symbol
            } ${parseInt(product.mrp)}`}</p>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <div className="contain">
        {cartProducts &&
          cartProducts?.length &&
          cartProducts.map((product) => <ProductRow product={product} />)}
      </div>
    </div>
  );
}
