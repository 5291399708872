import React from "react";

const ConfirmationPopup = (props) => {
  const { title, show, onClose, confirmButtonText, onConfirm, popupStyle } =
    props;

  const onClickConfirmButton = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      {show && (
        <div className="h-full fixed top-0 left-0 z-[9999] w-full bg-[#00000094] flex items-center justify-center">
          <div
            className={`w-[400px] rounded-sm z-10 bg-white px-6 ${
              popupStyle ?? "h-[180px]"
            }`}
          >
            <h4 className=" mt-4 text-[18px] font-[600]">{title}</h4>
            <div className="flex items-end justify-end px-6 mt-16">
              <button
                className="border-none text-primary text-[16px]"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="border-none ml-4 text-[#f74949]  text-[16px]"
                onClick={onClickConfirmButton}
              >
                {confirmButtonText ?? "Done"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationPopup;
