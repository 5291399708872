import React from "react";
import { useEffect } from "react";
import { ProductList } from "../../modules/core";
import InfiniteCarousel from "../../modules/core/infiniteCarousel";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategory } from "../../redux/features/category/CategoryActions";

const MenProductCollections = ({}) => {
  const { womenProducts } = useSelector((state) => state.product);
  const { womenCategories } = useSelector((state) => state.category);
  const disptach = useDispatch();

  useEffect(() => {
    disptach(fetchCategory({ type: "women" }));
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <ProductList
          type="women"
          products={womenProducts}
          categories={womenCategories}
        />
        <InfiniteCarousel title="GLIMPSES FROM SOCIAL MEDIA" />
      </div>
    </>
  );
};

export default MenProductCollections;
