import React from "react";
import "./mobileModal.css";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import sleekLogo from "../../../../assets/home/icons/logo.svg";

export default function MobileModal({ className, setIsOpen, openCart }) {
  const [cookies] = useCookies();
  const { user } = useSelector((state) => state.auth);

  return (
    <div className={`${className} mobile-modal bg-white`}>
      <div className="w-[90%] mx-auto my-[2%] mobile-nav ">
        <NavLink to="/" className="2xl:h-full h-16 p-1 ">
          <img
            className="w-full h-full object-contain"
            src={sleekLogo}
            alt="sleek logo"
          />
        </NavLink>
        <IoClose className="cross-icon" onClick={() => setIsOpen(false)} />
      </div>

      <div className="mobile-list">
        <NavLink
          to="/about"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <h4>About Us</h4>
        </NavLink>
        <NavLink
          to="/collections/men"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <h4> Men's Collection</h4>
        </NavLink>
        <NavLink
          to="/collections/women"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <h4> Womens's Collection</h4>
        </NavLink>
        {user &&
        user.email_verified_at &&
        cookies["sleek_studio_user_id"] &&
        cookies["sleek_studio_user_token"] &&
        cookies["sleek_studio_user_token_id"] ? (
          <>
            <NavLink
              to="/profile"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <h4>Profile</h4>
            </NavLink>
            <NavLink
              to="/cart"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <h4>Cart</h4>
            </NavLink>
            <NavLink
              to="/wishlist"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <h4>Wish List</h4>
            </NavLink>
          </>
        ) : (
          <NavLink
            to="/signup"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <h4>Login</h4>
          </NavLink>
        )}
      </div>
    </div>
  );
}
