import React, { useState, useEffect } from "react";
import BackButton from "../../../modules/atoms/backButton";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "../../../modules/atoms/dropdown";
import { clearError } from "../../../redux/features/coupon/CouponSlice";
import { createCoupon } from "../../../redux/features/coupon/CouponActions";
import DatePicker from "react-datepicker";
import "./index.css";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const currencyCodes = [
  { name: "INR", id: 1 },
  { name: "USD", id: 2 },
  { name: "CAD", id: 3 },
  { name: "NZD", id: 4 },
  { name: "GBP", id: 5 },
];

const AddCoupon = () => {
  const [coupon, setCoupon] = useState({
    description: "",
    minCartAmount: 0,
    currencyCode: "",
    type: "",
    amount: 0,
    percentage: 0,
    expiryDateTime: null,
  });
  const { error } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      dispatch(clearError(null));
    };
  }, []);

  const couponInputs = [
    {
      title: "Coupon Description",
      value: coupon.description,
      name: "description",
      type: "textArea",
      id: 1,
    },
    {
      title: "Select Currency and Minimum purchase value",
      value: coupon.minCartAmount,
      name: "minCartAmount",
      type: "normal",
      id: 2,
    },
    {
      title: "Coupon Type",
      value: coupon.type,
      name: "type",
      type: "dropdown",
      id: 3,
    },
    {
      title: "Coupon Fixed Amount",
      value: coupon.amount,
      name: "amount",
      type: "normal",
      id: 4,
    },
    {
      title: "Coupon Percentage",
      value: coupon.percentage,
      name: "percentage",
      type: "normal",
      id: 5,
    },
  ];

  const onChange = (e) => {
    dispatch(clearError({ ...error, [e.target.name]: null }));
    setCoupon({ ...coupon, [e.target.name]: e.target.value });
  };

  const onAdd = () => {
    dispatch(
      createCoupon({
        coupon: {
          ...coupon,
          type: coupon.type.name,
          currencyCode: coupon.currencyCode.name,
          expiryDateTime: moment(coupon.expiryDateTime).format(
            "YYYY-MM-DD HH:mm"
          ),
        },
        onSuccess: () => {
          navigate("/admin/coupons");
        },
      })
    );
  };

  return (
    <div className="w-full h-full py-12 px-12 lg:px-20">
      <BackButton />
      <h2 className="h6 font-bold mt-10">Add Coupon</h2>
      <div className="bg-white rounded-md grid grid-cols-2 gap-8 px-8 py-8 mt-6">
        {couponInputs.map((input) => (
          <div>
            <label className="block text-slate-600">{input.title}</label>
            {input.type === "normal" ? (
              <div className="flex items-center">
                {input.name === "minCartAmount" && (
                  <Dropdown
                    containerStyle="w-[40%] mr-[2%]"
                    dropdownInputStyle="w-full px-2 py-[7px] mt-2 border border-slate-300"
                    dropdownStyle="w-full"
                    placeholder="Currency"
                    data={currencyCodes}
                    selected={coupon.currencyCode}
                    onSelect={(value) => {
                      dispatch(clearError({ ...error, type: null }));
                      setCoupon({ ...coupon, currencyCode: value });
                    }}
                  />
                )}
                <input
                  onChange={onChange}
                  value={input.value}
                  placeholder="Amount"
                  name={input.name}
                  type="number"
                  className={`border  ${
                    input.name === "minCartAmount" ? "w-[70%]" : "w-full"
                  }
                  focus:outline-none px-2 py-[8px] rounded-sm mt-2 border-slate-300`}
                />
              </div>
            ) : input.type === "dropdown" ? (
              <Dropdown
                containerStyle="w-full"
                dropdownInputStyle="w-full px-2 py-[8px] mt-2 border border-slate-300"
                dropdownStyle="w-full"
                data={[
                  { name: "Fixed", id: 1 },
                  { name: "Percentage", id: 2 },
                ]}
                selected={coupon.type}
                placeholder="Type"
                onSelect={(value) => {
                  dispatch(clearError({ ...error, type: null }));
                  setCoupon({ ...coupon, type: value });
                }}
              />
            ) : (
              <textarea
                onChange={onChange}
                value={coupon.description}
                name="description"
                placeholder="Description"
                className="border h-[95px] px-2 focus:outline-none border-slate-300 w-full mt-2 hover:outline-none"
              />
            )}
            {error && error?.[input.name] && (
              <p className="error-text">{error?.[input.name][0]}</p>
            )}
          </div>
        ))}
        <div>
          <label className="block text-slate-600">Expire Date and Time</label>
          <div className="flex items-center justify-between">
            <DatePicker
              selected={coupon.expiryDateTime ?? ""}
              timeFormat="HH::mm aaa"
              dateFormat="dd-MM-yyyy"
              placeholderText="Expiry Date"
              showTimeSelect={true}
              shouldCloseOnSelect={false}
              onChange={(date) => {
                dispatch(clearError({ ...error, expiryDateTime: null }));
                setCoupon({ ...coupon, expiryDateTime: date });
              }}
              className="border focus:outline-none px-2 mt-2 w-[98%] border-slate-300 rounded-sm py-[8px]"
            />
            <input
              value={
                coupon.expiryDateTime
                  ? moment(coupon.expiryDateTime).format("HH:mm a")
                  : ""
              }
              disabled
              placeholder="Expiry Time"
              className="w-[40%] focus:outline-none border border-slate-300 rounded-sm py-[8px] mt-2 px-2"
            />
          </div>
          {error && error?.expiryDateTime && (
            <p className="error-text">{error.expiryDateTime[0]}</p>
          )}
        </div>
        <div className="flex items-end">
          <button
            onClick={onAdd}
            className="border-none w-full xl:w-[40%] bg-primary text-white py-3 rounded-sm"
          >
            Add Coupon
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCoupon;
