import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import OTPPopup from "../../../modules/overlays/otpPopup";
import { verifyOTP, resetEmailGenerateOTP } from "../../../api/Admin";
import { clearError, setError } from "../../../redux/features/admin/AdminSlice";
import { updateAdmin } from "../../../redux/features/admin/AdminActions";
import BackButton from "../../../modules/atoms/backButton";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";

const ResetEmail = () => {
  const [email, setEmail] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const { admin, error, isFetching } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onVerify = async (otp) => {
    await verifyOTP({ email: admin.email, type: "verifyEmail", otp: otp })
      .then((res) => {
        if (res.status && res.data) {
          dispatch(
            updateAdmin({
              user: { email: email, userId: admin.id },
              onSuccess: () => {
                setOpenPopup(false);
                navigate("/admin/profile");
              },
            })
          );
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const validate = () => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email.match(emailRegex)) {
      onSendOTP();
    } else {
      dispatch(
        setError(
          error
            ? {
                ...error,
                newEmail: ["The email field must be a valid email address"],
              }
            : { newEmail: ["The email field must be a valid email address"] }
        )
      );
    }
  };

  const onSendOTP = async () => {
    await resetEmailGenerateOTP({ userId: admin.id, newEmail: email })
      .then((res) => {
        if (res.status && res.data) {
          setOpenPopup(true);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onClosePopup = () => {
    dispatch(clearError(null));
    setOpenPopup(false);
  };

  const onChangeEmail = (e) => {
    dispatch(clearError(null));
    setEmail(e.target.value);
  };

  return (
    <div className="w-full px-12 pt-12">
      <BackButton />
      <div className="w-full max-w-[1000px] bg-white py-12 mt-12  px-8 rounded-md">
        <OTPPopup
          show={openPopup}
          data={email}
          onClose={onClosePopup}
          onVerify={onVerify}
          onResend={onSendOTP}
        />
        <div>
          <h2 className="font-bold text-xl">Reset Email</h2>
          <p className="mt-4 text-slate-400">
            Please enter your new email address to reset it. You will receive an
            email containg OTP on your old email shortly.
          </p>
        </div>
        <label className="block text-slate-500 mt-8">New Email</label>
        <input
          type="text"
          placeholder="Email"
          className="py-3 mt-2 block max-w-[600px] px-4 outline-[#476F66] input-secondary"
          value={email}
          onChange={onChangeEmail}
          name="email"
        />
        {error && error?.newEmail && (
          <p className="error-text">{error?.newEmail[0]}</p>
        )}
        <button
          className="text-primary border mt-8 border-primary bg-white h-full py-2 px-12 rounded-sm"
          onClick={validate}
        >
          {isFetching ? (
            <div className="flex items-center justify-center">
              <LoadingIndicator
                indicatorStyle={{
                  width: "30px",
                  height: "30px",
                  borderTop: "5px solid #dab188",
                }}
              />
            </div>
          ) : (
            "Verify Email"
          )}
        </button>
      </div>
    </div>
  );
};

export default ResetEmail;
