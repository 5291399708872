import React from "react";
import "../contact.css";
import phone from "../../../assets/contact/phone.svg";
import message from "../../../assets/contact/message.svg";

const Maps = () => {
  {
    /* Official name
Aay Ess Silk Mills Pvt Ltd
Address
Village Chack Gujra,
Jalandhar Road
Hoshiarpur
146001
Email
Info@thesleekstudio.com
Phone number
9317401740 */
  }
  return (
    <>
      <div className="md:my-40 my-20 md:flex items-center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14716.454607050478!2d75.88823949999998!3d22.761163149999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1642417733877!5m2!1sen!2sin"
          className="w-[100%] h-[38vh] lg:w-[74%] 2xl:h-[60vh] min-h-[28rem]"
        ></iframe>
        <div className="bg-primary px-10 md:py-28 py-14 lg:w-[48%]  2xl:w-[45%]">
          <h5 className="h4 text-secondary font-manrope leading-tight">
            Sleek Clothing <br /> Co. Limited
          </h5>
          <p className="p text-white border-b-2 border-solid border-secondary pb-4 leading-relaxed mt-8 font-manrope">
            <b>Corporate Office:</b>
            <br /> Aay Ess Silk Mills Pvt Ltd, Village Chack Gujra, <br />{" "}
            Jalandhar Road, Hoshiarpur-146001
          </p>
          {/* <p className="p text-white border-b-2 border-solid border-secondary pb-4 leading-relaxed mt-4 font-manrope">
            <b>Registered Office:</b> Lorem, 512, Demo towers, Ipsum Road,
            Mehrauli, New Delhi-110030
          </p> */}
          <div className="flex-nowrap flex items-center mt-5 gap-3">
            <img className="w-10" src={phone} alt="" />
            <a href="tel:+919317401740" className="p text-white font-manrope">
              +91 9317401740
            </a>
          </div>
          <div className="flex-nowrap flex items-center mt-5 gap-3 ">
            <img className="w-10 " src={message} alt="" />
            <a
              href="mail:info@thesleekstudio.com"
              className="p text-white font-manrope"
            >
              info@thesleekstudio.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maps;
