import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import { clearError } from "../../redux/features/category/CategorySlice";
import Dropdown from "../atoms/dropdown";

const AddCategoryPopup = ({ show, onClose, onSubmit, popupType }) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const { error } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const submitHandler = () => {
    if (popupType === "category") {
      onSubmit({ name: name, type: type?.name?.toLowerCase() });
    } else {
      onSubmit({ name: name });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearError(null));
    };
  }, []);

  const closeHandler = () => {
    dispatch(clearError(null));
    onClose();
  };

  return (
    <>
      {show && (
        <div className="h-full fixed top-0 left-0 z-[9999] w-full bg-[#00000094] flex items-center justify-center">
          <div className="w-[40%] py-12 relative bg-white mr-[2%] px-6 rounded-md border border-solid border-slate-200">
            <span className="cursor-pointer" onClick={closeHandler}>
              <RxCross1 className="absolute right-4 top-4" size={16} />
            </span>
            <h2 className="text-xl font-medium text-center mt-4">
              {`${
                popupType === "category" ? "Add Category" : "Add Subcategory"
              }`}
            </h2>

            <input
              onChange={(e) => {
                dispatch(clearError({ name: null }));
                setName(e.target.value);
              }}
              name="name"
              placeholder={`${
                popupType === "category" ? "Category Name" : "Subcategory Name"
              }`}
              autoFocus={false}
              autoComplete="off"
              className="border mt-8 focus:outline-none w-full py-2 px-2 rounded-sm border-slate-200"
            />
            {error && error?.name && <p className="error-text">{error.name}</p>}
            {error && error?.subcategory && (
              <p className="error-text">{error.subcategory[0]}</p>
            )}
            {popupType === "category" && (
              <>
                <Dropdown
                  containerStyle="w-full py-2 mt-4"
                  placeholder="Select Category Type"
                  dropdownInputStyle="w-full py-[8px] border px-2 border-slate-200"
                  dropdownStyle="h-[100px] w-[100%] top-15"
                  data={[
                    { id: 1, name: "Men" },
                    { id: 2, name: "Women" },
                  ]}
                  selected={type}
                  onSelect={(type) => {
                    dispatch(clearError({ type: null }));
                    setType(type);
                  }}
                />
                {error && error?.type && (
                  <p className="error-text">{error.type}</p>
                )}
              </>
            )}
            {error && error?.message && (
              <p className="error-text">{error.message}</p>
            )}
            <div className="flex mt-8 justify-center w-full">
              <button
                onClick={submitHandler}
                className="bg-primary w-[40%] py-2  text-white"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCategoryPopup;
