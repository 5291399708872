import React, { useEffect } from "react";
import productImg from "../../../assets/placeholder.jpg";
import { useSelector, useDispatch } from "react-redux";
import { fetchByUser } from "../../../redux/features/order/OrderActions";
import LoadingIndicator from "../../../modules/atoms/loadingIndicator";
import { baseURL } from "../../../helpers";
import { orderStatus, paymentMethods } from "../../../assets/data/orderData";
import moment from "moment";
import { useState } from "react";
import Measurement from "../../measurement";

const OrderDetail = () => {
  const { authOrders, isFetching } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.auth);
  const { country } = useSelector((state) => state.country);
  const [showMeasurements, setShowMeasurements] = useState(false);
  const [measurements, setMeasurements] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user?.id) {
      dispatch(fetchByUser(user.id));
    }
  }, [user]);

  const onSelectProduct = (product) => {
    const { productId, orderId, id, ...newMeasurements } = product.pivot;
    setMeasurements(newMeasurements);
    setShowMeasurements(true);
  };

  const DetailCard = ({
    status,
    coupon,
    product,
    paymentMethod,
    address,
    country,
    createdDate,
  }) => {
    return (
      <>
        <div className="flex mt-5 ">
          <div className="h-full md:w-28 w-20 ">
            <img
              className="h-full w-full object-cover"
              src={
                product?.files
                  ? `${baseURL}${product?.files[0]?.url}`
                  : productImg
              }
              alt="product"
            />
          </div>
          <div className="flex justify-between flex-1 md:pl-10 pl-2">
            <div className="text-primary  w-[60%] ">
              <h4 className="font-semibold h6">{product?.title}</h4>
              <h5 className="mt-2 h6">
                Address:{" "}
                <span className="font-semibold h6">
                  {`${address?.addressLine1}, ${address?.addressLine2} 
                  ${address?.city}, ${address?.state},  ${address?.postalCode}, ${address?.country?.name}`}
                </span>
              </h5>
              <h5 className="mt-2 h6">
                Payment Mode:{" "}
                <span className="font-semibold">
                  {paymentMethods[paymentMethod]}
                </span>
              </h5>
              {coupon && (
                <h5 className="md:mt-1 h6">
                  Coupons :{" "}
                  <span className="font-semibold ">{coupon?.code}</span>
                  {"  "}
                  <span>{coupon.description}</span>
                </h5>
              )}
            </div>
            <div className="text-primary md:block hidden">
              <h5 className="mt-1 h6"> {`${country?.symbol}${product.mrp}`}</h5>
            </div>
            <div className="text-primary flex flex-col justify-between">
              <h5
                className={`font-semibold h6
                  ${
                    status === "delivered"
                      ? "text-green-500"
                      : status === "cancelled"
                      ? "text-red-500"
                      : "text-yellow-500"
                  }`}
              >
                {orderStatus[status]} <br /> {createdDate}
              </h5>
              <button
                onClick={() => onSelectProduct(product)}
                className="border-primary border-2 rounded-sm px-2 py-1 text-primary font-semibold cursor-pointer"
              >
                Measurements
              </button>
            </div>
          </div>
        </div>
        <hr className="border-[1px] my-4" />
      </>
    );
  };
  return (
    <>
      <section className="">
        <div className="slider my-12">
          <div className="flex justify-between">
            <h6 className="h5 text-primary font-semibold">MY ORDERS</h6>
          </div>
          <hr className="mt-10md:mt-10 mt-4 hr-line" />

          {!isFetching && authOrders?.data?.length === 0 ? (
            <div className="w-full mt-20">
              <p className="text-center h6 font-medium text-primary">
                No Orders Found
              </p>
            </div>
          ) : isFetching ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <LoadingIndicator />
            </div>
          ) : (
            authOrders?.data?.map((item, ind) => {
              const { products, coupon, payment, address, status, created_at } =
                item;
              return products?.map((product) => {
                return (
                  <DetailCard
                    coupon={coupon}
                    paymentMethod={payment?.paymentMethod}
                    address={address}
                    country={country?.find(
                      (country) => country.currencyCode === payment?.currency
                    )}
                    product={product}
                    status={status}
                    createdDate={moment(created_at).format("DD-MM-YYY")}
                  />
                );
              });
            })
          )}
        </div>

        {showMeasurements ? (
          <Measurement
            closeCart={setShowMeasurements}
            existingMeasurements={measurements}
          />
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default OrderDetail;
