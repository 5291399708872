import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  applyCoupon as applyCouponAPI,
  create,
  remove,
  getAll,
  update,
} from "../../../api/Coupon";

export const createCoupon = createAsyncThunk(
  "coupon/create",
  async ({ coupon, onSuccess }, thunkAPI) => {
    return await create(coupon)
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data.coupon;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const updateCoupon = createAsyncThunk(
  "coupon/update",
  async (coupon, thunkAPI) => {
    return await update(coupon)
      .then((res) => {
        if (res?.status && res?.data) {
          return res.data.coupon;
        } else {
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const removeCoupon = createAsyncThunk(
  "coupon/remove",
  async ({ couponId, onSuccess }, thunkAPI) => {
    return await remove({ couponId })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        } else {
          onSuccess();
          return thunkAPI.rejectWithValue(res?.data ?? res);
        }
      })
      .catch((error) => error);
  }
);

export const fetchCoupons = createAsyncThunk("coupons", async (_, thunkAPI) => {
  return await getAll()
    .then((res) => {
      if (res?.status && res?.data) {
        return res.data.coupons;
      } else {
        return thunkAPI.rejectWithValue(res?.data ?? res);
      }
    })
    .catch((error) => error);
});

export const applyCoupon = createAsyncThunk(
  "coupon/apply",
  async ({ userId, countryId, couponCode, onSuccess }, thunkAPI) => {
    return await applyCouponAPI({ userId, countryId, couponCode })
      .then((res) => {
        if (res?.status && res?.data) {
          onSuccess();
          return res.data;
        }
        return thunkAPI.rejectWithValue(res?.data ?? res);
      })
      .catch((error) => error);
  }
);
