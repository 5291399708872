import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  login,
  loginWithOTP,
} from "../../../redux/features/admin/AdminActions";
import { verifyOTP, generateOTP } from "../../../api/Admin";
import { useSelector, useDispatch } from "react-redux";
import OTPPopup from "../../../modules/overlays/otpPopup";
import { clearError, setError } from "../../../redux/features/admin/AdminSlice";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { admin, error } = useSelector((state) => state.admin);
  const [openPopup, setOpenPopup] = useState(false);
  const location = useLocation();
  const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      login({
        admin: { email: email, password: password, role: "admin" },
        onSuccess: (data) => {
          setCookie("sleek_studio_admin_id", data.user.id, {
            maxAge: 2678400,
            path: "/",
          });
          setCookie("sleek_studio_admin_token_id", data.tokenId, {
            maxAge: 2678400,
            path: "/",
          });
          setCookie("sleek_studio_admin_token", data.token, {
            maxAge: 2678400,
            path: "/",
          });
        },
      })
    );
  };

  const onSendOTP = async () => {
    await generateOTP({ email: email, type: "verifyEmail", role: "admin" })
      .then((res) => {
        if (res.status && res.data) {
          setOpenPopup(true);
        } else {
          dispatch(setError(res));
        }
      })
      .catch((error) => console.log(error));
  };

  const onVerifyOTP = async (otp) => {
    dispatch(clearError(null));
    await verifyOTP({ email: email, type: "verifyEmail", otp }).then((res) => {
      if (res.status && res.data) {
        dispatch(
          loginWithOTP({
            admin: { email: email, role: "admin" },
            onSuccess: (data) => {
              setCookie("sleek_studio_admin_id", data.user.id, {
                maxAge: 2678400,
                path: "/",
              });
              setCookie("sleek_studio_admin_token_id", data.tokenId, {
                maxAge: 2678400,
                path: "/",
              });
              setCookie("sleek_studio_admin_token", data.token, {
                maxAge: 2678400,
                path: "/",
              });
            },
          })
        );
      } else {
        dispatch(setError(res));
      }
    });
  };

  const onClosePopup = () => {
    dispatch(clearError(null));
    setOpenPopup(false);
  };

  const changeHandler = (e) => {
    dispatch(clearError({ ...error, [e.target.name]: null }));
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (
      cookies["sleek_studio_admin_id"] &&
      cookies["sleek_studio_admin_token"] &&
      cookies["sleek_studio_admin_token_id"] &&
      location.pathname === "/admin/login"
    ) {
      navigate("/admin");
    }
  }, [cookies]);

  return (
    <div className="w-full h-[100vh]  flex items-center justify-center bg-secondaryTwoLight">
      <OTPPopup
        data={email}
        onClose={onClosePopup}
        onVerify={onVerifyOTP}
        onResend={onSendOTP}
        show={openPopup}
      />
      <div className="w-[50%]  flex flex-col justify-between bg-white max-w-[500px] h-[525px] p-8 rounded-md">
        <div>
          <h2 className="text-center font-bold text-2xl">Login</h2>
          <label className="mt-8 block">Enter Email</label>
          <input
            className="mt-2 focus:outline-none focus:border-primary hover:border-primary px-2 rounded-sm w-full h-[35px] border border-slate-300"
            name="email"
            value={email}
            onChange={changeHandler}
          />
          {error && error?.email && (
            <p className="error-text text-center">{error?.email[0]}</p>
          )}
          <label className="block mt-6">Enter Password</label>
          <input
            className="mt-2 focus:outline-none focus:border-primary hover:border-primary px-2 rounded-sm h-[35px] border w-full border-slate-300"
            name="password"
            type="password"
            value={password}
            onChange={changeHandler}
          />
          {error && error?.password && (
            <p className="error-text text-center">{error?.password[0]}</p>
          )}
          {error && error?.message && (
            <p className="error-text text-center">{error.message}</p>
          )}
        </div>
        <div>
          <button
            onClick={handleSubmit}
            className={`w-full
           
          text-white bg-primary py-2 rounded-sm`}
          >
            Login
          </button>
          <div className="w-full flex items-center justify-center mt-6">
            <div className="h-[1px] w-[45%] mr-[5%] bg-slate-300" />
            <p className="text">OR</p>
            <div className="w-[45%] h-[1px] ml-[5%] bg-slate-300" />
          </div>
          <button
            onClick={onSendOTP}
            className="w-full mt-6  text-white bg-secondary py-2"
          >
            Login With OTP
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
