import React, { useRef, useReducer } from "react";
import { Popover, Transition as PopTransition } from "@headlessui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { selectCountry } from "../../redux/features/country/CountrySlice";
import { baseURL } from "../../helpers";

const CountrySelector = ({
  showIcon,
  showCountryCode,
  selectedCountry: localScopeSelectedCountry,
  onSelectCountry,
}) => {
  const popBtn = useRef(null);

  const { selectedCountry, country } = useSelector((state) => state.country);
  const dispatch = useDispatch();

  const handleCountrySelection = (country) => {
    popBtn.current.click();
    if (onSelectCountry) {
      onSelectCountry(country);
    } else {
      dispatch(selectCountry(country));
    }
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={popBtn}
            className="text-base lg:text-lg text-primary flex flex-nowrap gap-1 items-center focus:outline-none focus:text-orange px-6"
          >
            <img
              src={`${baseURL}${
                localScopeSelectedCountry
                  ? localScopeSelectedCountry?.imageURL
                  : selectedCountry?.imageURL
              }`}
              alt="India Flag"
              className="h-5 mr-2  w-auto object-contain object-center"
            />
            {showCountryCode
              ? localScopeSelectedCountry
                ? `+${localScopeSelectedCountry?.code}`
                : `+${selectedCountry?.code}`
              : selectedCountry?.currencyCode}
            {showIcon && <IoMdArrowDropdown />}
          </Popover.Button>

          <PopTransition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-95 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className={`absolute w-28`}
          >
            <Popover.Panel className=" py-4 rounded-md  grid gap-3  bg-primary-dark text-white ">
              {country &&
                country.length > 0 &&
                country.map((country) => (
                  <div
                    className=" px-2 divide-y-1 divide-slate-200 gap-1"
                    key={country.id}
                  >
                    <button
                      type="button"
                      onClick={() => handleCountrySelection(country)}
                      className="d flex flex-nowrap items-center gap-2 focus:outline-none focus:text-secondary"
                    >
                      <img
                        src={`${baseURL}${country?.imageURL}`}
                        alt="flag"
                        className="h-5 w-10 object-contain object-center"
                      />
                      {country.currencyCode}
                    </button>
                  </div>
                ))}
            </Popover.Panel>
          </PopTransition>
        </>
      )}
    </Popover>
  );
};

export default CountrySelector;
