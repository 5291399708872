import React, { useState, useEffect, useMemo } from "react";
import "./styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { getById } from "../../api/category";
import { getById as getProduct } from "../../api/Product";
import { useNavigate, useParams } from "react-router";
import { addWishlistProduct } from "../../redux/features/wishlist/WishlistActions";
import { baseURL } from "../../helpers";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";
import { useCookies } from "react-cookie";
import HeartOutline from "../../modules/icons/HeartOutline";
import Back from "../../assets/icons/Back.svg";
import { toast } from "react-toastify";
import Measurement from "../measurement";

export default function ProductDetail() {
  const { user } = useSelector((state) => state.auth);
  const { selectedCountry } = useSelector((state) => state.country);
  const { wishlistProducts } = useSelector((state) => state.wishlist);
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState();
  const [subcategory, setSubcategory] = useState();
  const [measurement, setMeasurement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchCategory = async () => {
    await getById({ categoryId: product?.categoryId }).then((res) => {
      if (res.status && res.data) {
        setCategory(res.data.category);
        setSubcategory(
          res.data.category.subcategories.find(
            (subcategory) => subcategory.id === product?.subcategoryId
          )
        );
      }
    });
  };

  const fetchProduct = async () => {
    setLoading(true);
    await getProduct({
      productId: parseInt(productId),
      countryId: selectedCountry?.id,
    })
      .then((res) => {
        if (res.status && res.data) {
          setProduct(res.data.product);
        }
      })
      .finally(() => setLoading(false));
  };

  const isAddedToWishlist = useMemo(
    () => wishlistProducts?.data?.some((item) => item?.id === product?.id),
    [wishlistProducts, product]
  );

  const onAddMeasurement = () => {
    if (
      user &&
      user?.email_verified_at &&
      cookies["sleek_studio_user_id"] &&
      cookies["sleek_studio_user_token"] &&
      cookies["sleek_studio_user_token_id"]
    ) {
      if (measurement) setMeasurement(false);
      else setMeasurement(true);
    } else {
      navigate("/signup");
    }
  };

  const onAddProductToWishlist = () => {
    if (
      user &&
      user?.email_verified_at &&
      cookies["sleek_studio_user_token"] &&
      cookies["sleek_studio_user_token_id"] &&
      cookies["sleek_studio_user_id"]
    ) {
      if (!isAddedToWishlist) {
        dispatch(
          addWishlistProduct({
            userId: user.id,
            productId: product?.id,
            onSuccess: () => {
              toast.success("Product added to the wishlist", {
                position: "bottom-center",
                autoClose: 3000,
              });
            },
          })
        );
      }
    } else {
      navigate("/signup");
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [selectedCountry]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (product) {
      fetchCategory();
    }
  }, [product]);

  return (
    <>
      {loading ? (
        <div className="flex mx-auto min-h-[90vh] w-full items-center justify-center ">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className="contain mb-32 relative product-detail-wrapper grid lg:grid-cols-2 grid-cols-1 2xl:gap-20 lg:gap-10 gap-5 md:mt-[185px] mt-[150px]">
            <span
              onClick={() => navigate(-1)}
              className="text-xl h-fit absolute cursor-pointer bottom-full mb-3 md:left-0 left-4 hover:-translate-x-2 duration-300"
            >
              <img className="w-[70px] h-[70px]" src={Back} alt="back" />
            </span>
            <div className="lg:pl-10 pl-8 relative">
              <Swiper
                spaceBetween={40}
                slidesPerView={1}
                loop
                modules={[Pagination]}
                className="product-detail-carousel"
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                }}
              >
                {product?.files?.map((image, ind) => {
                  return (
                    <SwiperSlide>
                      <div className="xl:h-[75vh] lg:h-[60vh] md:h-[400px] h-[300px]  xl:min-h-[650px] lg:min-h-[500px]">
                        <img
                          src={`${baseURL}${image?.url}`}
                          className="h-full w-full object-cover object-center "
                          alt=""
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <span className="swiper-pagination swiper-pagination-vertical lg:block"></span>
            </div>
            <div className="product-details flex flex-col xl:gap-6 gap-2 max-w-lg">
              <h3 className="text-secondary lg:block hidden">
                {subcategory
                  ? `${category?.name} / ${subcategory?.name}`
                  : `${category?.name}`}
              </h3>
              <h2 className="text-primary h5 font-semibold leading-tight">
                {product?.title.slice(0, 60)}
              </h2>
              <div className="flex justify-between ">
                <h2 className="text-secondary h5 font-semibold leading-none ">
                  {`${selectedCountry?.symbol} ${parseInt(product?.mrp)}`}
                </h2>

                {/* <button className="bg-secondary h6 font-light text-white px-4  rounded">
                    40% Giveback
                </button> */}
              </div>
              <hr className="w-full my-3" />

              <h6 className="h6 text-primary">{product?.description}</h6>

              <div className="flex items-center gap-5">
                {/* <button
                  onClick={onAddProductToCart}
                  className="btn-primary-md px-6 h6 border-2"
                >
                  ADD TO CART
                </button> */}
                <button
                  onClick={onAddMeasurement}
                  className="btn-primary-md px-6 h6 border-2"
                >
                  ADD MEASUREMENT
                </button>
                <button
                  onClick={onAddProductToWishlist}
                  className={`${
                    isAddedToWishlist
                      ? "fill-[#dab188]"
                      : "fill-transparent hover:fill-secondary"
                  }
                bg-transparent border-2 p-2 border-secondary rounded-full transition-all duration-300 text-secondary hover:text-white font-manrope font-normal`}
                >
                  <HeartOutline width="30" height="30" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {measurement ? (
        <Measurement closeCart={setMeasurement} productId={product?.id} />
      ) : (
        ""
      )}
    </>
  );
}
