import React from "react";
import { useEffect } from "react";
import Hero_blade from "../../assets/about/hero-blade.png";
import SleekLegacy from "./sleekLegacy";
import Philosophy from "./sleekPhilosophy";
import Purpose from "./sleekPurpose";
import "./about.css";

export default function AboutScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <div className="about-wrapper">
        <img
          src={Hero_blade}
          alt=""
          className="w-full h-full object-cover object-left"
        />
      </div>
      <Philosophy />
      <Purpose />
      <SleekLegacy />
    </section>
  );
}
