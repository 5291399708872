import React, { useState, useMemo, useEffect } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import "./index.css";

const PaginationContainer = ({
  totalData,
  onChangePage,
  pageData,
  pageNumber,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [previous, setPrevious] = useState(false);
  const [next, setNext] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    if (pageNumber) {
      setCurrentPage(pageNumber);
    }
  }, []);

  const totalPages = useMemo(
    () =>
      totalData % pageData === 0
        ? Math.floor(totalData / pageData)
        : Math.floor(totalData / pageData) + 1,
    [totalData]
  );

  const totalSlides = useMemo(
    () =>
      totalPages % 5 === 0
        ? Math.floor(totalPages / 5)
        : Math.floor(totalPages / 5) + 1,
    [totalPages]
  );

  const slideNumberChanger = useMemo(
    () => (currentSlide > 1 ? 5 * (currentSlide - 1) : 0),
    [currentSlide]
  );

  const slideData = useMemo(() => {
    let totalPagesRef = totalPages;
    return totalSlides
      ? Array.from(Array(totalSlides).keys()).map(() => {
          if (totalPagesRef < 5) return totalPagesRef;
          totalPagesRef = totalPagesRef - 5;
          return 5;
        })
      : [];
  }, [totalPages, totalSlides]);

  const onClickNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    onChangePage(pageNumber);
  };

  useEffect(() => {
    if (totalSlides > 1 && currentSlide !== totalSlides) {
      setNext(true);
    } else {
      setNext(false);
    }
    if (currentSlide > 1) {
      setPrevious(true);
    } else {
      setPrevious(false);
    }
  }, [currentSlide, totalSlides]);

  return (
    <div className="w-[100%] mx-auto  py-4 bord border-slate-200">
      <div className="w-[95%] flex items-center justify-end">
        {previous && (
          <span
            onClick={() => setCurrentSlide((prev) => prev - 1)}
            className="text-primary cursor-pointer mt-1"
          >
            <BsArrowLeft size={20} />
          </span>
        )}

        {slideData.length === 1 &&
        Array.from(Array(slideData[currentSlide - 1])).length === 1
          ? null
          : slideData.length > 0 &&
            Array.from(Array(slideData[currentSlide - 1]).keys()).map(
              (item, index) => {
                const number = item + 1 + slideNumberChanger;
                return (
                  <span
                    key={index}
                    onClick={() => onClickNumber(number)}
                    className={`py-2 ml-6 rounded-md cursor-pointer flex items-center justify-center ${
                      currentPage === number ? "text-primary" : "text-slate-400"
                    }`}
                  >
                    <p className="text-lg font-semibold">{number}</p>
                  </span>
                );
              }
            )}

        {next && (
          <span
            onClick={() => setCurrentSlide((prev) => prev + 1)}
            className="text-primary ml-6 mt-1"
          >
            <BsArrowRight size={20} />
          </span>
        )}
      </div>
    </div>
  );
};

export default PaginationContainer;
