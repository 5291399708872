import { ApiClient } from "./ApiClient";

const api = new ApiClient();

export const applyCoupon = (body) => api.post("coupon/apply", body);

export const create = (body) => api.post("coupon", body, {}, "", "admin");

export const update = (body) => api.put("coupon", body, {}, "admin");

export const remove = (body) => api.delete("coupon", body, {}, "admin");

export const getAll = () => api.get("coupons");
