import family1 from "../../assets/home/family_01.jpg";
import family2 from "../../assets/home/family_02.jpg";
import family3 from "../../assets/home/family_03.jpg";
import groom1 from "../../assets/home/Groom_01.jpg";
import groom2 from "../../assets/home/Groom_02.jpg";
import groom3 from "../../assets/home/Groom_03.jpg";
import bride1 from "../../assets/home/Bride_01.jpg";
import bride2 from "../../assets/home/Bride_02.jpg";
import bride3 from "../../assets/home/Bride_03.jpg";

export const category = [
  {
    title: "Family",
    li1: "Stepping into your embrace of everlasting love is a once-in-a-lifetime experience! Sleek Studio’s Grooms celebrate this ethereal moment with hand-weaved timeless couture. Every dazzling piece is curated to compliment his charisma and glorify the man of the occasion.",
    li2: "You can count on Sleek studio to curate personalized occasion-wear imbued with traditions of royalty, and empowered with modern-perspective.",
    src: [
      { name: family1, id: 1 },
      { name: family2, id: 2 },
      { name: family3, id: 3 },
    ],
    id: 1,
  },
  {
    title: "Groom",
    li1: "Stepping into your embrace of everlasting love is a once-in-a-lifetime experience! Sleek Studio’s Grooms celebrate this ethereal moment with hand-weaved timeless couture. Every dazzling piece is curated to compliment his charisma and glorify the man of the occasion.",
    li2: "You can count on Sleek studio to curate personalized occasion-wear imbued with traditions of royalty, and empowered with modern-perspective.",
    src: [
      { name: groom1, id: 1 },
      { name: groom2, id: 2 },
      { name: groom3, id: 3 },
    ],
    id: 2,
  },
  {
    title: "Bride",
    li1: "Stepping into your embrace of everlasting love is a once-in-a-lifetime experience! Sleek Studio’s Grooms celebrate this ethereal moment with hand-weaved timeless couture. Every dazzling piece is curated to compliment his charisma and glorify the man of the occasion.",
    li2: "You can count on Sleek studio to curate personalized occasion-wear imbued with traditions of royalty, and empowered with modern-perspective.",
    src: [
      { name: bride1, id: 1 },
      { name: bride2, id: 2 },
      { name: bride3, id: 3 },
    ],
    id: 3,
  },
];
