import React, { useMemo, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../redux/features/users/UserActions";
import { createAddress } from "../../redux/features/address/AddressActions";
import { BiEdit } from "react-icons/bi";
import {
  selectAddress,
  setError as setAddressError,
} from "../../redux/features/address/AddressSlice";
import { useNavigate } from "react-router-dom";
import OTPPopup from "../../modules/overlays/otpPopup";
import PhoneInput from "../../modules/atoms/PhoneInput";
import { modifiedPhoneNumber } from "../../helpers";
import { generateOTP, verifyOTP, verifyPhone } from "../../api/User";
import { setError as setUserError } from "../../redux/features/users/userSlice";
import { fetchCartProducts } from "../../redux/features/cart/CartActions";
import LoadingIndicator from "../../modules/atoms/loadingIndicator";
import { removeAppliedCoupon } from "../../redux/features/coupon/CouponSlice";
import { BsCheckCircle } from "react-icons/bs";

export default function CheckoutForm() {
  const [openPopup, setOpenPopup] = useState(false);
  const { user, error: userError } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const {
    selectedAddress,
    addresses,
    error: addressError,
  } = useSelector((state) => state.address);
  const [selectedPhoneCountry, setSelectedPhoneCountry] = useState("");
  const { country, selectedCountry } = useSelector((state) => state.country);
  const { cartProducts } = useSelector((state) => state.cart);
  const { appliedCoupon, subtotal } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const total = useMemo(
    () => cartProducts?.reduce((total, product) => total + product.mrp, 0),
    [cartProducts]
  );

  const onSendOTP = async () => {
    await generateOTP({
      email: user.email,
      type: "phone",
      role: "customer",
    }).then((res) => {
      if (res.status && res.data) {
        setOpenPopup(true);
      } else {
        dispatch(setUserError(res));
      }
    });
  };

  const onVerifyOTP = async (otp) => {
    await verifyOTP({ email: user.email, type: "phone", otp }).then(
      async (res) => {
        if (res.status && res.data) {
          await verifyPhone({ userId: user.id }).then((res) => {
            if (res.status && res.data) {
              setOpenPopup(false);
              setLoading(false);
              navigate("confirmation");
            }
          });
        } else {
          dispatch(setUserError(res));
        }
      }
    );
  };

  const initialState = useMemo(
    () => ({
      name: user?.name ?? "",
      lastName: user?.lastName ?? "",
      email: user?.email ?? "",
      phoneNumber: modifiedPhoneNumber(user?.phoneNumber) ?? "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: null,
      state: "",
      type: null,
      postalCode: "",
    }),
    [user]
  );

  useEffect(() => {
    if (user?.phoneNumber && user?.countryName) {
      const selectedCountry = country.find(
        (country) => country.name === user.countryName
      );
      setSelectedPhoneCountry(selectedCountry);
    } else {
      setSelectedPhoneCountry(country[0]);
    }
  }, [user.phoneNumber, user.countryName, country]);

  useEffect(() => {
    dispatch(
      fetchCartProducts({ userId: user.id, countryId: selectedCountry.id })
    );
    if (total && selectedCountry?.id) {
      if (
        parseInt(total) < appliedCoupon?.minCartAmount ||
        selectedCountry?.currencyCode !== appliedCoupon?.currencyCode
      ) {
        dispatch(removeAppliedCoupon());
      }
    }
  }, [selectedCountry, total]);

  const submitHandler = async (values, { resetForm }) => {
    setLoading(true);
    dispatch(
      updateUser({
        user: {
          name: values.name,
          email: null,
          phoneNumber: `${selectedPhoneCountry?.code}${values.phoneNumber}`,
          lastName: values.lastName,
          countryName: selectedPhoneCountry.name,
          userId: user.id,
        },
        onSuccess: () => {
          return;
        },
      })
    );
    if (addresses.length === 0) {
      const selectedCountryId = country.find(
        (country) => country.name === values.country
      )?.id;
      dispatch(
        createAddress({
          address: {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            countryId: selectedCountryId,
            type: values.type,
            userId: user.id,
          },
          onSuccess: () => {},
        })
      );
    }
    if (addresses?.length > 0 && !selectedAddress) {
      dispatch(setAddressError({ message: "Please select an address" }));
      return;
    }
    if (
      `${selectedPhoneCountry.code}${values.phoneNumber}` !==
        user.phoneNumber &&
      values.phoneNumber !== "" &&
      values.phoneNumber != null
    ) {
      // await onSendOTP();
      setOpenPopup(false);
      setLoading(false);
      navigate("confirmation");
    } else {
      setLoading(false);
      navigate("confirmation");
    }
  };

  const onSelectAddress = (address) => {
    dispatch(selectAddress(address));
  };

  const validator = (values) => {
    const errors = {};
    if ((values.phoneNumber + "").length > 15 || values.phoneNumber === "") {
      errors.phoneNumber = "Invalid Phone Number";
    }
    if (values.name === "") {
      errors.name = "This field is required.";
    }
    if (addresses.length === 0 && values.state === "") {
      errors.state = "This field is Required.";
    }
    if (addresses.length === 0 && values.country === "") {
      errors.country = "This field is required.";
    }

    if (addresses.length === 0 && values.addressLine1 === "") {
      errors.addressLine1 = "This field is required";
    }

    if (addresses.length === 0 && values.addressLine2 === "") {
      errors.addressLine2 = "This field is required";
    }

    if (addresses.length === 0 && values.postalCode === "") {
      errors.postalCode = "This field is Required.";
    }
    if (addresses.length === 0 && values.city === "") {
      errors.city = "This field is required";
    }
    if (addresses.length === 0 && values.type === "") {
      errors.type = "This field is required";
    }
    if (addresses.length !== 0 && !selectedAddress) {
      errors.address = "Please select address";
    }
    return errors;
  };

  return (
    <>
      <OTPPopup
        data={user.phoneNumber}
        show={openPopup}
        onClose={() => setOpenPopup(false)}
        onVerify={onVerifyOTP}
        onResend={onSendOTP}
      />
      <Formik
        initialValues={initialState}
        validate={(values) => validator(values)}
        onSubmit={(values, actions) => submitHandler(values, actions)}
        validateOnChange={false}
        enableReinitialize
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <section className="mt-20 md:mt-32 pb-3 md:pb-6 lg:pb-10">
            <div className="contain">
              <h3 className="h4 px-3  md:px-5 text-primary font-manrope">
                Checkout
              </h3>
              {appliedCoupon && (
                <div className="px-3 md:px-5 mt-6 md:mb-12">
                  <div className="w-full flex items-center rounded-md py-4 px-4 bg-[#F8F8F8]">
                    <BsCheckCircle color="#008000" size={28} />
                    <div className="ml-4">
                      <p className="font-semibold">
                        <span className="font-bold">
                          {appliedCoupon?.code}{" "}
                        </span>{" "}
                        applied
                      </p>
                      <p className=" text-slate-400">
                        {appliedCoupon?.description}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <form
              className="grid grid-cols-1 xl:grid-cols-3 xl:gap-10 md:grid-cols-2  px-3 md:px-5 mt-5 contain"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="grid h-fit col-span-2 grid-cols-1 md:grid-cols-2 md:gap-6 gap-y-6">
                <label
                  htmlFor="name"
                  className=" flex flex-col text-lg text-primary"
                >
                  First Name
                  <input
                    type="text"
                    value={values?.name}
                    onChange={handleChange}
                    name="name"
                    id="name"
                    className={`py-2 px-3  border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                      errors.name
                        ? "outline-red-800 border-red-800"
                        : "outline-[#476F66] border-slate-400"
                    }`}
                  />
                  {(errors.name || userError?.name) && (
                    <small className="text text-red-800 dark:text-red font-medium capitalize ">
                      {errors.name}
                    </small>
                  )}
                </label>
                <label
                  htmlFor="lastName"
                  className=" flex flex-col text-lg text-primary    capitalize"
                >
                  Last Name
                  <input
                    type="text"
                    value={values?.lastName}
                    onChange={handleChange}
                    name="lastName"
                    id="lastName"
                    className="py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 outline-[#476F66] border-slate-400"
                  />
                  {(errors.lastName || userError?.lastName) && (
                    <small className="text text-red-800 dark:text-red font-medium capitalize ">
                      {errors.lastName}
                    </small>
                  )}
                </label>
                <label
                  htmlFor="phoneNumber"
                  className=" flex flex-col text-lg text-primary  capitalize"
                >
                  Phone Number
                  <PhoneInput
                    value={values?.phoneNumber}
                    onChange={handleChange}
                    style="mt-2 input-secondary"
                    name="phoneNumber"
                    selectedCountry={selectedPhoneCountry}
                    onSelectCountry={(country) =>
                      setSelectedPhoneCountry(country)
                    }
                  />
                  {(errors.phoneNumber || userError?.phoneNumber) && (
                    <small className="text text-red-800 dark:text-red font-medium capitalize ">
                      {errors.phoneNumber}
                    </small>
                  )}
                </label>
                <label
                  htmlFor="email"
                  className=" flex flex-col text-lg text-primary    capitalize"
                >
                  Email
                  <input
                    type="text"
                    value={values?.email}
                    onChange={handleChange}
                    disabled
                    name="email"
                    id="email"
                    className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                      errors.email
                        ? "outline-red-800 border-red-800"
                        : "outline-[#476F66] border-slate-400"
                    }`}
                  />
                  {(errors.email || userError?.email) && (
                    <small className="text text-red-800 dark:text-red font-medium capitalize ">
                      {errors.email}
                    </small>
                  )}
                </label>

                {addresses && addresses?.length ? (
                  addresses.map((address) => (
                    <div key={address.id} className="md:my-6 pr-8">
                      <div className="flex items-center justify-between">
                        <h6 className="text-[22px] font-manrope font-semibold text-[#7A7A7A]">
                          {address.type}
                        </h6>
                        <span
                          onClick={() => onSelectAddress(address)}
                          className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center rounded-full border-2 border-primary"
                        >
                          {selectedAddress?.id === address.id && (
                            <div className="w-[10px] h-[10px] rounded-full bg-primary"></div>
                          )}
                        </span>
                      </div>
                      <p className="text-base text-[#000000] pt-5 max-w-md">
                        {`${address.addressLine1}, ${address.addressLine2}`}
                      </p>
                      <p className="text-base text-[#000000] max-w-md">
                        {address.postalCode}, {address.city} {address.state},
                        {address.country.name}
                      </p>
                      <div className="flex gap-3 justify-end">
                        <button
                          onClick={() =>
                            navigate("/profile/address", { state: { address } })
                          }
                          className="flex items-center gap-2 btn-primary-sm"
                        >
                          <BiEdit />
                          Edit
                        </button>
                      </div>
                      <hr className="mt-4 " />
                      {errors.address && (
                        <small className="error-text">{errors.address}</small>
                      )}
                      {addressError?.message && (
                        <p className="error-text">{addressError?.message}</p>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    <label
                      htmlFor="addressLine1"
                      className=" flex flex-col text-lg text-primary capitalize"
                    >
                      Address Line 1
                      <input
                        type="text"
                        value={values.addressLine1}
                        onChange={handleChange}
                        name="addressLine1"
                        id="addressLine1"
                        className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                          errors.addressLine1
                            ? "outline-red-800 border-red-800"
                            : "outline-[#476F66] border-slate-400"
                        }`}
                      />
                      {(errors.addressLine1 || addressError?.addressLine1) && (
                        <small className="error-text">
                          {errors.addressLine1}
                        </small>
                      )}
                    </label>
                    <label
                      htmlFor="addressLine2"
                      className=" flex flex-col text-lg text-primary    capitalize"
                    >
                      Address Line 2
                      <input
                        type="text"
                        value={values.addressLine2}
                        onChange={handleChange}
                        name="addressLine2"
                        id="addressLine2"
                        className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                          errors.addressLine2
                            ? "outline-red-800 border-red-800"
                            : "outline-[#476F66] border-slate-400"
                        }`}
                      />
                      {(errors.addressLine2 || addressError?.addressLine2) && (
                        <small className="text text-red-800 dark:text-red font-medium capitalize ">
                          {errors.addressLine2}
                        </small>
                      )}
                    </label>
                    <label
                      htmlFor="city"
                      className=" flex flex-col text-lg text-primary    capitalize"
                    >
                      City
                      <input
                        type="text"
                        value={values.city}
                        onChange={handleChange}
                        name="city"
                        id="city"
                        className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                          errors.city
                            ? "outline-red-800 border-red-800"
                            : "outline-[#476F66] border-slate-400"
                        }`}
                      />
                      {(errors.city || addressError?.city) && (
                        <small className="text text-red-800 dark:text-red font-medium capitalize ">
                          {errors.city}
                        </small>
                      )}
                    </label>
                    <label
                      htmlFor="postalCode"
                      className=" flex flex-col text-lg text-primary    capitalize"
                    >
                      Postal Code
                      <input
                        value={values.postalCode}
                        onChange={handleChange}
                        name="postalCode"
                        id="postalCode"
                        className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                          errors.postalCode
                            ? "outline-red-800 border-red-800"
                            : "outline-[#476F66] border-slate-400"
                        }`}
                      />
                      {(errors.postalCode || addressError?.postalCode) && (
                        <small className="text text-red-800 dark:text-red font-medium capitalize ">
                          {errors.postalCode}
                        </small>
                      )}
                    </label>
                    <label
                      htmlFor="state"
                      className="flex flex-col text-lg text-primary capitalize"
                    >
                      State
                      <input
                        type="text"
                        value={values.state}
                        onChange={handleChange}
                        name="state"
                        id="state"
                        className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                          errors.state
                            ? "outline-red-800 border-red-800"
                            : "outline-[#476F66] border-slate-400"
                        }`}
                      />
                      {(errors.state || addressError?.state) && (
                        <small className="text text-red-800 dark:text-red font-medium capitalize ">
                          {errors.state}
                        </small>
                      )}
                    </label>
                    <label
                      htmlFor="country"
                      className="flex flex-col text-lg text-primary capitalize"
                    >
                      country
                      <select
                        type="text"
                        onChange={handleChange}
                        name="country"
                        id="country"
                        className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                          errors.country
                            ? "outline-red-800 border-red-800"
                            : "outline-[#476F66] border-slate-400"
                        }`}
                      >
                        <option hidden selected disabled>
                          Select Country
                        </option>
                        {country?.map((country) => (
                          <option>{country?.name}</option>
                        ))}
                      </select>
                      {(errors.country || addressError?.country) && (
                        <small className="text text-red-800 dark:text-red font-medium capitalize ">
                          {errors.country}
                        </small>
                      )}
                    </label>
                    <label
                      htmlFor="type"
                      className="flex flex-col text-lg text-primary capitalize"
                    >
                      Address Type
                      <select
                        type="text"
                        onChange={handleChange}
                        name="type"
                        id="type"
                        className={`py-2 px-3 border focus:border-slate-500 rounded w-full md:py-3  text-[18px] placeholder:text-primary placeholder:text-opacity-60 text-primary  focus:outline-none flex-0 items-start   focus:text-primary font-medium font-manrope; mt-2 ${
                          errors.country
                            ? "outline-red-800 border-red-800"
                            : "outline-[#476F66] border-slate-400"
                        }`}
                      >
                        <option hidden selected disabled>
                          Select address type
                        </option>
                        <option>Home</option>
                        <option>Office</option>
                      </select>
                      {(errors.type || addressError?.type) && (
                        <small className="text text-red-800 dark:text-red font-medium capitalize ">
                          {errors.typs}
                        </small>
                      )}
                    </label>
                  </>
                )}
              </div>

              <div>
                <div className="shadow-xl mt-12  xl:mt-0 py-10  px-2 md:px-6 rounded-lg overflow-hidden ">
                  <h5 className="text-xl xl:text-2xl font-bold text-primary uppercase">
                    Your Order
                  </h5>
                  <table className=" w-full mt-4">
                    <thead>
                      <td className="uppercase text-lg font-bold pl-2 py-2 ">
                        Product
                      </td>
                      <td
                        className="uppercase pr-2 py-2 text-lg font-bold"
                        align="right"
                      >
                        Total
                      </td>
                    </thead>
                    <tbody>
                      {cartProducts?.map((product) => (
                        <tr>
                          <td className="uppercase pl-2 py-2 font-semibold text-sm">
                            {product.title}
                          </td>
                          <td
                            className="uppercase pr-2 py-2 text-sm   font-semibold"
                            align="right"
                          >
                            {`${selectedCountry?.symbol}${parseInt(
                              product.mrp
                            )}`}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className=" text-xl uppercase pl-2 py-2 font-bold text-primary pt-4">
                          total
                        </td>
                        <td
                          className="uppercase pr-2 py-2 text-sm   font-semibold pt-4"
                          align="right"
                        >
                          {`${selectedCountry?.symbol}${parseInt(total)}`}
                        </td>
                      </tr>
                      {appliedCoupon && (
                        <tr>
                          <td className="  text-xl uppercase pl-2 py-2 font-bold text-primary pt-2">
                            Discount
                          </td>
                          <td
                            className="uppercase pr-2 py-2 text-sm   font-semibold"
                            align="right"
                          >
                            {`- ${selectedCountry?.symbol}${parseInt(
                              appliedCoupon?.amount &&
                                appliedCoupon?.amount !== 0
                                ? appliedCoupon?.amount
                                : total * (appliedCoupon?.percentage / 100)
                            )}`}
                          </td>
                        </tr>
                      )}
                      <tr className="border-t border-slate-200">
                        <td className="  text-xl uppercase pl-2 py-2 font-bold text-primary pt-5">
                          Sub Total
                        </td>
                        <td
                          className="uppercase pr-2 py-2 text-2xl text-primary   font-semibold"
                          align="right"
                        >
                          {`${selectedCountry?.symbol}${
                            subtotal !== 0
                              ? parseInt(subtotal)
                              : parseInt(total)
                          }`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    type="submit"
                    className="btn-secondary rounded-md font-bold w-full text-lg mt-10 uppercase"
                  >
                    {loading ? (
                      <div className="flex items-center justify-center">
                        <LoadingIndicator
                          indicatorStyle={{
                            width: "30px",
                            height: "30px",
                            borderTop: "5px solid #dab188",
                          }}
                        />
                      </div>
                    ) : (
                      "Place order"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </section>
        )}
      </Formik>
    </>
  );
}
